import React, {useEffect, useState} from 'react';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import sprite from "../../../assets/img/sprites/sprite.svg";
import ClickAwayListener from '@mui/material/ClickAwayListener';

const SmartLotsFilter = (props) => {
    const [jk, setJk] = useState(props.selectedComplex);
    const handleJk = (event) => {
        setJk(event.target.value);
        props.setSelectedComplex(event.target.value)
    };

    const renderComplex = () => {
        return props.complex.map((el)=>{
            if(el.active === 1){
                return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
            }
        })
    }
    return (
        <div className="filter">
            <div className="common__container">
                <div className="filter__box flatsFilter">
                    <div className="filter__button-wrap">
                        <button className="button__filter button__filter--filter js-show-filter">
                            Фильтры
                        </button>
                        <button className="button__filter button__filter--sort js-show-filter">
                            Сортировка
                        </button>
                    </div>
                    <div className={`filter__container js-filter-container' : ''}`}>
                        <div className="filter__form js-filter">
                            <div className="filter__form-inner">
                                <div className="filter__inner">
                                    <div className="filter__item js-filter-item">
                                        <FormControl sx={{ m: 1, minWidth: 250 }}>
                                            <Select
                                                value={jk}
                                                onChange={handleJk}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                {/*<MenuItem value={'all'}>Все ЖК</MenuItem>*/}
                                                {renderComplex()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                            <Box sx={{ position: 'relative' }}>
                                                <button className="filter__button js-filter-button">
                                                    <span className="filter__button-text">
                                                         Комнат
                                                    </span>
                                                    <div className="filter__button-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                    </div>
                                                </button>
                                            </Box>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <Box sx={{ position: 'relative' }}>
                                            <button className="filter__button js-filter-button">
                                                    <span className="filter__button-text">
                                                         Отделка
                                                    </span>
                                                <div className="filter__button-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                </div>
                                            </button>
                                        </Box>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <Box sx={{ position: 'relative' }}>
                                            <button className="filter__button js-filter-button">
                                                    <span className="filter__button-text">
                                                         Этаж
                                                    </span>
                                                <div className="filter__button-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                </div>
                                            </button>
                                        </Box>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <Box sx={{ position: 'relative' }}>
                                            <button className="filter__button js-filter-button">
                                                    <span className="filter__button-text">
                                                         Корпус
                                                    </span>
                                                <div className="filter__button-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                </div>
                                            </button>
                                        </Box>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <Box sx={{ position: 'relative' }}>
                                            <button className="filter__button js-filter-button">
                                                    <span className="filter__button-text">
                                                         Сдача
                                                    </span>
                                                <div className="filter__button-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                </div>
                                            </button>
                                        </Box>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <Box sx={{ position: 'relative' }}>
                                            <button className="filter__button js-filter-button">
                                                    <span className="filter__button-text">
                                                         Готовность
                                                    </span>
                                                <div className="filter__button-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                </div>
                                            </button>
                                        </Box>
                                    </div>
                                </div>
                                <div className="button__submit-wrap">
                                    <button className="button__submit" type="submit">
                                        Применить
                                    </button>
                                </div>
                            </div>
                            <div className="filter__reset">
                                <button className="button__close js-close-filter"
                                        style={{backgroundImage:`url(${sprite}#close)`}}>
                                </button>
                                <span className="filter__title">
                                    Фильтры
                                </span>
                                <button className="button__reset js-reset-filter">
                                        <span className="button__reset-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <use className="reload-prm" xlinkHref={`${sprite}#reload-prm`}> </use>
                                            </svg>
                                        </span>
                                    <span>Сбросить фильтр</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmartLotsFilter;