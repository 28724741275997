import React, {useContext, useState} from 'react';
import './callItem-classified.scss';
import reloadicon from '../../../assets/img/img/reloadicon.png';
import baricon from '../../../assets/img/img/baricon.png';
import CallTypePopup from "../../../components/call-type-popup";
import axios from "axios";
import {UserContext} from "../../../App";
import StandardPopup from "../../../components/standard-popup";


const CallItemClassified = (props) => {
    const {call_service,call_date,call_duration,mp3_source,call_number,call_status,
        call_status_text,call_id,call_repeat,call_confirm} = props.data;
    const [openPopup,setOpenPopup] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [callConf,setCallConf] = useState(call_confirm)
    const user = useContext(UserContext);
    const handleStatus = (status) => {
        switch (status) {
            case '0':
                return 'yellow'
            break;
            case '1':
                return 'green'
            break;
            case '2':
                return 'purple'
            break;
            case '3':
                return 'orange'
            break;
            case '4':
                return 'aqua'
            break;
            case '5':
                return 'red'
            break;
        }
    }
    const handleSubmit = ()=>{
        axios.post(`https://api.na100.pro/api.php?page=confirm&id=${call_id}`,user)
            .then(res=>{
                if(res.data.result){
                    setConfirmed(true)
                    setCallConf(1)
                }
            })
    }
    return (
        <div className="period__table-row">
            {openPopup ? <CallTypePopup callId={call_id} setOpenPopup={setOpenPopup}/> : ''}
            {confirmed ? <StandardPopup title={'Статус принят'} closePopup={setConfirmed}/> : ''}
            <div className="period__table-body-item">
                <div className="period__table-body-inner">
                    {call_date}
                </div>
            </div>
            <div className="period__table-body-item">
                <div className="period__table-body-inner numberContainer">
                    {call_number}
                    {call_repeat === '1' ? <img src={reloadicon} className='reloadIcon'/> : ''}
                </div>
                <button className="button__call">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink">
                        <use className="callback"
                             xlinkHref="/img/sprites/sprite.svg#callback"> </use>
                    </svg>
                </button>
            </div>
            <div className="period__table-body-item pl-item">
                <div className="period__table-body-inner">

                </div>
            </div>
            <div className="period__table-body-item">
                <div className="period__table-body-inner">
                    <div className="statusContainer">
                        <div className={`period__status ${handleStatus(call_status)}`}>
                            {call_status_text}
                        </div>
                        {call_status === '1'  && callConf != 1 ?
                            <span className="common__hint">
                                <div className="dotsButton">
                                    <img src={baricon} alt="bar icon" className='barIcon'/>
                                </div>
                                 <div className="common__hint-desc">
                                    <div className="standardBlueButton" onClick={handleSubmit}>Подтверждено</div>
                                    <div className="standardGrayButton" onClick={()=>{setOpenPopup(true)}}>Отклонить</div>
                                 </div>
                            </span>

                            : ''}
                    </div>
                </div>
            </div>
            <div className="period__table-body-item">
                <div className="period__table-body-inner">
                    <div className="period__time">
                        {call_duration}
                    </div>
                </div>
            </div>
            <div className="period__table-body-item">
                <div className="period__table-body-inner">
                    <div className="period__time">
                        <audio controls className='calls_audio' preload="auto">
                            <source src={mp3_source} type="audio/mpeg"/>
                        </audio>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallItemClassified;