import React, {useState} from 'react';
import levelIcon from "../../../assets/img/img/product/icon/level-icon.png";
import {Link} from "react-router-dom";
import sprite from "../../../assets/img/sprites/sprite.svg";
import StockPopup from "../../../components/stock-popup";
const OutputDetails = (props) => {
    const [open,setOpen] = useState(false);
    const [openStocks,setOpenStocks] = useState(false)
    const {complex_developer_logo,complex_name,complex_address,complex_id,updated,complex_cian_plan,
        complex_url_avito,complex_url_cian,complex_url_yandex,flats_count,
        complex_vas_cian,complex_class,complex_vas_yandex,complex_vas_avito,
        complex_add_yandex,complex_cian_strategy,complex_auction_cian,complex_add_cian} = props.details
    const handleCianStrategy = (i)=>{
        switch (i) {
            case 0:
                return {title:'Обычная', desc:'поднятие ставки, откат при лимите',}
            case 1:
                return {title:'Пассивная', desc:'минимальная ставка, присутствие',}
            case 2:
                return {title:'Агрессивная', desc:'поднятие ставки до лимита',}
            case 3:
                return {title:'Бережливая', desc:'минимальная ставка при достижении лимита',}
        }
    }
    const handleIconsColor = (complexClass)=>{
        switch (complexClass) {
            case 'Б':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #FF2E00'
                break;
            case 'П':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #D46B08'
                break;
            case 'Е':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #C0C0C0'
                break;
            case 'К':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #FFC53D'
                break;
        }
    }
    const renderPopupLinks = () => {
        return props.details.services.map((el)=>{
            return (
                <li className="link__list-item">
                    <a href={el.url} target='_blank' rel="noopener noreferrer" className="link__list-action">
                        <span>{el.name}</span>
                    </a>
                </li>
            )
        })
    }
    return (
        <div className="output__item">
            {openStocks ? <StockPopup campaigns={props.campaigns}
                                      setOpenStocks={setOpenStocks}
                                      update={props.update}
                                      setUpdate={props.setUpdate}
                                      id={complex_id}/> : ''}
            <div className="output__inner">
                <div className="output__head">
                    <div className="output__img">
                        <img src={props.images} alt={complex_name} />
                    </div>
                    <div className="output__info">
                        <div className="output__row">
                            <div className="output__logo">
                                <img src={complex_developer_logo} alt={complex_name} />
                            </div>
                            <div className="product__info product__info--header">
                                <div className="product__info-icon">

                                </div>
                                <span className="product__info-text">
                                    Обновлено {updated}
                                </span>
                            </div>
                        </div>
                        <div className="product__desc-top complexDetails">
                            <div className="product__desc">
                                <div className="product__desc-header">
                                    <span>{complex_address}</span>
                                </div>
                                <span className="product__desc-text product__desc-text--inside">
                                    <div className="product__desc-icon product__desc-icon--inside"
                                         style={{background:handleIconsColor(complex_class)}}>
                                        <span>{complex_class}</span>
                                    </div>
                                    {complex_name}
                                </span>
                            </div>
                        </div>
                        <div className="product__desc">
                            <Link to={`/flats/${complex_id}`} className="product__desc-header text-decoration-underline">
                                <span>Всего объектов в продаже  <span className='flatsCount'> &nbsp;{flats_count}</span></span>
                                <Link to={`/flats/${complex_id}`} className="button__link-cut">
                                </Link>
                            </Link>
                            <a onClick={()=> setOpenStocks(true)}  className="mt-2 product__desc-header text-decoration-underline">
                                <span>Активные акции в ЖК  </span>
                                <div className="button__link-cut">
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="output__box">
                <span className="output__title">Подключенные площадки</span>
                <div className="output__box-inner">
                    <div className="output__box-item">
                        <div className="output__box-head">
                        <span className={`output__box-status ${props.planCian != 0 ? 'positive' : 'negative'}`}> </span>
                            <span className="output__box-name">ЦИАН</span>
                            {
                                complex_url_cian !== '' ? <a href={complex_url_cian} target='_blank' rel="noopener noreferrer" className="button__link-cut"> </a> : ''
                            }
                        </div>
                        <div className="output__tags">
                            <div className="product__desc-header">
                                <span>Стратегия работы</span>
                            </div>
                            <ul className="output__tags-list">
                                <li className={`output__tags-item ${complex_cian_plan === 'lite' ? 'active' : ''}`}>
                                    <span className="output__tags-name">Lite</span>
                                </li>
                                <li className={`output__tags-item ${complex_cian_plan === 'PRO' ? 'active' : ''}`}>
                                    <span className="output__tags-name">PRO</span>
                                </li>
                                <li className={`output__tags-item ${complex_cian_plan === 'PRO+' ? 'active' : ''}`}>
                                    <span className="output__tags-name">PRO+</span>
                                </li>
                            </ul>
                            <ul className="output__tags-list">
                                {/*<li className={`output__tags-item ${complex_add_cian ? 'active' : ''}`}>*/}
                                {/*    <span className="output__tags-name">Фичеринг</span>*/}
                                {/*</li>*/}
                                <li className={`output__tags-item ${complex_vas_cian ? 'active' : ''}`}>
                                    <span className="output__tags-name">Усиление</span>
                                </li>
                                <li className={`output__tags-item ${complex_auction_cian ? 'active' : ''}`}>
                                    <span className={`output__tags-name `}>
                                        Аукцион: {complex_cian_strategy ? handleCianStrategy(complex_cian_strategy).title : ''}
                                        <span className="common__hint">
                                            <div className="common__hint-icon">
                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                      xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <use className="hint" xlinkHref={`${sprite}#hint`}> </use>
                                                </svg>
                                            </div>
                                            <div className="common__hint-desc">
                                                {complex_cian_strategy ? handleCianStrategy(complex_cian_strategy).desc : ''}
                                            </div>
                                        </span>
                                    </span>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="output__box-item">
                        <div className="output__box-head">
                            <span className={`output__box-status ${props.planYandex !== 0 ? 'positive' : 'negative'}`}> </span>
                            <span className="output__box-name">Яндекс</span>
                            {
                                complex_url_yandex ? <a href={complex_url_yandex} target='_blank' rel="noopener noreferrer"  className="button__link-cut"> </a> : ''
                            }
                        </div>
                        <div className="output__tags">
                            <div className="product__desc-header">
                                <span>Стратегия работы</span>
                            </div>
                            <ul className="output__tags-list">
                                <li className={`output__tags-item ${complex_add_yandex ? 'active' : ''}`}>
                                    <span className="output__tags-name">Расширенная карточка</span>
                                </li>
                                <li className={`output__tags-item ${complex_vas_yandex ? 'active' : ''}`}>
                                    <span className="output__tags-name">Усиление</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="output__box-item">
                        <div className="output__box-head">
                            <span className={`output__box-status ${props.planAvito != 0 ? 'positive' : 'negative'}`}> </span>
                            <span className="output__box-name">Авито</span>
                            {
                                complex_url_avito ? <a href={complex_url_avito} target='_blank' rel="noopener noreferrer" className="button__link-cut"> </a> :''
                            }
                        </div>
                        <div className="output__tags">
                            <div className="product__desc-header">
                                <span>Стратегия работы</span>
                            </div>
                            <ul className="output__tags-list">
                                <li className={`output__tags-item ${complex_vas_avito ? 'active' : ''}`}>
                                    <span className="output__tags-name">Усиление</span>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="output__box-item">
                        <div className="output__box-head text-decoration-underline" onClick={()=>{setOpen(true)}}>
                            <span className={`output__box-status ${props.planNasto != 0 ? 'positive' : 'negative'}`}> </span>
                            <span className="output__box-name">Базы недвижимости (охватные)</span>
                            <button className="button__list js-open-modal"
                                    data-modal="link-list" > </button>
                        </div>
                    </div>
                </div>
            </div>
            {open ? <section className="modal modal--big js-modal active" data-open="link-list">
                <div className="modal__wrap">
                    <div className="modal__inner">
                        <button className="button__close js-close-modal" onClick={()=>{setOpen(false)}}>x
                        </button>
                        <div className="modal__title">
                            Охватные площадки
                        </div>
                        <div className="link__list-wrap">
                            <div className="link__list-col">
                                <ul className="link__list">
                                    {renderPopupLinks()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> : ''}
        </div>
    );
};

export default OutputDetails;