import React, {useState} from 'react';

const PartnerItem = ({name}) => {
    const [type,setType] = useState(true)
    return (
        <div className="partnerItem">
            <div className="name"><b>{name}</b></div>
            <div className="buttonsContainer">
                <div onClick={()=>setType(true)} className={`buttons ${type ? 'active' : ''}`}>
                    Включен
                </div>
                <div onClick={()=>setType(false)} className={`buttons ${!type ? 'active' : ''}`}>
                    Отключен
                </div>
            </div>
        </div>
    );
};

export default PartnerItem;