import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {UserContext} from "../../../App";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MediaPlanCounter from "../media-plan-counter";

const MediaMainDetails = () => {
    const user = useContext(UserContext);
    const [complexList,setComplexList] = useState([])
    const [complex,setComplex] = useState(localStorage.getItem('ci'))
    const [objectCategoryList,setObjectCategoryList] = useState([
        {id:1,name:'Жилая недвижимость'},
        {id:2,name:'Коммерческая недвижимость'},
        {id:3,name:'Загородная недвижимость'},
    ])
    const [objectCategory,setObjectCategory] = useState('1')
    useEffect(()=>{
        if(user !== null){
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplexList(res.data.complex.filter(e => e.active !== 0))
                })
        }
    },[user])
    const handleSelectComplex = (event) => {
        setComplex(event.target.value);
    };
    const renderComplexList = () => {
        return complexList.map((el)=>{
            return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
        })
    }
    const handleSelectObjectCategory = (event) => {
        setObjectCategory(event.target.value);
    };
    const renderCategoryObjectList = () => {
        return objectCategoryList.map((el)=>{
            return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
        })
    }
    return (
        <div className="common__section" id='media-plan-details'>
            <div className="output">
                <div className="common__container">
                    <div className="output__wrap">
                        <div className="media-plan-details">
                            <div className="columnsContainer">
                                <div className="columns">
                                    <div className="columnRow">
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                Список ЖК
                                            </div>
                                            <div className="select">
                                                <FormControl sx={{ m: 1, minWidth: 414 }}>
                                                    <Select
                                                        value={complex}
                                                        onChange={handleSelectComplex}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {renderComplexList()}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                Категория объекта
                                            </div>
                                            <div className="select">
                                                <FormControl sx={{ m: 1, minWidth: 414 }}>
                                                    <Select
                                                        value={objectCategory}
                                                        onChange={handleSelectObjectCategory}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {renderCategoryObjectList()}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="columnRow">
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                Бюджет РК
                                            </div>
                                            <div className="inpContainer">
                                                <input className='add-obj-inp media-plan-inp'
                                                        placeholder='Бюджет РК'
                                                       type="number"/>
                                            </div>
                                        </div>
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                План по звонкам

                                            </div>


                                            <div className="inpContainer">
                                                <input className='add-obj-inp media-plan-inp'
                                                       placeholder='План по звонкам'
                                                       type="number"/>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediaMainDetails;