import React from 'react';

 const ContentPopup = (props) => {
    return (
        <div className='leadPopup'>
            <div className="container">
                <div className='sandedContainer'>
                    {props.children}
                </div>
                <div className="close" onClick={()=>{props.popupOpen(false)}}>x</div>
            </div>
        </div>
    );
};

export default ContentPopup;