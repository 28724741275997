import React from 'react';
import {Link} from "react-router-dom";
import sprite from '../../../assets/img/sprites/sprite.svg';
const ProductItem = (props) => {
    const {complex_image,complex_name,complex_status,call_fact,complex_address,
        call_plan,flats_count,updated,complex_id,complex_class} = props.data;
    const handleIconsColor = (complexClass)=>{
        switch (complexClass) {
            case 'Б':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #FF2E00'
            break;
            case 'П':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #D46B08'
            break;
            case 'Э':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #C0C0C0'
            break;
            case 'К':
                return 'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #FFC53D'
            break;
        }
    }
    return (
        <div className="product__item">
            <Link to={`${props.url === 'calls' ? 'calls' : 'output'}/${complex_id}`}
               className="product__link">
                <div className="product__img">
                    <img src={complex_image} alt={complex_name}/>
                </div>
                <div className="product__inner">
                    <div className="product__desc-top">
                        <div className="product__desc-icon"
                             style={{background:handleIconsColor(complex_class)}}>
                            <span>{complex_class}</span>
                        </div>
                        <div className="product__desc">
                            <span className="product__desc-text">{complex_name}</span>
                            <div className="product__desc-header product__desc-header-title">
                                <span>{complex_address}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {complex_status === 'active' ?
                <>
                    <div className="product__row">
                        {props.apartment !== false ? <div className="product__desc">
                            <div className="product__desc-header">
                                <span>Квартир</span>
                                <span className="common__hint">
                                    <div className="common__hint-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <use className="hint"
                                                 xlinkHref={`${sprite}#hint`}></use>
                                        </svg>
                                    </div>
                                    <div className="common__hint-desc">
                                        Количество квартир, опубликованных в базах недвижимости в рамках действующей рекламной кампании.
                                    </div>
                                </span>
                            </div>
                            <span className="product__desc-text product__desc-text--bold">{flats_count}</span>
                        </div> : ''}

                        <div className="product__desc">
                            <div className="product__desc-header">
                                <span>план/факт</span>
                                <span className="common__hint">
                                    <div className="common__hint-icon">
                                         <svg xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <use className="hint"
                                                 xlinkHref={`${sprite}#hint`}></use>
                                        </svg>
                                    </div>
                                    <div className="common__hint-desc">
                                        Соотношение планового объема целевых обращений по всем каналам к фактическому объему на текущий момент.
                                    </div>
                                </span>
                            </div>
                            <span className="product__desc-text product__desc-text--bold">{call_plan}/{call_fact}</span>
                        </div>
                    </div>
                    <div className="product__info">
                        <div className="product__info-icon">
                            <svg className="search__icon" xmlns="http://www.w3.org/2000/svg"
                                 xmlnshlink="http://www.w3.org/1999/xlink">
                                <use className="reload" xlinkHref={`${sprite}#reload`}> </use>
                            </svg>
                        </div>
                        {props.updated !== false ? <span className="product__info-text">Обновлено: {updated}</span> : ''}

                    </div>
                </>
                : ''}

                {complex_status ? <div className={`product__status ${complex_status}`}></div> : ''}
            </Link>
        </div>
    );
};

export default ProductItem;