import React from 'react';
import sprite from "../../../assets/img/sprites/sprite.svg";

const SmartLotItem = (props) => {
    const {flats,images,typical} = props.data;
    const renderRows = ()=>{
        return flats.map((el)=>{
            return (
                <tr key={el.flat_id}>
                    <td>{el.flat_decor}</td>
                     <td>{el.building_ident}</td>
                     <td>{el.building_quarter}</td>
                     <td>{el.flat_area}</td>
                     <td>{el.flat_level}</td>
                     <td>
                         {el.flat_price_campaign === '0' ?
                             <>
                                 <p>{el.flat_price_total}</p>
                             </>
                             :
                             <>
                                 <p><del>{el.flat_price_total}</del></p>
                                 <p>{el.flat_price_campaig}</p>
                             </>
                         }
                     </td>
                </tr>
            )
        })
    }
    return (
        <div className='smartLotItem'>
            <div className="imageContainer">
                <img src={images.foto_image} alt="" className='sm-image'/>
                <div className='imageData'>комнат: {typical.typical_room}</div>
                <div className='imageData'>площадки : 35.3</div>
            </div>
            <table>
                <tr>
                    <th>
                        Отделка
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`,marginLeft:'10px'}}
                            className="common__sort-button common__sort-button--up js-sort-button">
                        </button>
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                            className="common__sort-button common__sort-button--down js-sort-button">
                        </button>
                    </th>
                    <th>Корпус
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`,marginLeft:'10px'}}
                            className="common__sort-button common__sort-button--up js-sort-button">
                        </button>
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                            className="common__sort-button common__sort-button--down js-sort-button">
                        </button>
                    </th>
                    <th>Дата сдачи
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`,marginLeft:'10px'}}
                            className="common__sort-button common__sort-button--up js-sort-button">
                        </button>
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                            className="common__sort-button common__sort-button--down js-sort-button">
                        </button>
                    </th>
                    <th>Площадь
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`,marginLeft:'10px'}}
                            className="common__sort-button common__sort-button--up js-sort-button">
                        </button>
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                            className="common__sort-button common__sort-button--down js-sort-button">
                        </button>
                    </th>
                    <th>Этаж
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`,marginLeft:'10px'}}
                            className="common__sort-button common__sort-button--up js-sort-button">
                        </button>
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                            className="common__sort-button common__sort-button--down js-sort-button">
                        </button>
                    </th>
                    <th>Цена
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`,marginLeft:'10px'}}
                            className="common__sort-button common__sort-button--up js-sort-button">
                        </button>
                        <button
                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                            className="common__sort-button common__sort-button--down js-sort-button">
                        </button>
                    </th>
                </tr>
                {renderRows()}
            </table>
        </div>
    );
};

export default SmartLotItem;