import React, {useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";

const OutputPriceChart = (props) => {
    const [type,setType] = useState('type1')
    const [cian,setCian] = useState([])
    const [yandex,setYandex] = useState([])
    const [cianMix,setCianMix] = useState([])
    const [dataToSHow,setDatatoShow] = useState([])
    const monthsList = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    const graphsDataCorrecter = (data)=>{
        let firstDate = data[0]
        let secondDate = data[1]
        if(!firstDate && !secondDate){
            return [];
        }
        let dateArrayNfd = firstDate.date.split('.')
        let dateArrayNsd = firstDate.date.split('.')
        let nfd = new Date().setFullYear(1970,Number(dateArrayNfd[1]),Number(dateArrayNfd[0]))
        let nsd = new Date().setFullYear(1970,Number(dateArrayNsd[1]),Number(dateArrayNsd[0]))
        if(nfd > nsd){
            return data.reverse()
        }else{
            return data
        }


    }
    useEffect(()=>{
        setCian(graphsDataCorrecter(props.cian))
        setYandex(graphsDataCorrecter(props.yandex))
        setCianMix(graphsDataCorrecter(props.cianMix))
        type === 'type1' ? setDatatoShow(cian) : setDatatoShow(cianMix);
    },[props.yandex,props.cian,props.cianMix,type])


    const handleData = ()=>{
        if(type === 'type1'){
            return [
                {
                    label:'Яндекс ',
                    data: yandex.map((el)=>{
                        return el.place
                    }),
                    borderColor: [
                        'orange'
                    ],
                    borderWidth: 3,
                    fill: false,
                    backgroundColor: 'orange'
                },
                {
                    label:'Циан',
                    data: dataToSHow.map((el)=>{
                        return el.place
                    }),
                    borderColor: '#436FFD',
                    borderWidth: 3,
                    fill: true,
                    backgroundColor: 'rgba(67, 111, 253, 0.62)'
                },
            ]
        }else{
            return [
                {
                    label:'Циан',
                    data: cianMix.map((el)=>{
                        return el.place
                    }),
                    borderColor: '#436FFD',
                    borderWidth: 3,
                    fill: true,
                    backgroundColor: 'rgba(67, 111, 253, 0.62)'
                },
            ]
        }
    }
    return (
        <div className="output__item priceChartContainer">
            <span className="output__title output__title--margin">
                Динамика видимости на {monthsList[new Date().getMonth()]} {new Date().getFullYear()} г.
            </span>
            <div className="output__container">
                <div className="output__tabs-wrap js-tab-wrap">
                    <ul className="output__tabs">
                        <li className="output__tabs-item">
                            <button onClick={()=>{setType('type1')}} className={`output__tabs-button js-tab ${type === 'type1' ? 'active': ''} `} data-action="all">
                                Каталог новостройки
                            </button>
                        </li>
                        <li className="output__tabs-item">
                            <button onClick={()=>{setType('type2')}} className={`output__tabs-button js-tab ${type === 'type2' ? 'active': ''} `} data-action="yandex">
                                Смешанная выдача
                            </button>
                        </li>
                    </ul>
                    <div className="output__tabs-box">
                        <div className="output__tabs-section js-tab-section active" data-section="all">
                            <div className="output__plan-wrap vasChartSection">
                                {
                                    yandex.length !== 0 || cian.length !== 0 ?   <Line data={{
                                        labels: yandex.map((el)=>{
                                            return el.date
                                        }),
                                        datasets: handleData(),
                                    }}
                                   width={100}
                                   height={300}
                                   options={{
                                       maintainAspectRatio: false,
                                       responsive: true,
                                       label: {
                                           display:true,
                                           align:'left'
                                       },
                                       scales: {
                                           myScale: {
                                               type: 'logarithmic',
                                               position: 'left', // `axis` is determined by the position as `'y'`
                                           }
                                       }
                                   }}
                                    /> : <p className='noGraph'>Отсутствуют данные</p>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default OutputPriceChart;