import React, {useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";

const ChartStandard = (props) => {
    const [graph,setGraph] = useState([]);
    const [length,setLength] = useState();
    const graphsDataCorrecter = (data)=>{
        if(!data){
            return [];
        }
        let firstDate = data[0]
        let secondDate = data[1]
        if(!firstDate && !secondDate){
            return [];
        }
        let dateArrayNfd = firstDate.date.split('.')
        let dateArrayNsd = firstDate.date.split('.')
        let nfd = new Date().setFullYear(1970,Number(dateArrayNfd[1]),Number(dateArrayNfd[0]))
        let nsd = new Date().setFullYear(1970,Number(dateArrayNsd[1]),Number(dateArrayNsd[0]))
        if(nfd > nsd){
            return data.reverse
        }else{
            return data
        }


    }
    useEffect(()=>{
        setGraph(graphsDataCorrecter(props.graph))
        let lengthSum = 0;
        props.graph.map((el)=>{
            lengthSum += Number(el.count)
        })
        setLength(lengthSum)
    },[props.graph])
    return (
        <>
            {graph.length !== 0 && length > 0 ? <Line data={{
                labels: graph.reverse().map((el)=>{
                    return el.date
                }),
                datasets: [{
                    legend: false,
                    data: graph.reverse().map((el)=>{
                        return el.count
                    }),
                    borderColor: '#436FFD',
                    fill: true,
                    backgroundColor: 'rgba(67, 111, 253, 0.62)',
                }],
            }}
                                                      width={100}
                                                      height={280}
                                                      options={{
                                                          maintainAspectRatio: false,
                                                          plugins: {legend: false},
                                                          scales: {
                                                              y: {
                                                                  ticks: {
                                                                      stepSize: 1,
                                                                      beginAtZero: true,
                                                                  },
                                                              },
                                                          },
                                                      }}
            /> : <p className='noGraph'>Отсутствуют данные</p>}
        </>
    );
};

export default ChartStandard;