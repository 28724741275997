import React, {useContext, useEffect, useMemo, useState} from 'react';
import Menu from "../../components/menu";
import {Link} from 'react-router-dom'
import MenuOpenButton from "../../components/menuOpenButton";
import FlatsHeader from "../flats/flatsHeader";
import SmartLotsFilter from "./smart-lots-filter";
import SmartLotItem from "./smart-lot-item";
import axios from "axios";
import {RightsContext, UserContext} from "../../App";
import NoneContent from "../../components/none-content";
import PageLoader from "../../components/page-loader";


const SmartLots = ({match}) => {
    const [complex,setComplex] = useState([])
    const [selectedComplex,setSelectedComplex] = useState(localStorage.getItem('ci'))
    const [plan,setPlan] = useState({})
    const [planToShow,setPlanToShow] = useState({})
    const [plansCount,setPlansCount] = useState(10)
    const [pageLoader, setPageLoader] = useState(false);
    const rights = useContext(RightsContext);
    const user = useContext(UserContext);
    useEffect(()=>{
        setPageLoader(true)
        if(user !== null){
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplex(res.data.complex)
                })
            axios.post(`https://api.na100.pro/api.php?page=plan&complex_id=${selectedComplex}`,user)
                .then(res => {
                    setPageLoader(false)
                    setPlan(res.data.plan)
                    setPlanToShow(Object.fromEntries(Object.entries(res.data.plan).slice(0,10)))
                })

        }
    },[user,selectedComplex])
    useEffect(()=>{
        if(user !== null) {
            let data = plan
            setPlanToShow(Object.fromEntries(Object.entries(data).slice(0,plansCount)))

        }
    },[plan,plansCount])
    const handleShowMore = ()=>{
        let count = plansCount + 10;
        setPlansCount(count)
    }
    const renderFlats = () => {
        let data = [];
        for(const property in planToShow){
            data.push(<SmartLotItem key={property} data={planToShow[property]}/>)
        }
        return data
    }
    return (
        <div className='second'>
            {pageLoader ? <PageLoader/> : ''}
            <Menu/>
            <FlatsHeader/>
            <div className="common__section">
                <div className="filter">
                    <div className="common__container">
                        <div className="filter__box">
                            <div className="filter__container">
                                <div className="header__container">
                                    <div className="header__inner">
                                        <MenuOpenButton/>
                                        <div className="output__tabs-wrap js-tab-wrap">
                                            <ul className="output__tabs">
                                                <li className="output__tabs-item" style={{marginRight:'10px'}}>
                                                    <Link to={`/flats/${localStorage.getItem('ci')}`}  className={`output__tabs-button js-tab`}>
                                                        Обычные лоты
                                                    </Link>
                                                </li>
                                                {
                                                    rights.indexOf('second') !== -1 ? <li className="output__tabs-item me-3">
                                                        <Link to={'/second'}  className={`output__tabs-button js-tab`}>
                                                            Трейд-ин
                                                        </Link>
                                                    </li> : ''
                                                }
                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/typical-layouts'}  className={`output__tabs-button js-tab active`}>
                                                        Типовые планировки
                                                    </Link>
                                                </li>

                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/commercials'}  className={`output__tabs-button js-tab`}>
                                                        Коммерческая недвижимость
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SmartLotsFilter
                    selectedComplex={selectedComplex}
                    complex={complex}
                    setSelectedComplex={setSelectedComplex}
                />
                <div className="apartment">
                    <div className="common__container">
                        <div className="apartment__table">
                            <div className="apartment__table-body">
                                { Object.keys(plan).length !== 0 ? renderFlats() : <NoneContent/>}
                            </div>
                            {Object.keys(planToShow).length !==  Object.keys(plan).length && Object.keys(planToShow).length >= 10  ?
                                <div className="showMore" onClick={handleShowMore}>Показать еще {Object.keys(planToShow).length} из {Object.keys(plan).length}
                                </div> : ''}

                            {/*<div className="pagination__wrap">*/}
                            {/*    <div className="pagination">*/}
                            {/*        <div className="pagination__select">*/}
                            {/*            <FormControl sx={{ m: 1, minWidth: 165,maxHeight:'35px' }}>*/}
                            {/*                <Select*/}
                            {/*                    // value={productsCount}*/}
                            {/*                    // onChange={handleChange}*/}
                            {/*                    displayEmpty*/}
                            {/*                    inputProps={{ 'aria-label': 'Without label' }}*/}
                            {/*                >*/}
                            {/*                    <MenuItem value={10}>Выводить по 10</MenuItem>*/}
                            {/*                    <MenuItem value={20}>Выводить по 20</MenuItem>*/}
                            {/*                    <MenuItem value={100}>Выводить по 100</MenuItem>*/}
                            {/*                </Select>*/}
                            {/*            </FormControl>*/}
                            {/*        </div>*/}
                            {/*        <div className="pagination__list-wrap">*/}
                            {/*            <Stack spacing={2}>*/}
                            {/*                <Pagination count={15} shape="rounded"  page={1} />*/}
                            {/*            </Stack>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SmartLots;