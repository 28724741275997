import React from 'react';
import {Link} from "react-router-dom";

const NonComplexSocial = () => {
    return (
        <div className="common__section calls-exchange-page">
            <section className="product">
                <div className="common__container">
                    <div className="js-product-box">
                        <div className="product__block" id="data-container">
                            <div className="product__box ">
                                <div className="leadProductsContainer">
                                    <h3>Привлеките дополнительные обращения клиентов из сообществ недвижимости VK и Facebook</h3>
                                    <br/>
                                    <p>
                                        Обеспечиваем автоматическое формирование мультитектового
                                        поста о квартире/ЖК с последующей публикацией в сообщества недвижимости (более 600).
                                    </p>
                                    <p>
                                        Каждый публикуемый пост дополнительно промотируется
                                        для получения дополнительного охвата целевой аудитории.
                                    </p>
                                    <Link to='#' className='addObject disabledButton'>Добавить объект</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NonComplexSocial;