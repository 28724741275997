import React, {useState} from 'react';
import avitoLogo from "../../../assets/img/img/avito-logo.png";

const MediaBase = (props) => {
    const [tarif,setTarif] = useState('lite');
    return (
        <div className="common__section media-boxes" id='media-plan-base'>
            <div className="output">
                <div className="common__container">
                    <div className="output__wrap">
                        <div className="media-plan-details">
                            <div className="columnsContainer">
                                <div className="columns">
                                    <div className="columnRow">
                                        <div className="smallColumn">
                                            <div className='d-flex flex-column'>
                                                <div className="d-flex ">
                                                    <h6 className='text-nowrap mb-0 d-flex align-items-center'>
                                                        Базы недвижимости
                                                    </h6>
                                                    <div className="buttons-container mx-3">
                                                        <button className='active'>Включить</button>
                                                        <button>Выключить</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <h2 className='sec-title'>Тарифы
                                                <span onClick={()=>{props.popupOpen(true)}}
                                                      className='share-settings'>Расширенная настройка</span>
                                            </h2>
                                            <div className="d-flex">
                                                <div className="buttons-container">
                                                    <button className={tarif === 'lite' ? 'active' : ''}
                                                            onClick={()=>setTarif('lite')}>50 лотов</button>
                                                    <button className={tarif === 'pro' ? 'active' : ''}
                                                            onClick={()=>setTarif('pro')}>100 лотов</button>
                                                    <button className={tarif === 'pro+' ? 'active' : ''}
                                                            onClick={()=>setTarif('pro+')}>150 лотов+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="columnRow">
                                        <div className="smallColumn flex-row">
                                            <div className="d-flex flex-column justify-content-center mt-3">
                                                <h3 className='smallTitle'>ВАСы</h3>
                                                <div className="buttons-container">
                                                    <button className='active'>Включена</button>
                                                    <button>Отключена</button>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center mt-3 mr-10">
                                                <h3 className='smallTitle'>Бюджет на ВАСы, ₽</h3>
                                                <input type="text" placeholder='Введите доступный бюджет'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="columnRow">
                                        <div className="smallColumn flex-row">
                                            <div className="d-flex flex-column justify-content-center mt-3 mr-10">
                                                <h3 className='smallTitle'>Бюджет, ₽</h3>
                                                <input type="text" placeholder='Введите доступный бюджет'/>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center mt-3 mr-10">
                                                <h3 className='smallTitle'>План звонков</h3>
                                                <input type="text" placeholder='Введите количество звонков'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediaBase;