import React, {useContext, useEffect, useState} from 'react';
import './add-object.scss';
import sprite from "../../assets/img/sprites/sprite.svg";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import Menu from "../../components/menu";
import Checkbox from "@mui/material/Checkbox";
import TimePicker from '@mui/lab/TimePicker';
import downloadbutton from '../../assets/img/img/downloadbutton.png';
import DateFnsUtils from "@date-io/date-fns";
import { ru } from "date-fns/locale";
import StandartHeader from "../../components/standart-header/standart-header";
import axios from "axios";
import {RightsContext, UserAuthContext, UserContext} from "../../App";
import TextPopup from "../../components/text-popup";

const AddObject = () => {

    const [title,setTitle] = useState('')
    const [phone,setPhone] = useState('')
    const [category,setCategory] = useState('1')
    const [objectClass, setObjectClass] = React.useState('5000');
    const [traffic, setTraffic] = React.useState('traffic1');
    const [plan, setPlan] = React.useState(1);
    const [value, setValue] = React.useState(new Date());
    const [callCenter, setCallCenter] = React.useState(false);
    const [social, setSocial] = React.useState(false);
    const [landing, setLanding] = React.useState(false);
    const [from, setFrom] = React.useState(new Date('2020-01-01 09:00'));
    const [to, setTo] = React.useState(new Date('2020-01-01 21:00'));
    const [file1, setFile1] = React.useState(null);
    const [file2, setFile2] = React.useState(null);
    const [callCentre, setCallCentre] = React.useState();
    const [rulles, setRulles] = React.useState();
    const [popUp, setPopup] = React.useState(false);
    const monthsList = ['January','February','March','April','May','June','July',
        'August','September','October','November','December'];
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const rights = useContext(RightsContext);
    useEffect(()=>{
        if(rights.indexOf('lklead') === -1){
            window.location.href = '/no-access'
        }
    },[])
    const handleCategory = (event) => {
        setCategory(event.target.value);
    };
    const handleTraffic = (event) => {
        setTraffic(event.target.value);
    };
    const  handleClass = (event) => {
        setObjectClass(event.target.value);
    };
    const handleChecks = (event) => {
        switch (event.target.name) {
            case 'call':
                setCallCenter(!callCenter)
                break;
            case 'social':
                setSocial(!social);
                break;
            case 'landing':
                setLanding(!landing);
                break;
        }
    };
    const handleCallCentre = (event)=>{
        setCallCentre(event.target.files[0])
        setFile1(event.target.files[0].name)
    }
    const handlesetRulles = (event)=>{
        setRulles(event.target.files[0])
        setFile2(event.target.files[0].name)
    }
    const renderClassRadios = ()=>{
        return (
            <FormControl component="fieldset">
                <RadioGroup name="controlled-radio-buttons-group" value={objectClass} onChange={handleClass}>
                    <FormControlLabel size='small' value="5000" control={<Radio />} label={
                        <div className='labelContainer'>
                            <div className="product__desc-icon"
                                 style={{background:'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #C0C0C0'}}>
                                <span>Е</span>
                            </div>
                            <p className="labelText">Эконом</p>
                        </div>
                    } />
                    <FormControlLabel size='small' value="6500" control={<Radio />} label={
                        <div className='labelContainer'>
                            <div className="product__desc-icon"
                                 style={{background:'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #FFC53D'}}>
                                <span>К</span>
                            </div>
                            <p className="labelText">Комфорт</p>
                        </div>
                    } />
                    <FormControlLabel  value="8000" control={<Radio />} label={
                        <div className='labelContainer'>
                            <div className="product__desc-icon"
                                 style={{background:'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #FF2E00'}}>
                                <span>Б</span>
                            </div>
                            <p className="labelText">Бизнес</p>
                        </div>
                    } />
                    <FormControlLabel size='small' value="10000" control={<Radio />} label={
                        <div className='labelContainer'>
                            <div className="product__desc-icon"
                                 style={{background:'linear-gradient(180deg, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0) 100%), #D46B08'}}>
                                <span>П</span>
                            </div>
                            <p className="labelText">Премиум</p>
                        </div>
                    } />


                </RadioGroup>
            </FormControl>
        )
    }
    const priceHandler = (num)=>{
        if(num){
            let numb =  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/, '$1 ')
            return numb
        }
    }
    const handleSubmit = (event)=>{
        event.preventDefault()
        const fd = new FormData();
        fd.append('lead_name',title)
        fd.append('lead_category',category)
        switch (objectClass) {
            case '5000':
                fd.append('lead_class','Э')
            break;
            case '6500':
                fd.append('lead_class','К')
            break;
            case '8000':
                fd.append('lead_class','Б')
            break;
            case '10000':
                fd.append('lead_class','П')
            break;
        }
        fd.append('lead_price',priceHandler(Number(objectClass)))
        fd.append('lead_plan',plan.toString())
        fd.append('lead_period',monthsList[value.getMonth()])
        fd.append('lead_budget',priceHandler(Number(objectClass) * Number(plan)))
        fd.append('lead_phone',phone)
        fd.append('lead_time_from',`${from.getHours()}:${from.getHours()}`)
        fd.append('lead_time_to',`${to.getHours()}:${to.getHours()}`)
        fd.append('lead_traf_call',Number(callCenter))
        fd.append('lead_traf_social',Number(social))
        fd.append('lead_script',callCentre)
        fd.append('lead_rules',rulles)
        fd.append('auth',localStorage.getItem('token'))
        userAuth();

        // if(user !== null){
        //     axios.post('https://api.na100.pro/api.php?page=lead',fd)
        //         .then(res => {
        //
        //             if(res.data.result === 'success'){
        //                 setPopup(true)
        //             }
        //         })
        // }




    }
    return (
        <div>
            <Menu/>
            <StandartHeader title={'Добавить объект '}/>
            {popUp ? <TextPopup handleClose={setPopup}
                       title={'Ваша заявка принята!'}
                       text={'Мы свяжемся с вами после ее рассмотрения.'}/>: ''}
            <div className="common__section calls-exchange-page">
                <section className="product">
                    <div className="common__container add-object-container">
                        <form className="add-object" onSubmit={handleSubmit}>
                            <div className="columnsContainer">
                                <div className="columns">
                                    <h3 className="mainSectionTitle">Данные ЖК </h3>
                                    <div className="columnRow">
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                Название ЖК *
                                            </div>
                                            <div className="inpContainer">
                                                <input className='add-obj-inp' type="text" name='title' placeholder='Название ЖК'
                                                       onChange={e => setTitle(e.target.value)} value={title} required/>
                                            </div>
                                        </div>
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                Категория объекта
                                            </div>
                                            <div className="select">
                                                <FormControl sx={{ m: 1, minWidth: 414 }}>
                                                    <Select
                                                        value={category}
                                                        onChange={handleCategory}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value={'1'}>Жилая недвижимость</MenuItem>
                                                        <MenuItem value={'2'}>Коммерческая недвижимость</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sectionTitle">
                                        Класс объекта
                                    </div>
                                    <div className="radioClassesContainer">
                                        {renderClassRadios()}
                                    </div>
                                    <div className="sectionTitle">
                                        Стоимость целевого обращения с НДС, руб
                                    </div>
                                    <div className="inpContainer">
                                        <input className='add-obj-inp' type="number" name='plan' placeholder={priceHandler(Number(objectClass))} readOnly/>
                                    </div>
                                    <h3 className="mainSectionTitle">План звонков</h3>
                                    <div className="columnRow">
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                План целевых обращений
                                            </div>
                                            <div className="inpContainer">
                                                <input className='add-obj-inp' type="number" onChange={e=>setPlan(e.target.value)} name='plan' value={plan}/>
                                            </div>
                                        </div>
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                Период
                                            </div>
                                            <div className="inpContainer">
                                                <div className="dataPickerContainer monthDatePicker">
                                                    <div className="pickerLabel">Выберите месяц</div>
                                                        <LocalizationProvider locale={ru} dateAdapter={DateFnsUtils}>
                                                            <DatePicker
                                                                okLabel="Text"
                                                                clearLabel="Text"
                                                                cancelLabel="Text"

                                                                displayStaticWrapperAs="desktop"
                                                                openTo="month"
                                                                views={["year", "month"]}
                                                                value={value}
                                                                onChange={(newValue) => {
                                                                    setValue(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params}/>}
                                                            />
                                                        </LocalizationProvider>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sectionTitle">
                                        Бюджет рекламной кампании с НДС, руб
                                    </div>
                                    <div className="inpContainer">
                                        <input className='add-obj-inp' type="number" name='plan' placeholder={priceHandler(Number(objectClass) * Number(plan))} readOnly/>
                                    </div>
                                    <h3 className="mainSectionTitle">Материалы</h3>
                                    <div className="columnRow">
                                        <div className="smallColumn">
                                            <div className="sectionTitle">Подменный номер телефона</div>
                                            <div className="inpContainer">
                                                <input className="add-obj-inp" type="text" onChange={e=>setPhone(e.target.value)} name="phone" placeholder="+7 *** *** ***" value={phone} required/>
                                            </div>
                                        </div>
                                        <div className="smallColumn">
                                            <div className="sectionTitle">
                                                Разрешенное время перевода звонков.
                                            </div>
                                            <div className="inpContainer">
                                                <div className="dataPickerContainer timePickerContainer">
                                                    <div className="pickerLabel">c </div>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack spacing={3}>
                                                            <TimePicker
                                                                value={from}
                                                                ampm={false}
                                                                onChange={setFrom}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <div className="pickerLabel ml"> до </div>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack spacing={3}>
                                                            <TimePicker
                                                                value={to}
                                                                ampm={false}
                                                                onChange={setTo}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fileContainer">
                                        <h3 className="fileTitle">Скрипт колл-центра</h3>
                                        <p className='fileName'>{file1}</p>
                                        <label className='fileLabel'><img src={downloadbutton} alt=""/> Добавить файл  <input type="file" placeholder='загрузить файл' hidden
                                              onChange={handleCallCentre}/></label>
                                    </div>
                                    <div className="fileContainer">
                                        <h3 className="fileTitle">Критерии целевого звонка</h3>
                                        <p className='fileName'>{file2}</p>
                                        <label className='fileLabel'><img src={downloadbutton} alt=""/>Добавить файл<input type="file" placeholder='загрузить файл' hidden
                                        onChange={handlesetRulles}/></label>
                                    </div>
                                    <div className="sectionTitle">
                                        Разрешенные типы трафика
                                    </div>
                                    <div className="checkboxesTypes">
                                        <FormControlLabel control={<Checkbox size='small' checked={callCenter}   onChange={handleChecks}/>}   name={'call'} label="Колл-центры" />
                                        <FormControlLabel control={<Checkbox size='small' checked={social} onChange={handleChecks}/>}   name={'social'} label="Арбитраж в соцсетях" />
                                    </div>
                                </div>
                            </div>
                            <div className="buttonContainer">
                                <div className="save">
                                    <button className='bigButton'>Отправить запрос</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AddObject;