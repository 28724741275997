import React, {useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";
const OutputPlanChart = (props) => {
    const [cianGraph,setCianGraph] = useState([]);
    const [avitoGraph,setAvitoGraph] = useState([]);
    const [yandexGraph,setYandexGraph] = useState([]);
    const [nastoGraph,setNastoGraph] = useState([]);
    const [otherGraph,setOtherGraph] = useState([]);
    const [type,setType] = useState('all');
    const graphsDataCorrecter = (data)=>{
        if(!data){
            return [];
        }
        let firstDate = data[0]
        let secondDate = data[1]
        if(!firstDate && !secondDate){
            return [];
        }
        let dateArrayNfd = firstDate.date.split('.')
        let dateArrayNsd = firstDate.date.split('.')
        let nfd = new Date().setFullYear(1970,Number(dateArrayNfd[1]),Number(dateArrayNfd[0]))
        let nsd = new Date().setFullYear(1970,Number(dateArrayNsd[1]),Number(dateArrayNsd[0]))
        if(nfd > nsd){
            return data.reverse
        }else{
            return data
        }


    }
    useEffect(()=>{
        setNastoGraph(props.nastoGraph)
        setCianGraph(props.cianGraph)
        setAvitoGraph(props.avitoGraph)
        setYandexGraph(props.yandexGraph)
        setOtherGraph(props.otherGraph)
        setNastoGraph(graphsDataCorrecter(props.nastoGraph))
        setCianGraph(graphsDataCorrecter(props.cianGraph))
        setAvitoGraph(graphsDataCorrecter(props.avitoGraph))
        setYandexGraph(graphsDataCorrecter(props.yandexGraph))
        setOtherGraph(graphsDataCorrecter(props.otherGraph))
    },[props.yandexGraph,props.avitoGraph,props.cianGraph,props.nastoGraph,props.otherGraph])
    const monthsList = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    const factCounter = (e)=>{
        let fact = 0
        let plan = 0
        let dayCount = 0
        let toDay = new Date().getDate() + '.' + (Number(new Date().getMonth()) + 1);
        switch (type) {
            case 'all':
                cianGraph.map((el)=>{
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                    fact+=el.count
                })
                avitoGraph.map((el)=>{
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                    fact+=el.count
                })
                yandexGraph.map((el)=>{
                    fact+=el.count
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                })
                nastoGraph.map((el)=>{
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                    fact+=el.count
                })
                if(otherGraph){
                    otherGraph.map((el)=>{
                        if(el.date === toDay){
                            dayCount += Number(el.count)
                        }
                        fact+=el.count
                    })
                }
                plan += Number(props.planCian) + Number(props.planYandex) + Number(props.planAvito) + Number(props.planNasto) + Number(props.planOther);
            break;
            case 'cian':
                cianGraph.map((el)=>{
                    fact+=el.count
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                })
                plan = Number(props.planCian)
            break;
            case 'avito':
                avitoGraph.map((el)=>{
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                    fact+=Number(el.count)
                })
                plan = Number(props.planAvito)
            break;
            case 'yandex':
                yandexGraph.map((el)=>{
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                    fact+=el.count
                })
                plan = Number(props.planYandex)
            break;
            case 'nasto':
                nastoGraph.map((el)=>{
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                    fact+=el.count
                })
                plan = Number(props.planNasto)
            break;
            case 'other':
                otherGraph.map((el)=>{
                    if(el.date === toDay){
                        dayCount += Number(el.count)
                    }
                    fact+=el.count
                })
                plan = Number(props.planOther)
                break;
        }
        if(e === 'all'){
            return {
                fact: Math.round(fact),
                plan: Math.round(plan),
                dayProgress:Math.round(dayCount),
            }
        }else if(e === 'month'){
            return Math.round((fact / plan) * 100)
        }else if(e === 'day'){
            return Math.round(fact / (plan / 30 * cianGraph.length) * 100)
        }

    }
    const handleGraphData = (graphType)=>{
        switch (graphType) {
            case 'all':
                let data = [];
                for(let i = 0; i < yandexGraph.length;i++){
                    data.push(yandexGraph[i].count + cianGraph[i].count + avitoGraph[i].count + nastoGraph[i].count + otherGraph[i].count)
                }
                return [
                {
                    label: 'Всего',
                    data: data,
                    borderColor: [
                        '#a0d911'
                    ],
                    borderWidth: 3
                },
                ];
            case 'yandex':
                return [{
                    label: 'Яндекс',
                    data: yandexGraph.map((el)=>{
                        return el.count
                    }),
                    borderColor: [
                        'orange'
                    ],
                    borderWidth: 3
                },]
            case 'cian':
                return [{
                    label: 'ЦИАН',
                    data: cianGraph.map((el)=>{
                        return el.count
                    }),
                    borderColor: '#436FFD',
                    borderWidth: 3,
                    fill: true,

                },]
            case 'nasto':
                return [{
                    label: 'Na100Pro',
                    data: nastoGraph.map((el)=>{
                        return el.count
                    }),
                    borderColor: 'purple',
                    borderWidth: 3,
                    fill: true,

                },]
            case 'avito':
                return [{
                    label: 'Avito',
                    data: avitoGraph.map((el)=>{
                        return el.count
                    }),
                    borderColor: 'red',
                    borderWidth: 3,
                    fill: true,
                },]
            case 'other':
                return [{
                    label: 'Другие CPL',
                    data: otherGraph.map((el)=>{
                        return el.count
                    }),
                    borderColor: '#d91189',
                    borderWidth: 3,
                    fill: true,
                },]
        }
    }

    return (
        <div className="output__item">
            <span className="output__title"> Выполнение медиаплана за {monthsList[new Date().getMonth()]} {new Date().getFullYear()} г.</span>
            <div className="output__container">
                <div className="output__tabs-wrap js-tab-wrap">
                    <ul className="output__tabs">
                        <li className="output__tabs-item all">
                            <button onClick={()=>{setType('all')}} className={`output__tabs-button  js-tab ${type === 'all' ? 'active': ''} `} data-action="all">
                                Всего
                            </button>
                        </li>
                        <li className="output__tabs-item">
                            <button onClick={()=>{setType('yandex')}} className={`output__tabs-button js-tab ${type === 'yandex' ? 'active': ''} `} data-action="yandex">
                                Яндекс
                            </button>
                        </li>
                        <li className="output__tabs-item">
                            <button onClick={()=>{setType('cian')}} className={`output__tabs-button js-tab ${type === 'cian' ? 'active': ''} `} data-action="cian">
                                ЦИАН
                            </button>
                        </li>
                        <li className="output__tabs-item">
                            <button onClick={()=>{setType('avito')}} className={`output__tabs-button js-tab ${type === 'avito' ? 'active': ''} `} data-action="avito">
                                Avito
                            </button>
                        </li>
                        <li className="output__tabs-item">
                            <button onClick={()=>{setType('nasto')}} className={`output__tabs-button js-tab ${type === 'nasto' ? 'active': ''} `} data-action="Na100PRO">
                                Базы недв.
                            </button>
                        </li>
                        <li className="output__tabs-item">
                            <button onClick={()=>{setType('other')}} className={`output__tabs-button js-tab ${type === 'other' ? 'active': ''} `} data-action="other">
                                Другие CPL
                            </button>
                        </li>
                    </ul>
                    <div className="output__tabs-box">
                        <div className="output__tabs-section js-tab-section active" data-section="all">
                            <div className="output__header">
                                <div className="output__header-item">
                                <span className="output__header-title">
                                План/Факт
                                </span>
                                <span className="output__header-calc">
                                    {factCounter('all').plan  ? factCounter('all').plan : 0}/{factCounter('all').fact}
                                    {factCounter('all').dayProgress !== 0 ? <span>+{factCounter('all').dayProgress}</span> : ''}
                                </span>
                                </div>
                                <div className="output__header-item">
                                <span className="output__header-title">
                                Выполнение на месяц
                                </span>
                                    <span className="output__header-calc">{factCounter('month') ? factCounter('month') : 0} %</span>
                                </div>
                                <div className="output__header-item">
                                <span className="output__header-title">
                                Выполнение на день
                                </span>
                                    <span className="output__header-calc">
                                {factCounter('day') ? factCounter('day') : 0} %
                                </span>
                                </div>
                            </div>
                            <div className="output__plan-wrap vasChartSection">
                                {
                                    factCounter('month') && factCounter('day') ?
                                    <Line data={{
                                        labels:yandexGraph.map((el)=>{
                                            return el.date
                                        }),
                                        datasets:handleGraphData(type),

                                    }}
                                   width={100}
                                   height={300}
                                   options={{
                                       scales: {
                                           y: {
                                               ticks: {
                                                   stepSize: 1,
                                                   beginAtZero: true,
                                               },
                                           },
                                       },
                                       maintainAspectRatio: false,
                                       plugins: {legend: false},


                                   }}
                                    /> : <p className='noGraph'>Отсутствуют данные</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OutputPlanChart;