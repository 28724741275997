import React, {useEffect, useState} from 'react';
import vasup from "../../assets/img/img/vasup.png";
import vasstar from "../../assets/img/img/vasstar.png";
import vasprogress from "../../assets/img/img/vasprogress.png";
import AdvFlatItem from "../adv-flat-item";

const AdvListSettings = (props) => {
    const [filter,setFilter] = useState({
        studio:true,
        one:true,
        two:true,
        three:true,
        four:true,
    })
    const [flats,setFlats] = useState([])
    const [allFlats,setAllFlats] = useState([])
    const [flatsToShow,setFlatsToShow] = useState([])
    const [plansCount,setPlansCount] = useState(10)
    useEffect(()=>{
        setAllFlats(props.flats)
        setFlats(props.flats)
        setFlatsToShow(props.flats.slice(0,10))
    },[props.flats])
    useEffect(()=>{
            let data = flats
            setFlatsToShow(data.slice(0,plansCount))
    },[flats,plansCount])
    useEffect(()=>{
        let data = [];
        if(filter.studio){
            let result = allFlats.filter(e => e.flat_studio === 1 )
            result.map(e => data.push(e))
        }
        if(filter.one){
            let result = allFlats.filter(e => e.flat_rooms === 1 )
            result.map(e => data.indexOf(e) === -1 ? data.push(e) : '')
        }
        if(filter.two){
            let result = allFlats.filter(e => e.flat_rooms === 2 )
            result.map(e => data.indexOf(e) === -1 ? data.push(e) : '')
        }
        if(filter.three){
            let result = allFlats.filter(e => e.flat_rooms === 3 )
            result.map(e => data.indexOf(e) === -1 ? data.push(e) : '')
        }
        if(filter.four){
            let result = allFlats.filter(e => e.flat_rooms === 4 )
            result.map(e => data.indexOf(e) === -1 ? data.push(e) : '')
        }
        setFlats(data)

    },[filter,allFlats])
    const handleShowMore = ()=>{
        let count = plansCount + 10;
        setPlansCount(count)
    }
    const renderFlats = ()=>{
        return flatsToShow.map((el)=>{
            return <AdvFlatItem data={el} type='settings'/>
        })
    }
    const handleFilter = (event) => {
        setFilter({
            ...filter,
            [event.target.name]: event.target.checked,
        });
    };
    return (
        <div className="callsFilter filter">
            <div className="common__container">
                <div className="filter__box">
                    <div className="filter__container js-filter-container">
                        <div className="filter__form js-filter">
                            <div className='advListSettings'>
                                <div className="title">Квартиры </div>
                                <div className="filterContainer">
                                    <label className="checkbox__custom-container">
                                        <input type="checkbox"
                                               name='studio'
                                               checked={filter.studio}
                                               onChange={handleFilter}
                                               className="checkbox__custom--hidden"/>
                                        <span className="checkbox__custom"> </span>
                                        <span className="checkbox__custom-text">Студия</span>
                                    </label>
                                    <label className="checkbox__custom-container">
                                        <input type="checkbox"
                                               name='one'
                                               checked={filter.one}
                                               onChange={handleFilter}
                                               className="checkbox__custom--hidden"/>
                                        <span className="checkbox__custom"> </span>
                                        <span className="checkbox__custom-text">1 ком.</span>
                                    </label>
                                    <label className="checkbox__custom-container">
                                        <input type="checkbox"
                                               name='two'
                                               checked={filter.two}
                                               onChange={handleFilter}
                                               className="checkbox__custom--hidden"/>
                                        <span className="checkbox__custom"> </span>
                                        <span className="checkbox__custom-text">2 ком.</span>
                                    </label>
                                    <label className="checkbox__custom-container">
                                        <input type="checkbox"
                                               name='three'
                                               checked={filter.three}
                                               onChange={handleFilter}
                                               className="checkbox__custom--hidden"/>
                                        <span className="checkbox__custom"> </span>
                                        <span className="checkbox__custom-text">3 ком.</span>
                                    </label>
                                    <label className="checkbox__custom-container">
                                        <input type="checkbox"
                                               name='four'
                                               checked={filter.four}
                                               onChange={handleFilter}
                                               className="checkbox__custom--hidden"/>
                                        <span className="checkbox__custom"> </span>
                                        <span className="checkbox__custom-text">4+ ком.</span>
                                    </label>
                                </div>
                                <div className="flatsList">
                                    {renderFlats()}
                                </div>
                                {flatsToShow.length !==  flats.length && flatsToShow.length >= 10  ?
                                    <div className="showMore" onClick={handleShowMore}>Показать еще {flatsToShow.length} из {flats.length}
                                    </div> : ''}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default AdvListSettings;