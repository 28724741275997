import React, {useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import sprite from '../../../assets/img/sprites/sprite.svg'

const Filter = (props) => {
    const [jk, setJk] = useState('all');
    const [sectionOpen, setSectionOpen] = useState(false);
    const [sections, setSections] = useState({});
    const [buildings, setBuildings] = useState({});
    const [buildingOpen, setBuildingOpen] = useState(false);
    const [levelFrom, setLevelFrom] = useState(1);
    const [levelMax, setLevelMax] = useState(53);
    const [levelOpen, setLevelOpen] = useState(false);
    const [roomOpen, setRoomOpen] = useState(false);
    const [rooms, setRooms] = useState({
        studio:false,
        one:false,
        two:false,
        tree:false,
        four:false
    });
    const [vas, setVas] = useState('none')
    const [filterOpen,setFilterOpen] = useState(false)
    const [sortOpen,setSortOpen] = useState(false)
    const [sortType,setSortType] = useState('asc');
    const [sort,setSort] = useState('building');
    useEffect(()=>{
        if(props.complexId !== undefined){
            setJk(props.complexId)
        }
    },[props.complexId])
    useEffect(()=>{
        let data = {};
        for(let item of props.sections){
            data[`${item}`] = false
        }
        setSections(data)
        let buildingData = {}
        for(let item of props.buildings){
            buildingData[`${item}`] = false
        }
        setBuildings(buildingData)
    },[props.sections,props.buildings,props.vases]);
    const handleSection = (event) => {
        setSections({
            ...sections,
            [event.target.name]: event.target.checked,
        });
    };
    const handleBuildings = (event) => {
        setBuildings({
            ...buildings,
            [event.target.name]: event.target.checked,
        });
    };
    const handleRooms = (event) => {
        setRooms({
            ...rooms,
            [event.target.name]: event.target.checked,
        });
    };
    const handleSectionOpen = () => {
        setSectionOpen((prev) => !prev);
    };
    const handleLevelOpen = () => {
        setLevelOpen((prev) => !prev);
    };
    const handleRoomOpen = () => {
        setRoomOpen((prev) => !prev);
    };
    const handleBuildingOpen = () => {
        setBuildingOpen((prev) => !prev);
    };
    const handleClickAway = () => {
        setSectionOpen(false);
    };
    const handleClickAwayBuilding = () => {
        setBuildingOpen(false);
    };
    const handleClickAwayRoom = () => {
        setRoomOpen(false);
    };
    const handleClickAwayLevels = () => {
        setLevelOpen(false);
    };
    const handleJk = (event) => {
        setJk(event.target.value);
        props.changeComplex(event.target.value)
    };
    const handleVases = (event) => {
        setVas(event.target.value);
    };
    const renderComplex = () => {
        return props.complex.map((el)=>{
            if(el.active === 1){
                return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
            }
        })
    }
    const renderVases = () => {
        return props.vases.map((el)=>{
            return <MenuItem  value={el.id}>{el.name}</MenuItem>
        })
    }
    const renderSections = () => {
        let data = [];
        for(let [key,value] of Object.entries(sections)){
            const row = (
             <div key={key} className="checkbox__wrap-item checkbox__wrap-item--filter">
                    <FormControlLabel
                        control={
                            <Checkbox size='small' checked={value} onChange={handleSection} name={key} />
                        }
                        label={key}
                    />
            </div>)
            data.push(row)
        }
        return data
    }
    const renderBuildings = () => {
        let data = [];
        for(let [key,value] of Object.entries(buildings)){
            const row = (
                <div key={key} className="checkbox__wrap-item checkbox__wrap-item--filter">
                    <FormControlLabel
                        control={
                            <Checkbox size='small' checked={value} onChange={handleBuildings} name={key} />
                        }
                        label={key}
                    />
                </div>)
            data.push(row)
        }
        return data
    }
    const handleObjToArray = (obj) => {
        const identifiers = Object.keys(obj)
        let arr = identifiers.filter(function(id) {
            return Number(obj[id])
        })
        return arr;
    }
    const  handleSubmit = ()=>{
        let formData = new FormData();
        formData.append('complex',jk)
        formData.append('from',levelFrom)
        formData.append('to',levelMax)
        let buildingsData = handleObjToArray(buildings);
        buildingsData.forEach(el => {formData.append('building[]',el)});
        let sectionsData = handleObjToArray(sections);
        let vasData = handleObjToArray(vas);
        sectionsData.forEach(el => {formData.append('section[]',el)});
        let roomsData = handleObjToArray(rooms);
        let roomsData2 = roomsData.map((el)=>{
            if(el === 'one'){
                return '1';
            }
            if(el === 'studio'){
                return 'studia';
            }
            if(el === 'two'){
                return '2';
            }
            if(el === 'tree'){
                return '3';
            }
            if(el === 'four'){
                return '4';
            }
        })
        roomsData2.forEach(el => {formData.append('room[]',el)});
        formData.append('auth',localStorage.getItem('token'))

        if(vas !== 'none'){
            formData.append('vas',vas);
        }
        props.handleFlatsData(formData)
    }
    const handleSortSubmit = (event)=>{
        event.preventDefault()
        props.handleSorting(sort,sortType);
    }
    return (
        <div className="filter">
            <div className="common__container">
                <div className="filter__box flatsFilter">
                    <div className="filter__button-wrap">
                        <button className="button__filter button__filter--filter js-show-filter"
                                onClick={()=>{setFilterOpen(true)}}>
                            Фильтры
                        </button>
                        <button className="button__filter button__filter--sort js-show-filter"
                                onClick={()=>{setSortOpen(true)}}>
                            Сортировка
                        </button>
                    </div>
                    <div className={`filter__container js-filter-container ${filterOpen ? 'active' : ''}`}>
                        <div className="filter__form js-filter">
                            <div className="filter__form-inner">
                                <div className="filter__inner">
                                    <div className="filter__item js-filter-item">
                                        <FormControl sx={{ m: 1, minWidth: 250 }}>
                                            <Select
                                                value={jk}
                                                onChange={handleJk}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                            {/*<MenuItem value={'all'}>Все ЖК</MenuItem>*/}
                                                {renderComplex()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <ClickAwayListener onClickAway={handleClickAwayBuilding}>
                                            <Box sx={{ position: 'relative' }}>
                                                <button className="filter__button js-filter-button" onClick={handleBuildingOpen}>
                                                    <span className="filter__button-text">
                                                         Корпус
                                                    </span>
                                                    <div className="filter__button-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                    </div>
                                                </button>
                                                {buildingOpen ? (
                                                    <div className="filter__modal js-filter-modal active">
                                                        <div className="filter__modal-inner">
                                                            <div className="checkbox__form">
                                                                {renderBuildings()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </Box>
                                        </ClickAwayListener>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <Box sx={{ position: 'relative' }}>
                                                <button className="filter__button js-filter-button" onClick={handleSectionOpen}>
                                                    <span className="filter__button-text">
                                                        Секция
                                                    </span>
                                                    <div className="filter__button-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"></path></svg>
                                                    </div>
                                                </button>
                                                {sectionOpen ? (
                                                    <div className="filter__modal js-filter-modal active">
                                                        <div className="filter__modal-inner">
                                                            <div className="checkbox__form">
                                                                {renderSections()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </Box>
                                        </ClickAwayListener>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <ClickAwayListener onClickAway={handleClickAwayLevels}>
                                            <Box sx={{ position: 'relative' }}>
                                                <button className="filter__button js-filter-button" data-action="modal" onClick={handleLevelOpen}>
                                                    <span className="filter__button-text">Этаж</span>
                                                    <div className="filter__button-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                                             viewBox="0 0 10 6"
                                                             fill="none">
                                                            <path d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z" fill="black" fillOpacity="0.45"/>
                                                        </svg>
                                                    </div>
                                                </button>
                                                {levelOpen ? (
                                                    <div className="filter__modal js-filter-modal active">
                                                        <div className="filter__modal-inner">
                                                            <div className="range">
                                                                <div className="range__item">
                                                                    <label className="range__item-name">От</label>
                                                                    <input  type="text" onChange={e => setLevelFrom(e.target.value)} value={levelFrom} className="form__input form__input--range js-filter-param"/>
                                                                </div>
                                                                <div className="range__item">
                                                                    <label className="range__item-name">До</label>
                                                                    <input  type="text" onChange={e => setLevelMax(e.target.value)} value={levelMax} className="form__input form__input--range js-filter-param"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </Box>
                                        </ClickAwayListener>
                                    </div>
                                    <div className="filter__item js-filter-item">
                                        <ClickAwayListener onClickAway={handleClickAwayRoom}>
                                            <Box sx={{ position: 'relative' }}>
                                                <button className="filter__button js-filter-button" onClick={handleRoomOpen} data-action="modal">
                                                    <span className="filter__button-text">
                                                        Комнатность
                                                    </span>
                                                    <div className="filter__button-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                                             viewBox="0 0 10 6"
                                                             fill="none">
                                                            <path
                                                                d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z"
                                                                fill="black" fillOpacity="0.45"/>
                                                        </svg>
                                                    </div>
                                                </button>
                                                {roomOpen ? (
                                                    <div className="filter__modal js-filter-modal active">
                                                        <div className="filter__modal-inner">
                                                            <div className="checkbox__form">
                                                                <div className="checkbox__wrap-item checkbox__wrap-item--filter">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size='small' checked={rooms.studio} onChange={handleRooms} name='studio' />
                                                                        }
                                                                        label={'Студия'}
                                                                    />
                                                                </div>
                                                                <div className="checkbox__wrap-item checkbox__wrap-item--filter">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size='small' checked={rooms.one} onChange={handleRooms} name='one' />
                                                                        }
                                                                        label={'1'}
                                                                    />
                                                                </div>
                                                                <div className="checkbox__wrap-item checkbox__wrap-item--filter">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size='small' checked={rooms.two} onChange={handleRooms} name='two' />
                                                                        }
                                                                        label={'2'}
                                                                    />
                                                                </div>
                                                                <div className="checkbox__wrap-item checkbox__wrap-item--filter">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size='small' checked={rooms.tree} onChange={handleRooms} name='tree' />
                                                                        }
                                                                        label={'3'}
                                                                    />
                                                                </div>
                                                                <div className="checkbox__wrap-item checkbox__wrap-item--filter">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size='small' checked={rooms.four} onChange={handleRooms} name='four' />
                                                                        }
                                                                        label={'4+'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </Box>
                                        </ClickAwayListener>
                                    </div>
                                    {/*<div className="filter__item js-filter-item">*/}
                                    {/*    <FormControl sx={{ m: 1, minWidth: 250 }}>*/}
                                    {/*        <Select*/}
                                    {/*            value={vas}*/}
                                    {/*            onChange={handleVases}*/}
                                    {/*            displayEmpty*/}
                                    {/*            inputProps={{ 'aria-label': 'Without label' }}*/}
                                    {/*        >*/}
                                    {/*            <MenuItem value={'none'}>Усиления по каналам</MenuItem>*/}
                                    {/*            {renderVases()}*/}
                                    {/*        </Select>*/}
                                    {/*    </FormControl>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="button__submit-wrap">
                                    <button className="button__submit filterButton" type="submit" onClick={handleSubmit}>
                                        Применить
                                    </button>
                                </div>
                            </div>
                            <div className="filter__reset">
                                <button className="button__close js-close-filter"
                                        style={{backgroundImage:`url(${sprite}#close)`}}
                                        onClick={()=>{setFilterOpen(false)}}>
                                </button>
                                <span className="filter__title">
                                    Фильтры
                                </span>
                                <button className="button__reset js-reset-filter" onClick={()=>{props.resetData()}}>
                                        <span className="button__reset-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <use className="reload-prm" xlinkHref={`${sprite}#reload-prm`}> </use>
                                            </svg>
                                        </span>
                                    <span>Сбросить фильтр</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`sortbox__container js-sort-container ${sortOpen ? 'active' : ''}`} data-show="sort">
                        <form onSubmit={handleSortSubmit} className="sortbox__form js-sort-box">
                            <button className="button__close js-close-filter"
                                    style={{backgroundImage:`url(${sprite}#close)`}}
                                    onClick={()=>{setSortOpen(false)}}>
                            </button>
                            <div className="sortbox__title-wrap">
                                <span className="sortbox__title">
                                    Сортировка
                                </span>
                                <button className="button__reset js-reset-sort" disabled>
                                <span className="button__reset-icon"> </span>
                                </button>
                            </div>
                            <div className="sortbox__box">
                                <div className="sortbox__item">
                                    <label className="radio__custom-container">
                                        <input  type="radio"
                                                className="radio__custom--hidden js-sort-item"
                                                name="sort"
                                                onChange={()=>{setSort('building')}}/>
                                        <span className="radio__custom">
                                        </span>
                                        <span className="radio__custom-text">Корпус</span>
                                    </label>
                                </div>
                                <div className="sortbox__item">
                                    <label className="radio__custom-container">
                                        <input  type="radio"
                                                className="radio__custom--hidden js-sort-item"
                                                name="sort"
                                                onChange={()=>{setSort('section')}}/>
                                        <span className="radio__custom">
                                        </span>
                                        <span className="radio__custom-text">Секция</span>
                                    </label>
                                </div>
                                <div className="sortbox__item">
                                    <label className="radio__custom-container">
                                        <input  type="radio"
                                                className="radio__custom--hidden js-sort-item"
                                                name="sort"
                                                onChange={()=>{setSort('level')}}/>
                                        <span className="radio__custom">
                                        </span>
                                        <span className="radio__custom-text">Этаж</span>
                                    </label>
                                </div>
                                <div className="sortbox__item">
                                    <label className="radio__custom-container">
                                        <input  type="radio"
                                                className="radio__custom--hidden js-sort-item"
                                                name="sort"
                                                onChange={()=>{setSort('num')}}/>
                                        <span className="radio__custom">
                                        </span>
                                        <span className="radio__custom-text">№ кварт.</span>
                                    </label>
                                </div>
                                <div className="sortbox__item">
                                    <label className="radio__custom-container">
                                        <input  type="radio"
                                                className="radio__custom--hidden js-sort-item"
                                                name="sort"
                                                onChange={()=>{setSort('rooms')}}/>
                                        <span className="radio__custom">
                                        </span>
                                        <span className="radio__custom-text">Комнат</span>
                                    </label>
                                </div>
                                <div className="sortbox__item">
                                    <label className="radio__custom-container">
                                        <input  type="radio"
                                                className="radio__custom--hidden js-sort-item"
                                                name="sort"
                                                onChange={()=>{setSort('area')}}/>
                                        <span className="radio__custom">
                                        </span>
                                        <span className="radio__custom-text">Площадь, м2</span>
                                    </label>
                                </div>
                                <div className="sortbox__item">
                                    <label className="radio__custom-container">
                                        <input  type="radio" className="radio__custom--hidden js-sort-item"
                                                name="sort"
                                                onChange={()=>{setSort('price')}}/>
                                        <span className="radio__custom">
                                        </span>
                                        <span className="radio__custom-text">Цена, ₽</span>
                                    </label>
                                </div>
                            </div>
                            <div className="button__group js-switch-container">
                                <div className="button__item">
                                    <button className={`button button--switch js-button-switch ${sortType === 'asc' ? 'active' : ''}`}
                                            onClick={()=>{setSortType('desc')}}>
                                        От большего
                                    </button>
                                </div>
                                <div className="button__item">
                                    <button className={`button button--switch js-button-switch ${sortType === 'desc' ? 'active' : ''}`}
                                            onClick={()=>{setSortType('asc')}}>
                                        От меньшего
                                    </button>
                                </div>
                            </div>
                            <div className="button__submit-wrap">
                                <button className="button__submit filterButton" type="submit">
                                    Применить
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filter;