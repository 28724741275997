import React from 'react';
import nonecontent from '../../assets/img/img/nonecontent.png';
const NoneContent = () => {
    return (
        <div className="common__section">
            <div className='period'>
                <div className="common__container">
                    <div className='period__wrap'>
                        <div className='none-content'>
                            <img src={nonecontent} alt="none content image"/>
                            <p>Данные отсутствуют </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default NoneContent;