import React, {useState,useEffect} from 'react';
import Menu from "../../components/menu";
import MediaHeader from "./media-header";
import MediaMainDetails from "./media-main-details";
import MediaPlanCounter from "./media-plan-counter";
import MediaCian from "./media-cian";
import MediaYandex from "./media-yandex";
import MediaAvito from "./media-avito";
import MediaBase from "./media-base/media-base";
import ContentPopup from "../../components/content-popup";
import OtherCpl from "./other-cpl";

const MediaPlan = () => {
    const [popupOpen,setPopupOpen] = useState(false);
    const [type,setType] = useState('all')
    useEffect(() => {
        const container = document.getElementById('media-plan-content')
        const updatePosition = () => {
            const scrollCube = Math.ceil((container.scrollHeight / 6));
            switch (true){
                case container.scrollTop >= 0 && container.scrollTop <= scrollCube - 100:
                    setType('all')
                break;
                case container.scrollTop > scrollCube - 100 && container.scrollTop < scrollCube * 2 - 100:
                    setType('cian')
                break;
                case container.scrollTop > scrollCube * 2 - 120 && container.scrollTop < scrollCube * 3 - 120:
                    setType('yandex')
                break;
                case container.scrollTop > scrollCube * 3 - 130 && container.scrollTop < scrollCube * 4 - 130:
                    setType('avito')
                break;
                case container.scrollTop > scrollCube * 4 - 130 && container.scrollTop < scrollCube * 5 - 400:
                    setType('other')
                break;
                case container.scrollTop > scrollCube * 5 -400 :
                    setType('cpl')
                break;

            }
        }
        container.addEventListener("scroll", updatePosition);
        updatePosition();
    }, []);

    return (
        <>
            <Menu/>

            <MediaHeader/>
            {
                popupOpen ? <ContentPopup popupOpen={setPopupOpen}>
                    <div className='shareSettings p-3'>
                        <h4>Расширенная настройка</h4>
                        <div className="shareRow mt-3">
                            <div className="title">X2</div>
                            <input type="text" placeholder='Количество'/>
                            <input type="text" placeholder='Цена'/>
                        </div>
                        <div className="shareRow mt-3">
                            <div className="title">X5</div>
                            <input type="text" placeholder='Количество'/>
                            <input type="text" placeholder='Цена'/>
                        </div>
                        <div className="shareRow mt-3">
                            <div className="title">X10</div>
                            <input type="text" placeholder='Количество'/>
                            <input type="text" placeholder='Цена'/>
                        </div>
                    </div>
                </ContentPopup> : ''
            }
            <div className="common__section py-0">
                <div className="output">
                    <div className="common__container">
                        <div className="output__wrap">
                            <div className="content media-plan-content" >
                                <div className="left-side" id='media-plan-content'>
                                    <MediaMainDetails/>
                                    <MediaCian popupOpen={setPopupOpen}/>
                                    <MediaYandex popupOpen={setPopupOpen}/>
                                    <MediaAvito popupOpen={setPopupOpen}/>
                                    <MediaBase popupOpen={setPopupOpen}/>
                                    <OtherCpl/>
                                </div>
                                <div className="right-side">
                                    <MediaPlanCounter type={type} setType={setType}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MediaPlan;