import React from 'react';

const TextPopup = (props) => {
    return (
        <div className='leadPopup'>
            <div className="container">
                <div className='sandedContainer'>
                    <h2>{props.title}</h2>
                    <p>
                        {props.text}
                    </p>
                </div>
                <div className="close" onClick={()=>{props.handleClose(false)}}>x</div>
            </div>
        </div>
    );
};

export default TextPopup;