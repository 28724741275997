import React, {useContext, useEffect, useState} from 'react';
import Menu from "../../components/menu";
import axios from "axios";
import {UserContext} from "../../App";
import {Line} from "react-chartjs-2";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import cianlogo from '../../assets/img/img/cian-logo.png'
import MenuOpenButton from "../../components/menuOpenButton";


const Statistic = () => {
    const user = useContext(UserContext);
    const [cian,setCian] = useState([]);
    const [type,setType] = useState('type1');
    const [type2,setType2] = useState('type1');
    const [complexList,setComplexList] = useState([])
    const [cianCals,setCianCalls] = useState([])
    const [cianCalsList,setCianCallsList] = useState([])
    const [complex,setComplex] = useState(localStorage.getItem('ci'))
    const handleCianCallsData = (data) => {
         return  data.filter(e => e.call_service === "ЦИАН звонки");
    }
    const handleToday = (day) => {
        let date = '';
        if(day === 'toDay'){
             date = new Date().getDate();
        }else {
            date = new Date().getDate() - 1;
        }
        let month = new Date().getMonth() + 1;
        if(date.toString().length === 1){
            date = '0' + date;
        }
        if(month.toString().length === 1){
            month = '0' + month;
        }
       return `${date}.${month}`;
    }
    const handleStatistic = () => {
        let days = cian.map(e => e.date)
        let toDayIndex = days.indexOf(handleToday('toDay'))

        let days2 = cianCals.map(e => e.label)
        let toDayIndex2 = days2.indexOf(handleToday('toDay'))

        let listDataAll = 0;
        let phoneDataAll = 0;
        let uniqueDataAll = 0;
        let viewDataAll = 0;
        let listDataDay = 0;
        let phoneDataDay = 0;
        let uniqueDataDay = 0;
        let viewDataDay = 0;
        let listDataWeek = 0;
        let phoneDataWeek = 0;
        let uniqueDataWeek = 0;
        let viewDataWeek = 0;

        let allAllCalls = cianCalsList.length
        let dayAllCalls = 0
        let weekAllCalls = 0

        let allCelCalls = cianCalsList.filter(e => e.call_status === '1').length;
        let dayCelCalls = 0
        let weekCelCalls = 0
        cianCalsList.map((e,index) => {
            let date = e.call_date.split('.')[0] + '.' + e.call_date.split('.')[1] ;
            if(date === handleToday()){
                dayAllCalls ++;
                if(e.call_status === '1'){
                    dayCelCalls ++;
                }
            }
            if(index <= toDayIndex2 && index > toDayIndex2 - 7){
                weekAllCalls ++;
                if(e.call_status === '1'){
                    weekCelCalls ++;
                }
            }
        })
        cian.map((e,index) => {
            listDataAll += Number(e.list);
            phoneDataAll += Number(e.phone);
            uniqueDataAll += Number(e.unique);
            viewDataAll += Number(e.view);
            if(e.date === handleToday()){
                listDataDay += Number(e.list);
                phoneDataDay += Number(e.phone);
                uniqueDataDay += Number(e.unique);
                viewDataDay += Number(e.view);
            }
            if(index <= toDayIndex && index > toDayIndex - 7){
                listDataWeek += Number(e.list);
                phoneDataWeek += Number(e.phone);
                uniqueDataWeek += Number(e.unique);
                viewDataWeek += Number(e.view);
            }
        })
        return {
            list:listDataAll,
            phone:phoneDataAll,
            unique:uniqueDataAll,
            view:viewDataAll,

            listDay:listDataDay,
            phoneDay:phoneDataDay,
            uniqueDay:uniqueDataDay,
            viewDay:viewDataDay,

            listWeek:listDataWeek,
            phoneWeek:phoneDataWeek,
            uniqueWeek:uniqueDataWeek,
            viewWeek:viewDataWeek,

            allAllCalls:allAllCalls,
            dayAllCalls:dayAllCalls,
            weekAllCalls:weekAllCalls,

            allCelCalls:allCelCalls,
            dayCelCalls:dayCelCalls,
            weekCelCalls:weekCelCalls,
        }
    }
    const handleDate = () => {
         let nDate = new Date();
         let month = nDate.getMonth() + 1;
         if(month.toString().length === 1){
            month = '0' + month;
         }

         let endDay = new Date(nDate.getFullYear(), nDate.getMonth() + 1, 0);

         return {
            from: `01.${month}.${nDate.getFullYear()}`,
             to:`${endDay.getDate()}.${month}.${nDate.getFullYear()}`,
         }
    }
    useEffect(()=>{
        if(user){
            if(user){
                axios.post(`https://api.na100.pro/api.php?page=ffilter`,user)
                    .then(res => {
                        setComplexList(res.data.complex)
                    })
            }
        }
    },[user])
    useEffect(()=>{
        if(user){
            axios.post(`https://api.na100.pro/api.php?page=stat&complex_id=${complex}`,user)
                .then(res => {
                    setCian(res.data.cian)
                })

            axios.post(`https://api.na100.pro/api.php?page=calls&complex=${complex}&from=${handleDate().from}&to=${handleDate().to}`,user)
                .then(res => {
                    setCianCalls(res.data.graph)
                    setCianCallsList(handleCianCallsData(res.data.calls))
                })
        }
    },[user,complex])
    const handleData = ()=>{
        switch (type) {
            case 'type1':
                return [
                    {
                        data: cian.map((el)=>{
                            return el.list
                        }),
                        borderColor: [
                            'blue'
                        ],
                        borderWidth: 3,
                        fill: false,
                        backgroundColor: 'blue'
                    },
                ]
            break
            case 'type2':
                return [
                    {
                        data: cian.map((el)=>{
                            return el.phone
                        }),
                        borderColor: [
                            'blue'
                        ],
                        borderWidth: 3,
                        fill: false,
                        backgroundColor: 'blue'
                    },
                ]
            break
            case 'type3':
                return [
                    {
                        data: cian.map((el)=>{
                            return el.unique
                        }),
                        borderColor: [
                            'blue'
                        ],
                        borderWidth: 3,
                        fill: false,
                        backgroundColor: 'blue'
                    },
                ]
            break
            case 'type4':
                return [
                    {
                        data: cian.map((el)=>{
                            return el.view
                        }),
                        borderColor: [
                            'blue'
                        ],
                        borderWidth: 3,
                        fill: false,
                        backgroundColor: 'blue'
                    },
                ]
            break
        }

    }
    const handleComplex = (event) => {
        setComplex(event.target.value);
    };
    const renderComplex = () => {
        return complexList.map((el)=>{
            if(el.active === 1){
                return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
            }
        })
    }
    return (
        <div className='cian-media'>
            <Menu/>
            <header className="header">
                <div className="common__container">
                    <div className="header__container">
                        <div className="header__inner">
                            <MenuOpenButton/>
                            <div className="breadcrumbs">
                                <ul className="breadcrumbs__list px-3">
                                    <li className="breadcrumbs__item "><span>Статистика по ЖК</span></li>
                                </ul>
                            </div>
                            <div className="filter__item js-filter-item">
                                <FormControl sx={{ m: 1, minWidth: 250 }}>
                                    <Select
                                        value={complex}
                                        onChange={handleComplex}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {/*<MenuItem value={'all'}>Все ЖК</MenuItem>*/}
                                        {renderComplex()}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="common__section py-0">
                <div className="output">
                    <div className="common__container">
                        <div className="output__wrap">
                            <div className="content h-100">
                                <div className="columnsContainer">
                                    <div className="columns">
                                        <div className="output__container bg-white p-3 d-flex ">
                                            <img style={{width:'120px'}} src={cianlogo} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="common__section py-0 pt-0">
                <div className="output">
                    <div className="common__container">
                        <div className="output__wrap">
                            <div className="content h-100">
                                <div className="columnsContainer">
                                    <div className="columns pt-0">
                                        <div className="output__container">
                                            <div className="column bg-white mt-1 p-3 h-auto">
                                                <div className="chartContainer">
                                                    <ul className="output__tabs chartTabsContainer">
                                                        <li className="output__tabs-item">
                                                            <button onClick={()=>{setType2('type1')}} className={`output__tabs-button js-tab ${type2 === 'type1' ? 'active': ''} `}>
                                                                вчера
                                                            </button>
                                                        </li>
                                                        <li className="output__tabs-item">
                                                            <button onClick={()=>{setType2('type2')}} className={`output__tabs-button js-tab ${type2 === 'type2' ? 'active': ''} `}>
                                                                за неделю
                                                            </button>
                                                        </li>
                                                        <li className="output__tabs-item">
                                                            <button onClick={()=>{setType2('type3')}} className={`output__tabs-button js-tab ${type2 === 'type3' ? 'active': ''} `}>
                                                                c начала месяца
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    <div className="graph py-3">
                                                        <div data-aos="zoom-in" data-aos-delay='100' className="item">
                                                            {type2 === 'type3' ?  handleStatistic().list : ''}
                                                            {type2 === 'type2' ?  handleStatistic().listWeek : ''}
                                                            {type2 === 'type1' ?  handleStatistic().listDay : ''}
                                                            <div className="rectLeft"></div>
                                                            <div className="rectRight"></div>
                                                            <p className='text-left'>Количество показов</p>
                                                        </div>
                                                        <div data-aos="zoom-in" data-aos-delay='200' className="item">
                                                            {type2 === 'type3' ?  handleStatistic().unique : ''}
                                                            {type2 === 'type2' ?  handleStatistic().uniqueWeek : ''}
                                                            {type2 === 'type1' ?  handleStatistic().uniqueDay : ''}
                                                            <div className="rectLeft"></div>
                                                            <div className="rectRight"></div>
                                                            <p className='text-left'> Уникальные посетители</p>
                                                            <p className='text-right'>
                                                                {
                                                                    type2 === 'type3' &&  Math.ceil(handleStatistic().unique / handleStatistic().list * 100) ? Math.ceil(handleStatistic().unique / handleStatistic().list * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type2' && Math.ceil(handleStatistic().uniqueWeek / handleStatistic().listWeek * 100) ? Math.ceil(handleStatistic().uniqueWeek / handleStatistic().listWeek * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type1' && Math.ceil(handleStatistic().uniqueDay / handleStatistic().listDay * 100) ? Math.ceil(handleStatistic().uniqueDay / handleStatistic().listDay * 100) + '%' : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div data-aos="zoom-in" data-aos-delay='300' className="item">
                                                            {type2 === 'type3' ?  handleStatistic().view : ''}
                                                            {type2 === 'type2' ?  handleStatistic().viewWeek : ''}
                                                            {type2 === 'type1' ?  handleStatistic().viewDay : ''}
                                                            <div className="rectLeft"></div>
                                                            <div className="rectRight"></div>
                                                            <p className='text-left'>Количество просмотров</p>
                                                            <p className='text-right'>
                                                                {
                                                                    type2 === 'type3' && Math.ceil(handleStatistic().view / handleStatistic().unique * 100) ? Math.ceil(handleStatistic().view / handleStatistic().unique * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type2' && Math.ceil(handleStatistic().viewWeek / handleStatistic().uniqueWeek * 100) ? Math.ceil(handleStatistic().viewWeek / handleStatistic().uniqueWeek * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type1' && Math.ceil(handleStatistic().viewDay / handleStatistic().uniqueDay * 100) ? Math.ceil(handleStatistic().viewDay / handleStatistic().uniqueDay * 100) + '%' : ''
                                                                }

                                                            </p>
                                                        </div>
                                                        <div data-aos="zoom-in" data-aos-delay='400' className="item">
                                                            {type2 === 'type3' ?  handleStatistic().phone : ''}
                                                            {type2 === 'type2' ?  handleStatistic().phoneWeek : ''}
                                                            {type2 === 'type1' ?  handleStatistic().phoneDay : ''}
                                                            <div className="rectLeft"></div>
                                                            <div className="rectRight"></div>
                                                            <p className='text-left'>Количество показов номера телефона</p>
                                                            <p className='text-right'>
                                                                {
                                                                    type2 === 'type3' && Math.ceil(handleStatistic().phone / handleStatistic().view * 100) ? Math.ceil(handleStatistic().phone / handleStatistic().view * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type2' && Math.ceil(handleStatistic().phoneWeek / handleStatistic().viewWeek * 100) ? Math.ceil(handleStatistic().phoneWeek / handleStatistic().viewWeek * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type1' && Math.ceil(handleStatistic().phoneDay / handleStatistic().viewDay * 100) ?  Math.ceil(handleStatistic().phoneDay / handleStatistic().viewDay * 100) + '%' : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div data-aos="zoom-in" data-aos-delay='500' data-aos-offset="0" className="item">
                                                            {type2 === 'type3' ?  handleStatistic().allAllCalls : ''}
                                                            {type2 === 'type2' ?  handleStatistic().weekAllCalls : ''}
                                                            {type2 === 'type1' ?  handleStatistic().dayAllCalls : ''}
                                                            <div className="rectLeft"></div>
                                                            <div className="rectRight"></div>
                                                            <p className='text-left'> Количество звонков</p>
                                                            <p className='text-right'>
                                                                {
                                                                    type2 === 'type3' && Math.ceil(handleStatistic().allAllCalls / handleStatistic().phone * 100) ? Math.ceil(handleStatistic().allAllCalls / handleStatistic().phone * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type2' && Math.ceil(handleStatistic().weekAllCalls / handleStatistic().phoneWeek * 100) ? Math.ceil(handleStatistic().weekAllCalls / handleStatistic().phoneWeek * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type1' && Math.ceil(handleStatistic().dayAllCalls / handleStatistic().phoneDay * 100)  ? Math.ceil(handleStatistic().dayAllCalls / handleStatistic().phoneDay * 100) + '%' : ''
                                                                }
                                                            </p>
                                                        </div>
                                                        <div data-aos="zoom-in" data-aos-delay='600' data-aos-offset="0" className="item">
                                                            <span>
                                                                {type2 === 'type3' ?  handleStatistic().allCelCalls : ''}
                                                                {type2 === 'type2' ?  handleStatistic().weekCelCalls : ''}
                                                                {type2 === 'type1' ?  handleStatistic().dayCelCalls : ''}
                                                            </span>
                                                            <div className="rectLeft"></div>
                                                            <div className="rectRight"></div>
                                                            <p className='text-left'>Количество целевых звонков</p>
                                                            <p className='text-right'>
                                                                {
                                                                    type2 === 'type3' && Math.ceil(handleStatistic().allCelCalls / handleStatistic().allAllCalls * 100)  ? Math.ceil(handleStatistic().allCelCalls / handleStatistic().allAllCalls * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type2' && Math.ceil(handleStatistic().weekCelCalls / handleStatistic().weekAllCalls * 100)  ? Math.ceil(handleStatistic().weekCelCalls / handleStatistic().weekAllCalls * 100) + '%' : ''
                                                                }
                                                                {
                                                                    type2 === 'type1' && Math.ceil(handleStatistic().dayCelCalls / handleStatistic().dayAllCalls * 100) ? Math.ceil(handleStatistic().dayCelCalls / handleStatistic().dayAllCalls * 100) + '%' : ''
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="output__tabs-wrap mt-4 js-tab-wrap bg-white p-3">
                                                    <ul className="output__tabs">
                                                        <li className="output__tabs-item">
                                                            <button onClick={()=>{setType('type1')}} className={`output__tabs-button js-tab ${type === 'type1' ? 'active': ''} `}>
                                                                Количество показов
                                                            </button>
                                                        </li>
                                                        <li className="output__tabs-item">
                                                            <button onClick={()=>{setType('type3')}} className={`output__tabs-button js-tab ${type === 'type3' ? 'active': ''} `}>
                                                                Уникальные посетители
                                                            </button>
                                                        </li>
                                                        <li className="output__tabs-item">
                                                            <button onClick={()=>{setType('type4')}} className={`output__tabs-button js-tab ${type === 'type4' ? 'active': ''} `}>
                                                                Количество просмотров
                                                            </button>
                                                        </li>
                                                        <li className="output__tabs-item">
                                                            <button onClick={()=>{setType('type2')}} className={`output__tabs-button js-tab ${type === 'type2' ? 'active': ''} `}>
                                                                Количество показов номера телефона
                                                            </button>
                                                        </li>

                                                    </ul>
                                                    <div className="output__tabs-box ">
                                                        <div className="output__tabs-section js-tab-section active" data-section="all">
                                                            <div className="output__plan-wrap vasChartSection">
                                                                {
                                                                    cian.length !== 0 ? <Line data={{
                                                                        labels: cian.map((el)=>{
                                                                            return el.date
                                                                        }),
                                                                        datasets: handleData(),
                                                                    }}
                                                                      width={100}
                                                                      height={300}
                                                                      options={{
                                                                          maintainAspectRatio: false,
                                                                          responsive: true,
                                                                          label: {
                                                                              display:false,
                                                                              align:'left'
                                                                          },
                                                                          scales: {
                                                                              myScale: {
                                                                                  type: 'logarithmic',
                                                                                  position: 'left', // `axis` is determined by the position as `'y'`
                                                                              }
                                                                          },
                                                                          plugins: {legend: false},
                                                                      }}
                                                                    /> : <p className='noGraph'>Отсутствуют данные</p>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistic;