import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../App";
import axios from "axios";
import Menu from "../../components/menu";
import AdvHeader from "../../components/advHeader";
import AdvInfoHeader from "../../components/adv-info-header";
import CallsChart from "../../components/callsChart";
import AdvListSettings from "../../components/adv-list-settings";
import VasList from "../../components/vas-list";

const Nasto = () => {
    const [services,setServices] = useState([])
    const [graph,setGraph] = useState([])
    const [chosenDates, setChosenDates] = useState({})
    const [statics, setStatics] = useState([])
    const [selectedId,setSelectedId] = useState(localStorage.getItem('selectedId'))
    const user = useContext(UserContext);
    const handleGraphData = (data) => {
        let fineldata = [];
        for (const property in data) {
            let handledata = {}
            for (const el in data[property]){
                handledata.stat_0 = data[property].count_0
                handledata.stat_1 = data[property].count_1
                handledata.stat_2 = data[property].count_2
                handledata.stat_3 = data[property].count_3
                handledata.stat_4 = data[property].count_4
                handledata.stat_5 = data[property].count_5
                handledata.count_all = data[property].stat_all
            }
            let newData = {
                label:property,
                ...handledata
            }
            fineldata.push(newData)
        }
        return fineldata
    }
    const handleStatisticData = (data) => {
        return {
            stat_all:data.total_all,
            stat_0:data.total_0,
            stat_1:data.total_1,
            stat_2:data.total_2,
            stat_3:data.total_3,
            stat_4:data.total_4,
            stat_5:data.total_5,
        }
    }
    useEffect(()=>{
        if(user !== null){
            axios.post(`https://api.na100.pro/api.php?page=class&complex_id=${selectedId ? selectedId : localStorage.getItem('ci')}`,user)
                .then(res => {
                    setGraph(handleGraphData(res.data.nasto.graph))
                    setStatics(handleStatisticData(res.data.nasto.graph_total))
                    setServices(res.data.nasto.services)
                })
        }
    },[user,selectedId])
    const handleCallsFilter = (data)=>{

    }
    const renderServices = () => {
        return services.map((el)=>{
            return (
                <li className="block__link-item">
                    <a href={el.url} target='_blank' rel="noopener noreferrer" className="block__link-link"  >
                        <span>
                            {el.name}
                        </span>
                    </a>
                </li>

            )
        })
    }
    return (
        <div className='yandex-page'>
            <Menu/>
            <AdvHeader type='nasto' setSelectedId={setSelectedId}/>
            <div className="common__section">
                <section className="period">
                    <div className="common__container">
                        <CallsChart handleCallsFilter={handleCallsFilter} graph={graph} chosenDates={chosenDates} statics={statics}/>
                        <div className="callsFilter filter">
                            <div className="common__container">
                                <div className="filter__box">
                                    <div className="filter__container js-filter-container">
                                        <div className="filter__form js-filter">
                                            <div className="offer__item">
                                                <div className="block__link-box">
                                                    <span className="block__link-title nastoTitleOfferList">Охватные площадки Na100pro</span>
                                                    <div className="block__link">
                                                        <ul className="block__link-list">
                                                            {renderServices()}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    );
};

export default Nasto;