import React from 'react';
import './flatitem.scss';
import {Link} from "react-router-dom";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import vasicons from '../../../assets/img/img/vasicons.png';
import sprite from "../../../assets/img/sprites/sprite.svg";
const FlatItem = (props) => {
    const {flat_id,flat_campaign,flat_image,flat_title,flat_building,
        complex_name,flat_image_full,flat_section,flat_level,
        flat_rooms,flat_num,flat_area,flat_price,flat_price2,flat_vas} = props.data
    const check = Object.keys(props.checkes).length;
    const priceHandler = (num)=>{
        let fv =  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return fv.replace(',',' ')
    }
    const handleCheck = () => {
        if(!check){
            return false
        }
    }
    return (
        <div className="apartment__table-row flatItem">
            <div className="apartment__table-body-item apartment__table-body-item--small">
                <span>
                    <label className="checkbox__custom-container">
                            <input type="checkbox"
                                   checked={handleCheck()}
                                   onChange={props.handleChecks}
                                   name={flat_id}
                                   className="checkbox__custom--hidden"/>
                            <span className="checkbox__custom">

                            </span>
                    </label>

                </span>
            </div>
            <div className="apartment__table-body-item apartment__table-body-item--big">
                <div className="apartment__table-inner">
                    <div className="apartment__plan max-35">
                        <Zoom>
                            <picture>
                                <img
                                    alt="that wanaka tree"
                                    src={flat_image_full}
                                    width="100%"
                                />
                            </picture>
                        </Zoom>
                    </div>
                    <Link  to={`/offer/${flat_id}`} className="apartment__link">{flat_title}</Link>
                </div>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Корпус</span>
                <span className='flat_building'>{flat_building}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Секция</span>
                <span>{flat_section}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Этаж</span>
                <span>{flat_level}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">№ кварт.</span>
                <span>{flat_num}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Комнат</span>
                <span>{flat_rooms}</span>
            </div>
            <div className="apartment__table-body-item apartment__table-body-item--middle">
                <span className="apartment__table-body-name">Площадь, м2</span>
                <span>{flat_area}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Цена, ₽</span>
                <div className="apartment__table-box">
                    <span className='price_flat'>{flat_price2 !== 0 ? <del>{priceHandler(flat_price)}</del> : priceHandler(flat_price)}</span>
                    <span className='price_flat'>{flat_price2 !== 0 ? priceHandler(flat_price2) : ''}</span>
                </div>
            </div>
            <div className={`apartment__table-body-item align-center ${flat_vas !== null && flat_campaign.length !== 0 ? 'justify-content-start' : 'justify-content-start'}`}>
                {/*<span className="common__hint">*/}
                {/*    {flat_vas !== null ? <div className="common__hint-icon mr-5 "><img src={vasicons} alt="vas icon" className='vasIcon'/></div>*/}
                {/*         : ''}*/}
                {/*    <div className="common__hint-desc" dangerouslySetInnerHTML={{__html:flat_vas}}></div>*/}
                {/*</span>*/}
                {
                    flat_campaign.length !== 0 ? <span className="common__hint stockIcon">
                    <div className="common__hint-icon mx-5 mal-10">
                          {flat_campaign.length !== 0 ? <div className='flatsStockIcon'>акции</div> : ''}
                    </div>
                        {flat_campaign.length !== 0 ? <div className="common__hint-desc" >
                            {
                                flat_campaign.map((el)=>{
                                    return (
                                        <p>{el}</p>
                                    )
                                })
                            }
                        </div> : ''}

                </span> : ''
                }


            </div>
        </div>
    );
};

export default FlatItem;