import React, {useContext, useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import sprite from "../../assets/img/sprites/sprite.svg";
import './call-type-popup.scss';
import RadioGroup from '@mui/material/RadioGroup';
import {Radio} from "@mui/material";
import axios from "axios";
import {UserContext} from "../../App";
import downloadbutton from "../../assets/img/img/downloadbutton.png";

const CallTypePopup = (props) => {
    const [status, setStatus] = React.useState('1');
    const [message, setMessage] = React.useState('');
    const [sanded,setSanded] = useState(false);
    const [file,setFile] = useState()
    const user = useContext(UserContext);
    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const handleSubmit = ()=>{

        user.append('id',props.callId)
        user.append('status',status)
        user.append('reason',message)
        axios.post(`https://api.na100.pro/api.php?page=refuse`,user)
            .then(res=>{
                if(res.data.result === 'success'){
                    setSanded(true)
                }
            })
    }
    return (
        <div className='leadPopup'>
            <div className="container">
                {sanded ? <div className='sandedContainer'>
                    <h2>Ваша заявка принята.</h2>
                    <p>
                        Изменения вступят в силу после обработки заявки нашими специалистами.
                    </p>
                </div>: <div className="content flex-column">
                    <h2 className='title'>Изменить статус</h2>
                    <h3 className='subTitle'>Статус</h3>
                    <div className="checksContainer">
                        <RadioGroup
                            aria-label="status"
                            name="controlled-radio-buttons-group"
                            value={status}
                            onChange={handleChange}>
                            <FormControlLabel value="1" control={<Radio/>} label="Не целевой"/>
                            <FormControlLabel value="3" control={<Radio/>} label="Повторный"/>
                        </RadioGroup>
                    </div>
                    <div className="tarifs">
                        Комментарий
                        <span className="common__hint">
                    </span>
                    </div>
                    <div className="checksContainer">
                        <textarea name="" id="" cols="30" rows="7"
                                  onChange={e => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className="fileUploadContainer">
                        <div className="fileContainer">
                            <h3 className="fileTitle d-flex flex-column"><span className='d-flex w-100 white-space'>Добавьте запись разговора</span>
                                {file?.name}
                            </h3>
                            <label className='fileLabel'><img src={downloadbutton} alt=""/> Добавить файл  <input onChange={(e)=>{setFile(e.target.files[0])}} type="file" placeholder='загрузить файл' hidden/></label>
                        </div>
                    </div>
                    <div className="save">
                        <button onClick={handleSubmit}>Сохранить</button>
                    </div>
                </div>}
                <div className="close" onClick={()=>{props.setOpenPopup(false)}}>x</div>
            </div>
        </div>
    );
};

export default CallTypePopup;