import React, {useContext, useEffect, useState} from 'react';
import settingsIcon from '../../assets/img/img/settingsicon.png';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import {UserContext} from "../../App";

const AdvInfoHeader = (props) => {
    const firstId = localStorage.getItem('ci');
    const selectedId = localStorage.getItem('selectedId')
    const [complex,setComplex] = useState(firstId)
    const [complexList,setComplexList] = useState([])
    const user = useContext(UserContext)
    useEffect(()=>{
        if(user !== null){
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplexList(res.data.complex.filter(e => e.active === 1))
                })
        }
    },[user])
    const handleComplex = (event) => {
        setComplex(event.target.value);
        localStorage.setItem('selectedId',event.target.value)
        props.setSelectedId(event.target.value)

    };
    const renderComplex = () => {
        return complexList.map((el)=>{
            if(el.active === 1){
                return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
            }
        })
    }
    return (
        <FormControl sx={{ m: 1, minWidth: 250 }}>
            <Select
                value={complex}
                onChange={handleComplex}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {/*<MenuItem value={'all'}>Все ЖК</MenuItem>*/}
                {renderComplex()}
            </Select>
        </FormControl>
    );
};

export default AdvInfoHeader;