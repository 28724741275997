import React from 'react';

const NoteItem = (props) => {
    const {news_head,news_date,news_text,news_read} = props.note;
    return (
        <li className="notification-page__item" style={{backgroundColor:news_read === 1 ? '#ffffff' : '#F6F9FF'}}>
            <div className="notification-page__main">
                <span className="notification-page__title">
                    {news_head}
                </span>
                <span className="notification-page__date">
                    {news_date}
                </span>
            </div>
            <div className="notification-page__content js-text-action-box">
                <div className="notification-page__content-inner js-text-action-content">
                    <p>
                        {news_text}
                    </p>
                </div>
                <button className="button__open js-button-show-content open">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                         viewBox="0 0 10 6" fill="none">
                        <path
                            d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z"
                            fill="black" fillOpacity="0.45"></path>
                    </svg>
                </button>
            </div>
        </li>
    );
};

export default NoteItem;