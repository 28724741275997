import React, {useContext} from 'react';
import {SetMenuOpen} from "../../App";
import sprite from "../../assets/img/sprites/sprite.svg";

const MenuOpenButton = () => {
    const setMenuOpen = useContext(SetMenuOpen);
    return (
        <button className="button__menu  js-open-menu"
                style={{backgroundImage:`url(${sprite}#menu)`}}
                onClick={()=>{setMenuOpen(true)}}>
        </button>
    );
};

export default MenuOpenButton;