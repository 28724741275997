import React from 'react';

const CompItem = (props) => {
    const {complex_name,client_name,count_studio,count_1,count_2,count_3,count_4} = props.data;
    const colors = ['light-green','green','pink','orange','red'];
    const priceHandler = (num)=>{
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }
    return (
        <div className="competitor__table-row">
            <div className="competitor__table-item competitor__table-item--main">
                <span>{complex_name}</span>
            </div>
            <div className="competitor__table-item competitor__table-item--main">
                <span>{client_name}</span>
            </div>
            <div
                className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                <span>{props.price ? priceHandler(Number(count_studio)) : count_studio}</span>
            </div>
            <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                <span>{props.price ? priceHandler(Number(count_1)) : count_1}</span>
            </div>
            <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                <span>{props.price ? priceHandler(Number(count_2)) : count_2}</span>
            </div>
            <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                <span>{props.price ? priceHandler(Number(count_3)) : count_3}</span>
            </div>
            <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                <span>{props.price ? priceHandler(Number(count_4)) : count_4}</span>
            </div>
            {/*<div className="competitor__table-item competitor__table-item--status">*/}
            {/*    <span>32</span>*/}
            {/*</div>*/}
        </div>
    );
};

export default CompItem;