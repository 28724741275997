import React, {useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";

const AvgPrice = (props) => {
    const [graph,setGraph] = useState([]);
    const [length,setLength] = useState();
    const graphsDataCorrecter = (data)=>{
        let firstDate = data[0]
        let secondDate = data[1]
        if(!firstDate && !secondDate){
            return [];
        }
        let dateArrayNfd = firstDate.date.split('.')
        let dateArrayNsd = firstDate.date.split('.')
        let nfd = new Date().setFullYear(1970,Number(dateArrayNfd[1]),Number(dateArrayNfd[0]))
        let nsd = new Date().setFullYear(1970,Number(dateArrayNsd[1]),Number(dateArrayNsd[0]))
        if(nfd > nsd){
            return data.reverse()
        }else{
            return data
        }
    }
    useEffect(()=>{
        setGraph(graphsDataCorrecter(props.graph))
        let lengthSum = 0;
        props.graph.map((el)=>{
            lengthSum += Number(el.price)
        })
        setLength(lengthSum)
    },[props.graph])
    const monthsList = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль',
        'Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    return (
        <div className="output__item avgChart">
            <span className="output__title output__title--margin">
                Изменения цен за {monthsList[new Date().getMonth()]} {new Date().getFullYear()} г.
            </span>
            <div className="output__container">
                <div className="output__plan-wrap vasChartSection">
                    {graph.length !== 0 && length ? <Line data={{
                        labels: graph.reverse().map((el)=>{
                            return el.date
                        }),
                        datasets: [{
                            legend: false,
                            data: graph.reverse().map((el)=>{
                                return Number(el.price)
                            }),
                            borderColor: 'rgb(143 85 221)',
                            fill: true,
                            backgroundColor: '#8f55ddb0',

                        }],
                    }}
                    width={100}
                    height={280}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {legend: false},
                        // scales: {
                        //     // myScale: {
                        //     //     type: 'logarithmic',
                        //     //     position: 'left',
                        //     // },
                        //     yAxes: [{
                        //         ticks: {
                        //             precision: 0
                        //         }
                        //     }],
                        // }
                        scales: {
                            y: {
                                ticks: {
                                    stepSize: 1,
                                    beginAtZero: true,
                                },
                            },
                        },
                    }}
                    /> : <p className='noGraph'>Отсутствуют данные</p>}

                </div>
            </div>
        </div>
    );
};

export default AvgPrice;