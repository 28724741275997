import React from 'react';
import editIcon from '../../assets/img/img/editicon.png'
import trashicon from '../../assets/img/img/trashicon.png'
const StockItem = (props) => {
    const {campaign_id,campaign_name,campaign_text,campaign_end} = props.data;
    return (
        <div className='stockItem'>
            <div className="titleSide">
                <h2>{campaign_name}</h2>
            </div>
            <div className="mainSide">
                <div className="titleContainer">
                    <h3 className='title'> Действует до {campaign_end}</h3>
                    <div className="buttonsContainer">
                        <button onClick={()=>{
                            props.setDataForUpdate(props.data)
                            props.setPopup(true)
                            props.setPopupType('update')
                        }}>
                            <img src={editIcon} alt="edit icon"/>
                        </button>
                        <button onClick={()=>{
                            props.setDeletePopupOpen(true)
                            props.setDeleteStockId(campaign_id)
                        }}>
                            <img src={trashicon} alt="trash icon"/>
                        </button>
                    </div>
                </div>
                <p className="text">{campaign_text}</p>
                <div className="section sectionRow">
                    <div className="columnLogo first">
                        <div className="smallTitle">Акция от застройщика</div>
                        <div className="section">
                            <div className="logo">
                                <img src={props.client.client_image} alt="developer logo"/>
                            </div>
                            <div className="developerName">{props.client.client_name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockItem;