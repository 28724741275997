import React, {useContext, useEffect, useState} from 'react';
import Menu from "../../components/menu";
import CallsHeader from "../../components/callsHeader";
import CallsChart from "../../components/callsChart";
import CallItemClassified from "./callItem-classified";
import axios from "axios";
import {RightsContext, UserContext} from "../../App";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import sprite from "../../assets/img/sprites/sprite.svg";
import NoneContent from "../../components/none-content";
const CallsClassified = ({match}) => {
    const user = useContext(UserContext);
    const [complex,setComplex] = useState([])
    const [complexId,setComplexId] = useState(0)
    const [callsList,setCallsList] = useState([])
    const [callsListToShow,setCallsListToShow] = useState([])
    const [graph,setGraph] = useState([])
    const [productsCount, setProductsCount] = useState(10)
    const [pagesCount, setPagesCount] = useState(0)
    const [page, setPage] = useState(1)
    const [chosenDates, setChosenDates] = useState({})
    const [statics, setStatics] = useState([])
    const [noneContent,setNoneContent] = useState(false)
    const dateFormatChanger = (date) => {
        let month = date.getMonth();
        let dateFormat = date.getDate();
        if(month.toString().length === 1){
            month = '0' + (Number(date.getMonth()) + 1);
        }else{
            month = Number(date.getMonth()) + 1;
        }
        if(dateFormat.toString().length === 1){
            dateFormat = '0' + date.getDate();
        }
        return dateFormat + '.' + (month) + '.' + date.getFullYear()
    }
    const [dates,setDates] = useState({
        startDay:dateFormatChanger(new Date()),
        endDay:dateFormatChanger(new Date()),
    })
    const [sort,setSort] = useState('')
    const [order,setOrder] = useState('asc')
    const datesHandler = (e) => {
        setDates({
            startDay: dateFormatChanger(e.startDay),
            endDay: dateFormatChanger(e.endDay),
        })
    }
    const handleSortingService = (sort,order)=>{
        setSort(sort)
        setOrder(order)
    }
    const rights = useContext(RightsContext);
    useEffect(()=>{
        if(rights.indexOf('lklead') === -1){
            window.location.href = '/no-access'
        }
    },[])
    useEffect(()=>{
        if(user !== null){
            user.append('sort',sort)
            user.append('order',order)
            axios.post(`https://api.na100.pro/api.php?page=leadcalls&complex=${match.params.id ? match.params.id : complexId}&from=${dates.startDay}&to=${dates.endDay}`,user)
                .then(res => {
                    if(res.data.message === 'Access Denied'){
                        setNoneContent(true)
                    }else{
                        setStatics(res.data.statistic)
                        setGraph(res.data.graph)
                        setCallsList(res.data.calls)
                        setChosenDates(res.data.dates)
                        setCallsListToShow(res.data.calls.slice(0,10))
                    }
                })
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplex(res.data.complex)
                })
        }
    },[user,dates,complexId,match.params.id,sort,order])
    useEffect(()=>{
        if(user !== null) {
            let data = callsList;
            setPagesCount(Math.ceil(data.length / productsCount))
            let pageinationData = data.slice((page - 1) * productsCount, page * productsCount)
            setCallsListToShow(pageinationData);
        }
    },[callsList,page,productsCount])
    const handleComplexId = (id) => {
        setComplexId(id);
    }
    const handlePage = (event, value) => {
        setPage(value);
    };
    const handleChange = (event) => {
        setProductsCount(event.target.value);
    };
    const renderItems = () => {
        return callsListToShow.map((el)=>{
            return <CallItemClassified key={el.call_id} data={el}/>
        })
    }

    return (

        <div>
            <Menu/>

            {noneContent ? <NoneContent/> : <>
                <CallsHeader datesHandler={datesHandler} complex={complex}
                             handleComplexId={handleComplexId} matchId={match.params.id}/>
                <div className="common__section">
                    <section className="period">
                        <div className="common__container">
                            <CallsChart graph={graph} chosenDates={chosenDates} statics={statics}/>
                            <div className="period__table-container">
                                <div className="period__table">
                                    <div className="period__table-header">
                                        <div className="period__table-header-item">
                                            <div className="period__table-header-inner">
                                                Дата
                                            </div>
                                        </div>
                                        <div className="period__table-header-item">
                                            <div className="period__table-header-inner">
                                                Номер клиента
                                            </div>
                                        </div>
                                        <div className="period__table-header-item pl-item">
                                            <div className="period__table-header-inner">
                                                PID
                                            </div>
                                            <div className="common__sort">
                                                <button
                                                    onClick={()=>{handleSortingService('service','asc')}}
                                                    style={{backgroundImage:`url(${sprite}#triangle)`}}
                                                    className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                                <button
                                                    onClick={()=>{handleSortingService('service','desc')}}
                                                    style={{backgroundImage:`url(${sprite}#triangle)`}}
                                                    className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                            </div>
                                        </div>
                                        <div className="period__table-header-item">
                                            <div className="period__table-header-inner">
                                                Статус
                                            </div>
                                            <div className="common__sort">
                                                <button
                                                    onClick={()=>{handleSortingService('status','asc')}}
                                                    style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                                <button
                                                    onClick={()=>{handleSortingService('status','asc')}}
                                                    style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                            </div>
                                        </div>
                                        <div className="period__table-header-item">
                                            <div className="period__table-header-inner">
                                                Продолжительность
                                            </div>
                                        </div>
                                        <div className="period__table-header-item playerHeader">
                                            <div className="period__table-header-inner">
                                                Плеер
                                            </div>
                                        </div>
                                    </div>
                                    <div className="period__table-body">
                                        {renderItems()}
                                    </div>
                                    <div className="pagination__wrap">
                                        <div className="pagination">
                                            <div className="pagination__select">
                                                <FormControl sx={{ m: 1, minWidth: 165,maxHeight:35 }}>
                                                    <Select
                                                        value={productsCount}
                                                        onChange={handleChange}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value={10}>Выводить по 10</MenuItem>
                                                        <MenuItem value={20}>Выводить по 20</MenuItem>
                                                        <MenuItem value={100}>Выводить по 100</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="pagination__list-wrap">
                                                <Stack spacing={2}>
                                                    <Pagination count={pagesCount} shape="rounded"  page={page} onChange={handlePage}/>
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>}
        </div>
    )
}

export default CallsClassified;