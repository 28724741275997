import React, {useContext, useEffect, useState} from 'react';
import StandartHeader from "../../components/standart-header/standart-header";
import Menu from "../../components/menu";
import './partner.scss';
import PartnerItem from "./partner-item";
import {RightsContext} from "../../App";
const Partners = () => {
    const [partnersList, setPartnersList] = useState([
        {name:'Pid1'},
        {name:'Pid2'},
        {name:'Pid3'},
        {name:'Pid4'},
        {name:'Pid5'},
        {name:'Pid6'},
        {name:'Pid6'},
        {name:'Pid7'},
        {name:'Pid9'},
        {name:'Pid10'},
        {name:'Pid11'},
        {name:'Pid12'},
    ])
    const rights = useContext(RightsContext);
    useEffect(()=>{
        if(rights.indexOf('lklead') === -1){
            window.location.href = '/no-access'
        }
    },[])
    const renderPartners = ()=>{
        return partnersList.map((el)=>{
            return <PartnerItem key={new Date().toString()} name={el.name}/>
        })
    }
    return (
        <div>
            <Menu/>
            <StandartHeader title={'Партнеры'}/>
            <div className="common__section">
                <div className="notification-page  ">
                    <div className="common__container ">
                        <div className="partnersContainer">
                            <h1>Управление списком разрешенных партнеров</h1>
                            <p>В случае необходимости, вы всегда можете изменить состав разрешенных партнеров.</p>
                            <div className="partnersTable">
                                {renderPartners()}
                            </div>
                            <div className="buttonContainer">
                                <div className="bigButton">сохранить</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;