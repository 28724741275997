import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import HomeHeader from "./homeHeader";
import ProductItem from "./productItem";
import {UserAuthContext, UserContext} from "../../App";
import Menu from "../../components/menu";

const HomePage = () => {
    const [client,setClient] = useState({});
    const [products,setProducts] = useState([]);
    const [allProducts,setAllProducts] = useState([]);
    const [activation, setActivation] = useState('active');
    const [productsCount, setProductsCount] = useState(10);
    const [allActiveComplexes,setAllActiveComplexes] = useState([])
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const [complexGroup,setComplexGroup] = useState([])
    const handleActivation = (e) => {
        setActivation(e);
        setPage(1)
    }
    useEffect(()=>{
        userAuth();
        if(user !== null){
            user.append('link',' ');
            axios.post(`https://api.na100.pro/api.php?page=index`,user)
                .then(res => {
                    setClient(res.data.client)
                    console.log("client",res.data.client);
                    setAllProducts(res.data.complex)
                    let firstData = res.data.complex.filter(e => e.complex_status === activation).slice(0,10)
                    setAllActiveComplexes(res.data.complex.filter(e => e.complex_status === activation))
                    setProducts(firstData)
                    setPagesCount(Math.ceil(firstData.length / productsCount))
                })
        }
    },[user])
    useEffect(()=>{
        if(user !== null) {
            let data = []
            activation === 'all' ? data = allProducts : data = allProducts.filter(e => e.complex_status === activation)
            setPagesCount(Math.ceil(data.length / productsCount))
            let pageinationData = data.slice((page - 1) * productsCount, page * productsCount)
            setProducts(pageinationData)
        }
    },[allProducts,activation,page,productsCount])
    useEffect( ()=>{
        if(client.client_name === 'Брусника'){
            const uniqueValues = allProducts.reduce((accumulator, current) => {
                if (!accumulator.includes(current.complex_group)) {
                    accumulator.push(current.complex_group);
                }
                return accumulator;
            }, []);
            setComplexGroup(uniqueValues)
        }

    },[client,allProducts])
    const renderProducts = () => {
        return products.map((el)=>{
            return <ProductItem key={el.complex_id} data={el}/>
        })
    }
    const handleShowMore = ()=>{
        let count = productsCount + 10;
        setProductsCount(count)
    }
    const renderBrusnika = ()=>{
        return complexGroup.map((gr) => {
            return (
                <div>
                    <div className="product__header">
                        <div className="product__icon">
                            <img src={client.client_image}
                                 width="48" height="48" alt={client.client_name}/>
                        </div>
                        <span className="product__name">
                            {gr === '' ? 'Екатеринбург' : gr}
                        </span>
                    </div>
                    <div className="product__box">
                        {
                            allActiveComplexes.filter(e=>e.complex_group === gr).map((el)=>{
                                // console.log(el)
                                return <ProductItem key={el.complex_id} data={el}/>
                            },)
                        }
                    </div>
                </div>
            )
        })
    }
    return (
        <div>
            <Menu/>
            <HomeHeader title={true} activation={activation} setActivation={handleActivation}/>
            <div className="common__section classifiedPage">
                <section className="product">
                    <div className="common__container">
                        <div className="js-product-box">
                            <div className="product__block" id="data-container">
                                {
                                    client.client_name !== 'Брусника' ?
                                        <>
                                            <div className="product__header">
                                                <div className="product__icon">
                                                    <img src={client.client_image}
                                                         width="48" height="48" alt={client.client_name}/>
                                                </div>
                                                <span className="product__name">
                                                    {client.client_name}
                                                </span>
                                            </div>
                                            <div className="product__box">
                                                {renderProducts()}
                                            </div>
                                        </>
                                        : renderBrusnika()

                                }
                                {
                                    client.client_name !== 'Брусника' ? <>
                                        {products.length !==  allProducts.length && products.length >= 10  ? <div className="showMore" onClick={handleShowMore}>Показать еще {products.length} из {allProducts.length}</div> : ''}
                                    </>:''
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    );
};

export default HomePage;