import React from 'react';
import StandartHeader from "../../components/standart-header/standart-header";
import Menu from "../../components/menu";
import vasprogress from '../../assets/img/img/vasprogress.png';
import vasstar from '../../assets/img/img/vasstar.png';
import vasup from '../../assets/img/img/vasup.png';

const AddVas = () => {
    return (
        <div>
            <Menu/>
            <StandartHeader title={'Добавление ВАСа'}/>
            <div className="common__section calls-exchange-page">
                <section className="product">
                    <div className="common__container add-object-container">
                        <div className="addVasPage">
                            <p className='availableBudget'>
                                Доступный бюджет: <span>5 684 ₽</span>
                            </p>
                            <p className="smallTitle">Квартира</p>
                            <p className="roomsTitles">
                                2-комн. апартаменты в новом жилом комплексе, от застройщика, метр...
                            </p>
                            <div className="vasGroupsRow">
                                <div className="vasGroups">
                                    <h3>Яндекс Недвижимость</h3>
                                    <table>
                                        <tr>
                                            <th>Тип ВАСа</th>
                                            <th>Количество дней</th>
                                            <th>Стоимость, ₽</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='titleContainer'>
                                                    <img src={vasup} alt="vas icon" className='icon'/>
                                                    <p className="title">Поднятие</p>
                                                </div>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='titleContainer'>
                                                    <img src={vasstar} alt="vas icon" className='icon'/>
                                                    <p className="title">Премиум</p>
                                                </div>
                                            </td>
                                            <td>

                                                <input type="text"/>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='titleContainer'>
                                                    <img src={vasprogress} alt="vas icon" className='icon'/>
                                                    <p className="title">Продвижение</p>
                                                </div>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="vasGroups">
                                    <h3>Авито</h3>
                                    <table>
                                        <tr>
                                            <th>Тип ВАСа</th>
                                            <th>Количество дней</th>
                                            <th>Стоимость, ₽</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='titleContainer'>
                                                    <img src={vasup} alt="vas icon" className='icon'/>
                                                    <p className="title">Поднятие</p>
                                                </div>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='titleContainer'>
                                                    <img src={vasstar} alt="vas icon" className='icon'/>
                                                    <p className="title">Премиум</p>
                                                </div>
                                            </td>
                                            <td>

                                                <input type="text"/>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='titleContainer'>
                                                    <img src={vasprogress} alt="vas icon" className='icon'/>
                                                    <p className="title">Продвижение</p>
                                                </div>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="buttonContainer">
                                <button className="button button--switch active">
                                    Добавить ВАСы - 10 940 ₽
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AddVas;