import React from 'react';
import Zoom from "react-medium-image-zoom";
import {Link} from "react-router-dom";
import vasicons from "../../assets/img/img/vasicons.png";

const AuctionTableRow = (props) => {
    const {history_date,history_place_st,history_value,history_liquid,history_place_1,history_place_2,history_place_3,history_place_4} = props.data
    return (
        <div className="apartment__table-row flatItem">
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Дата</span>
                <span className='flat_building'>{history_date}</span>
            </div>
            {/*<div className="apartment__table-body-item">*/}
            {/*    <span className="apartment__table-body-name">Позиция</span>*/}
            {/*    <span>{position}</span>*/}
            {/*</div>*/}
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Ликвидность</span>
                <span>{history_liquid}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Ставка, ₽</span>
                <span>{history_value}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Ст.</span>
                <span>{history_place_st}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">1</span>
                <span>{history_place_1}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">2</span>
                <span>{history_place_2}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">3</span>
                <span>{history_place_3}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">4+</span>
                <span>{history_place_4}</span>
            </div>
        </div>
    );
};

export default AuctionTableRow;