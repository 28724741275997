import React, {useContext, useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {UserContext} from "../../../App";
import PageLoader from "../../../components/page-loader";

const AuctionSection = (props) => {
    const [stockController,setStockController] = useState(0)
    const [startDate, setStartDate] = useState(new Date());
    const [category,setCategory] = useState('0')
    const [available,setAvailable] = useState()
    const [money,setMoney] = useState('')
    const [max,setMax] = useState('')
    const [pos,setPos] = useState()
    const [bet,setBet] = useState()
    const [loader,setLoader] = useState(true)
    const user = useContext(UserContext)
    const handleCategory = (event) => {
        setCategory(event.target.value);
    };
    useEffect(()=>{
        setLoader(true)
        if(user !== null){
            axios.post(`https://api.na100.pro/api.php?page=auction&complex_id=${props.selectedId}`,user)
                .then(res => {
                    setMoney(res.data.complex_cian_money)
                    setMax(res.data.complex_cian_max)
                    setPos(res.data.complex_cian_pos)
                    setStockController(res.data.auction_status)
                    setAvailable(res.data.auction_available)
                    setBet(res.data.auction_bet)
                    setLoader(false)
                })
        }
    },[user,props.selectedId])
    const handleSubmit = () => {
        if(max > 15000){
            let pr = window.confirm('Вы уверены что хотите изменить Максимальную  ставку ?')
            if(pr){
                setLoader(true)
                const fd = new FormData
                fd.append('auction_available',available)
                fd.append('auction_status',stockController.toString())
                fd.append('bet',stockController === 0 ? '0' : bet)
                fd.append('strategy',category)
                fd.append('money',money)
                fd.append('pos',pos)
                fd.append('max',max)
                fd.append('auth',localStorage.getItem('token'))
                axios.post(`https://api.na100.pro/api.php?page=auction&complex_id=${props.selectedId}&do=update`,fd)
                    .then(res => {
                        setLoader(false)
                    })
            }
        }else{
            setLoader(true)
            const fd = new FormData
            fd.append('auction_available',available)
            fd.append('auction_status',stockController.toString())
            fd.append('bet',stockController === 0 ? '0' : bet)
            fd.append('strategy',category)
            fd.append('money',money)
            fd.append('pos',pos)
            fd.append('max',max)
            fd.append('auth',localStorage.getItem('token'))
            axios.post(`https://api.na100.pro/api.php?page=auction&complex_id=${props.selectedId}&do=update`,fd)
                .then(res => {
                    setLoader(false)
                })
        }

    }
    return (
        <div className="d-flex w-50 position-relative">
            {available == 0 ? <div className="d-flex position-absolute top-0 left-0 w-100 h-100 auctionCover"></div> : ''}
                {loader ? <div className='preloaderSmall'> <PageLoader type={'small'}/> </div>: ''}
            <div className="stockControllerWrapper">
                {available == 0 ? <p className='title mb-3'>
                    Аукцион ЦИАН недоступен. <br/>
                    <span>Обратитесь к вашему менеджеру для активации.</span>
                </p> : ''}
                <p className='title d-flex'>
                    Управление аукционом -
                    <div className="buttonsContainer">
                        <div onClick={()=>setStockController(1)} className={`buttons ${stockController ? 'active' : ''}`}>
                            ВКЛ
                        </div>
                        <div onClick={()=>setStockController(0)} className={`buttons ${!stockController ? 'active' : ''}`}>
                            ВЫКЛ
                        </div>
                    </div>
                </p>
                <div className="d-flex w-100 mt-5">
                    <div className="w-50 d-flex align-items-center">
                        <p className='text-body mb-0'>Бюджет аукциона (с НДС, руб)</p>
                    </div>
                    <div className="w-50">
                        <input type="text" value={money} onChange={(e)=>setMoney(e.target.value)}/>
                    </div>
                </div>
                {/*<div className="d-flex w-100 mt-3">*/}
                {/*    <div className="w-50 d-flex align-items-center">*/}
                {/*        <p className='text-body mb-0'>Период РК</p>*/}
                {/*    </div>*/}
                {/*    <div className="d-flex w-50">*/}
                {/*        <div className="w-50 me-2">*/}
                {/*            <DatePicker selected={startDate} onChange={(date)=> setStartDate(date)} />*/}
                {/*        </div>*/}
                {/*        <div className="w-50 ms-2">*/}
                {/*            <DatePicker selected={startDate} onChange={(date)=> setStartDate(date)} />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="d-flex w-100 mt-3">
                    <div className="w-50 d-flex align-items-center">
                        <p className='text-body mb-0'>Выбор стратегии</p>
                    </div>
                    <div className="d-flex w-50">
                        <div className="w-100 select">
                            <FormControl sx={{ m: 1, minWidth: 414 }}>
                                <Select
                                    value={category}
                                    onChange={handleCategory}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={'0'}>Обычная (поднятие ставки, откат при лимите)</MenuItem>
                                    <MenuItem value={'1'}>Пассивная (минимальная  ставка, присутствие)</MenuItem>
                                    <MenuItem value={'2'}>Агрессивная (поднятие ставки до лимита)</MenuItem>
                                    <MenuItem value={'3'}>Бережливая (минимальная ставка при достижении лимита)</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 mt-3">
                    <div className="w-50 d-flex align-items-center">
                        <p className='text-body mb-0'>Желаемая позиция</p>
                    </div>
                    <div className="d-flex w-50">
                        <div className="w-100 select">
                            <input type="text" value={pos} onChange={(e)=>{setPos(e.target.value)}}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 mt-3">
                    <div className="w-50 d-flex align-items-center">
                        <p className='text-body mb-0'>Максимальная ставка</p>
                    </div>
                    <div className="d-flex w-50">
                        <div className="w-100 select">
                            <input type="text" value={max} onChange={(e)=>{setMax(e.target.value)}}/>
                        </div>
                    </div>
                </div>
                <div className="w-100 border-bottom mt-4"></div>
                <div className="d-flex w-100 mt-3">
                    <div className="w-50 d-flex align-items-center">
                        <p className='text-body mb-0'>Текущая ставка аукциона</p>
                    </div>
                    <div className="d-flex w-50 ">
                        <div className="w-100 d-flex align-items-center">
                            <p className='w-50 mb-0'>{bet} руб</p>
                        </div>
                        <div className="w-50 d-flex justify-content-end">
                            <div className='standardBlueButton mt-0' onClick={handleSubmit}>Сохранить </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuctionSection;