import React, {useContext, useEffect, useState} from 'react';
import logo from '../../assets/img/img/logo.jpg';
import all from '../../assets/img/svg/all.svg';
import menuicon from '../../assets/img/svg/menuicon.svg';
import svg3 from '../../assets/img/svg/svgexport-3.svg';
import svg4 from '../../assets/img/svg/svgexport-4.svg';
import svg5 from '../../assets/img/svg/svgexport-5.svg';
import svg6 from '../../assets/img/svg/svgexport-6.svg';
import svg7 from '../../assets/img/svg/svgexport-7.svg';
import avatar from '../../assets/img/img/avatar.jpeg';
import partnersicon from '../../assets/img/img/partnersicon.png';
import faqicon from '../../assets/img/img/faqicon.png';
import multy from '../../assets/img/img/multy.svg';
import stockicon from '../../assets/img/img/stock.png';
import settingsicon from '../../assets/img/img/settingsicon.png';
import statistic from '../../assets/img/img/statistic.png';
import {Link} from "react-router-dom";
import axios from "axios";
import {MenuOpen, RightsContext, SetMenuOpen, UserAuthContext, UserContext} from "../../App";

function Menu() {
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const [id,setId] = useState('')
    const [complexes,setComplexes]=useState([]);
    const menuOpen = useContext(MenuOpen)
    const setMenuOpen = useContext(SetMenuOpen)
    const rights = useContext(RightsContext);
    useEffect(()=>{
        userAuth()
        if(user !== null){
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setId(res.data.complex.filter(e => e.active === 1)[0].id)
                    localStorage.setItem('ci',res.data.complex.filter(e => e.active === 1)[0].id)
                })
        }
    },[user,complexes])
    const logout = () => {
        localStorage.removeItem('token')
    }
    const handleChapterList = ()=>{
        switch (localStorage.getItem('chapter')) {
            case 'class':
                return (
                    <div>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/">
                                <div className="menu__icon">
                                    <img src={all} alt="all svg"/>
                                </div>
                                <span className="menu__item-name">
                                        Общие показатели
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/cian">
                                <div className="menu__icon">
                                    <img src={settingsicon} alt="all svg"/>
                                </div>
                                <span className="menu__item-name">
                                        Настройки каналов
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to={`/flats/${id}`}>
                                <div className="menu__icon">
                                    <img src={svg3} alt="svg 3"/>
                                </div>
                                <span className="menu__item-name">
                                        Квартиры ЖК
                                    </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/calls">
                                <div className="menu__icon">
                                    <img src={svg4} alt="svg 4"/>
                                </div>
                                <span className="menu__item-name">
                                    Отчет по звонкам
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to={`/visibility`}>
                                <div className="menu__icon">
                                    <img src={svg7} alt="svg 5"/>
                                </div>
                                <span className="menu__item-name">
                                        Видимость ЖК
                                    </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to={`/comp/${id}`}>
                                <div className="menu__icon">
                                    <img src={svg5} alt="svg 5"/>
                                </div>
                                <span className="menu__item-name">
                                        Отчет по конкурентам
                                    </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/offer/random">
                                <div className="menu__icon">
                                    <img src={svg6} alt="svg 6"/>
                                </div>
                                <span className="menu__item-name">
                                            Мультитекстовые объявления
                                        </span>
                            </Link>
                        </li>
                        {/*<li onClick={()=>setMenuOpen(false)} className="menu__item">*/}
                        {/*    <Link className="menu__link" to="/complex-statistic">*/}
                        {/*        <div className="menu__icon">*/}
                        {/*            <img src={statistic} alt="svg 6"/>*/}
                        {/*        </div>*/}
                        {/*        <span className="menu__item-name">*/}
                        {/*            Статистика по ЖК*/}
                        {/*        </span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {
                            rights.indexOf('lkcamp') !== -1 ? <li onClick={()=>setMenuOpen(false)} className="menu__item">
                                <Link className="menu__link" to="/stock">
                                    <div className="menu__icon">
                                        <img src={stockicon} alt="svg 6"/>
                                    </div>
                                    <span className="menu__item-name">
                                        Акции
                                  </span>
                                </Link>
                            </li> : ''
                        }

                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="#">
                                <div className="menu__icon">
                                    <img src={multy} alt="svg 6"/>
                                </div>
                                <span className="menu__item-name">
                                        Уведомления
                                  </span>
                            </Link>
                        </li>

                    </div>
                )
            break;
            case 'call':
                return (
                    <div>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/calls-exchange">
                                <div className="menu__icon">
                                    <img src={all} alt="all svg"/>
                                </div>
                                <span className="menu__item-name">
                                        Общие показатели
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/calls-classified">
                                <div className="menu__icon">
                                    <img src={svg4} alt="svg 4"/>
                                </div>
                                <span className="menu__item-name">
                                    Отчет по звонкам
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to={`/partners`}>
                                <div className="menu__icon">
                                    <img src={partnersicon} alt="svg 5"/>
                                </div>
                                <span className="menu__item-name">
                                        Партнеры
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to={`/faq`}>
                                <div className="menu__icon">
                                    <img src={faqicon} alt="svg 5"/>
                                </div>
                                <span className="menu__item-name">
                                        Вопрос - ответ
                                </span>
                            </Link>
                        </li>
                    </div>
                )
            break;
            case 'social':
                return (
                    <div>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/calls-exchange">
                                <div className="menu__icon">
                                    <img src={all} alt="all svg"/>
                                </div>
                                <span className="menu__item-name">
                                        Общие показатели
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to="/calls-classified">
                                <div className="menu__icon">
                                    <img src={svg4} alt="svg 4"/>
                                </div>
                                <span className="menu__item-name">
                                    Отчет по звонкам
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to={`/partners`}>
                                <div className="menu__icon">
                                    <img src={partnersicon} alt="svg 5"/>
                                </div>
                                <span className="menu__item-name">
                                        Партнеры
                                </span>
                            </Link>
                        </li>
                        <li onClick={()=>setMenuOpen(false)} className="menu__item">
                            <Link className="menu__link" to={`/faq`}>
                                <div className="menu__icon">
                                    <img src={faqicon} alt="svg 5"/>
                                </div>
                                <span className="menu__item-name">
                                        Вопрос - ответ
                                </span>
                            </Link>
                        </li>
                    </div>
                )
            break;
            default:
               return (
                   <div>
                       <li onClick={()=>setMenuOpen(false)} className="menu__item">
                           <Link className="menu__link" to="/">
                               <div className="menu__icon">
                                   <img src={all} alt="all svg"/>
                               </div>
                               <span className="menu__item-name">
                                        Общие показатели
                                </span>
                           </Link>
                       </li>
                       <li onClick={()=>setMenuOpen(false)} className="menu__item">
                           <Link className="menu__link" to={`/flats/${id}`}>
                               <div className="menu__icon">
                                   <img src={svg3} alt="svg 3"/>
                               </div>
                               <span className="menu__item-name">
                                        Квартиры ЖК
                                    </span>
                           </Link>
                       </li>
                       <li onClick={()=>setMenuOpen(false)} className="menu__item">
                           <Link className="menu__link" to="/calls">
                               <div className="menu__icon">
                                   <img src={svg4} alt="svg 4"/>
                               </div>
                               <span className="menu__item-name">
                                    Отчет по звонкам
                                </span>
                           </Link>
                       </li>
                       <li onClick={()=>setMenuOpen(false)} className="menu__item">
                           <Link className="menu__link" to={`/comp/${id}`}>
                               <div className="menu__icon">
                                   <img src={svg5} alt="svg 5"/>
                               </div>
                               <span className="menu__item-name">
                                        Отчет по конкурентам
                                    </span>
                           </Link>
                       </li>
                       <li onClick={()=>setMenuOpen(false)} className="menu__item">
                           <Link className="menu__link" to="/offer/random">
                               <div className="menu__icon">
                                   <img src={multy} alt="svg 6"/>
                               </div>
                               <span className="menu__item-name">
                                            Мультитекстовые объявления
                                        </span>
                           </Link>
                       </li>
                       <li onClick={()=>setMenuOpen(false)} className="menu__item">
                           <Link className="menu__link" to="#">
                               <div className="menu__icon">
                                   <img src={svg6} alt="svg 6"/>
                               </div>
                               <span className="menu__item-name">
                                        Уведомления
                                  </span>
                           </Link>
                       </li>
                   </div>
               )
        }
    }
    return (
            <menu className={`menu js-menu ${menuOpen ? 'active' : ''}`}>
                <div className="menu__inner">
                    <div className="menu__container">
                        <div className="menu__header">
                            <div className="menu__logo-wrap">
                                <Link to='/' className="menu__logo">
                                    <img src={logo} alt="na100pro"/>
                                </Link>
                                <span className="menu__logo-text">
                                Система продвижения объектов недвижимости в сети Интернет
                            </span>
                            </div>
                            <div className="menu__close">
                                <div className="menu__close-inner">
                                    <img src={menuicon} alt='menu icon'/>
                                </div>
                            </div>
                        </div>
                        <ul className="menu__list menu-space">
                            {handleChapterList()}
                        </ul>
                    </div>
                    <div className="menu__box">
                        <div className="menu__box-item menu__box-item--question">
                            <div className="menu__icon-box">
                                <div className="menu__icon menu__icon--question">
                                    <img src={svg7} alt="svg 7"/>
                                </div>
                            </div>
                            <div className="menu__box-info">
                            <span className="menu__box-title">
                                Появилась идея?
                            </span>
                                <span className="menu__box-desc">
                                Сообщите пожалуйста нам и мы обязательно рассмотрим ее реализацию.
                          </span>
                            </div>
                        </div>
                        <div className="menu__box-item menu__box-item--contact">
                            <div className="menu__avatar menu__avatar--contact">
                                <img src={avatar} alt="Аватар"/>
                            </div>
                            <div className="menu__box-info">
                            <span className="menu__box-title">
                                Руслан Русланов
                            </span>
                                <span className="menu__box-desc">
                                Ваш личный менеджер
                            </span>
                            <div className="menu__box-link">
                                <a href="Tel:+74991109881" className="menu__contact">
                                    +7 (499) 110-98-81
                                </a>
                                <a href="mailto:ruslan@na100.pro" className="menu__contact">
                                    ruslan@na100.pro
                                </a>
                            </div>
                            </div>
                        </div>
                        <div className="logoutContainer" onClick={logout}>
                            <a href='/login' onClick={logout}>Выйти из системы</a>
                        </div>
                    </div>

                </div>
                <div className="menuCover" onClick={()=>setMenuOpen(false)}> </div>
            </menu>
    );
}

export default Menu;