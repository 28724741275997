import React from 'react';

const OtherCpl = () => {
    return (
        <div className="common__section media-boxes mb-5" id='media-plan-cpl'>
            <div className="output">
                <div className="common__container">
                    <div className="output__wrap">
                        <div className="media-plan-details">
                            <div className="columnsContainer">
                                <div className="columns">
                                    <div className="columnRow">
                                        <div className="smallColumn">
                                            <div className='d-flex flex-column'>
                                                <div className="d-flex ">
                                                    <h6 className='text-nowrap mb-0 d-flex align-items-center m-lg-2'>
                                                        Другие  CPL
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="columnRow">
                                        <div className='shareSettings'>
                                            <div className="shareRow mt-3">
                                                <div className="title">M2</div>
                                                <input type="text" placeholder='Количество'/>
                                                <input type="text" placeholder='Цена'/>
                                            </div>
                                            <div className="shareRow mt-3">
                                                <div className="title">DomClick</div>
                                                <input type="text" placeholder='Количество'/>
                                                <input type="text" placeholder='Цена'/>
                                            </div>
                                            <div className="shareRow mt-3">
                                                <div className="title">Novostroy-M</div>
                                                <input type="text" placeholder='Количество'/>
                                                <input type="text" placeholder='Цена'/>
                                            </div>
                                            <div className="shareRow mt-3">
                                                <div className="title">Авахо</div>
                                                <input type="text" placeholder='Количество'/>
                                                <input type="text" placeholder='Цена'/>
                                            </div>
                                            <div className="shareRow mt-3">
                                                <div className="title">МСК-Гуру</div>
                                                <input type="text" placeholder='Количество'/>
                                                <input type="text" placeholder='Цена'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtherCpl;