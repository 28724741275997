import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {RightsContext, UserAuthContext, UserContext} from "../../App";
import Menu from "../../components/menu";
import FlatsHeader from "../flats/flatsHeader";
import MenuOpenButton from "../../components/menuOpenButton";
import {Link} from "react-router-dom";
import sprite from "../../assets/img/sprites/sprite.svg";
import SecondItem from "../second/second-item";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import CommercialItem from "./commercial-item";

const Commercials = () => {
    // const user = useContext(UserContext);
    // useEffect(()=>{
    //     if(user !== null){
    //         user.append('start','0')
    //         user.append('limit','999999999')
    //         axios.post(`${process.env.REACT_APP_BASE_API}?page=flats`,user)
    //             .then(res=>{
    //                 console.log(res.data.flats);
    //             })
    //     }
    //
    // },[user])
    // return (
    //     <div>
    //
    //     </div>
    // );
    const [flats,setFlats] = useState([]);
    const [flatsTOShow,setFlatsToShow] = useState([]);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [productsCount, setProductsCount] = useState(10);
    const user = useContext(UserContext);
    const rights = useContext(RightsContext);
    const handleChange = (event) => {
        setProductsCount(event.target.value);
    };
    const handlePage = (event, value) => {
        setPage(value);
    };
    const handleSorting = ()=>{

    }
    useEffect(()=>{
        if(user !== null){
            user.append('start','0')
            user.append('limit','999999999')
            axios.post(`${process.env.REACT_APP_BASE_API}?page=flats`,user)
                .then(res=>{

                    let data = res.data.flats.filter(e=>e.is_commercial === 1)
                    console.log(data);
                    setFlats(data)
                    setFlatsToShow(res.data.flats.filter(e=>e.is_commercial === 1).slice(0,10))
                    setPagesCount(Math.ceil(res.data.flats.filter(e=>e.is_commercial === 1).length / productsCount))
                })
        }

    },[user])
    useEffect(()=>{
        if(user !== null){
            let data = flats;
            setPagesCount(Math.ceil(data.length / productsCount))
            let pageinationData = data.slice((page - 1) * productsCount,page * productsCount)
            setFlatsToShow(pageinationData);
        }
    },[page,productsCount,flats])
    return (
        <div className='second'>
            <Menu/>
            <FlatsHeader/>
            <div className="common__section">
                <div className="filter">
                    <div className="common__container">
                        <div className="filter__box">
                            <div className="filter__container">
                                <div className="header__container">
                                    <div className="header__inner">
                                        <MenuOpenButton/>
                                        <div className="output__tabs-wrap js-tab-wrap">
                                            <ul className="output__tabs">
                                                <li className="output__tabs-item" style={{marginRight:'10px'}}>
                                                    <Link to={`/flats/${localStorage.getItem('ci')}`}  className={`output__tabs-button js-tab `}>
                                                        Обычные лоты
                                                    </Link>
                                                </li>
                                                {
                                                    rights.indexOf('second') !== -1 ? <li className="output__tabs-item me-3">
                                                        <Link to={'/second'}  className={`output__tabs-button js-tab`}>
                                                            Трейд-ин
                                                        </Link>
                                                    </li> : ''
                                                }
                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/typical-layouts'}  className={`output__tabs-button js-tab`}>
                                                        Типовые планировки
                                                    </Link>
                                                </li>

                                                <li className="output__tabs-item me-3 ">
                                                    <Link to={'/commercials'}  className={`output__tabs-button js-tab active`}>
                                                        Коммерческая недвижимость
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apartment">
                    <div className="common__container">
                        <div className="apartment__table">
                            <div className="apartment__table-header">
                                <div className="apartment__table-header-item apartment__table-header-item--big">
                                    <span>
                                        Объявление
                                    </span>
                                </div>
                                <div className="apartment__table-header-item" >
                                    <span>ЖК</span>
                                    <div className="common__sort">
                                        <button
                                            onClick={()=>{handleSorting('building','asc')}}
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--up js-sort-button">
                                        </button>
                                        <button
                                            onClick={()=>{handleSorting('building','desc')}}
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--down js-sort-button">
                                        </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Этаж</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('level','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('level','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Секция</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('num','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('num','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Комнат</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('rooms','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('rooms','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item apartment__table-header-item--middle">
                                    <span>Площадь, м2</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('area','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('area','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Цена, ₽</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('price','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('price','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                            </div>
                            <div className="apartment__table-body">
                                {
                                    flatsTOShow.map((el)=> {
                                        return (<CommercialItem key={el.flat_id} data={el}/>)
                                    })
                                }
                            </div>
                            <div className="pagination__wrap">
                                <div className="pagination">
                                    <div className="pagination__select">
                                        <FormControl sx={{ m: 1, minWidth: 165,maxHeight:'35px' }}>
                                            <Select
                                                value={productsCount}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={10}>Выводить по 10</MenuItem>
                                                <MenuItem value={20}>Выводить по 20</MenuItem>
                                                <MenuItem value={100}>Выводить по 100</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="pagination__list-wrap">
                                        <Stack spacing={2}>
                                            <Pagination count={pagesCount} shape="rounded"  page={page} onChange={handlePage}/>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Commercials;