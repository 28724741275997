import React from 'react';
import MenuOpenButton from "../../../components/menuOpenButton";

const StockHeader = (props) => {
    return (
        <header className="header">
            <div className="common__container standardHeader">
                <div className="header__container">
                    <div className="header__inner">
                        <MenuOpenButton/>
                        <div className="breadcrumbs">
                            <ul className="breadcrumbs__list">
                                <li className="breadcrumbs__item"><span>Акции</span></li>
                            </ul>
                        </div>
                    </div>
                    <button className='add-stock-button' onClick={()=>{
                        props.setDataForUpdate(null)
                        props.setPopup(true)
                        props.setPopupType('add')

                    }}>
                        + Добавить акцию
                    </button>
                </div>
            </div>
        </header>
    );
};

export default StockHeader;