import React from 'react';

const StandardPopup = (props) => {
    return (
        <div className='leadPopup'>
            <div className="container">
                <div className='sandedContainer'>
                    <h2>{props.title ? props.title : ''}</h2>
                    <p>
                        {props.text ? props.text : ''}
                    </p>
                </div>
                <div className="close" onClick={()=>{props.closePopup(false)}}>x</div>
            </div>
        </div>
    );
};

export default StandardPopup;