import React, {useState} from 'react';
import cianLogo from '../../../assets/img/img/cian-logo.png';
import yandexLogo from '../../../assets/img/img/yandex-logo.png';
import avitoLogo from '../../../assets/img/img/avito-logo.png';
const MediaPlanCounter = (props) => {
    const setType = props.setType;
    const type = props.type;
    return (
        <div className='media-plan-counter'>
            <ul className="output__tabs ">
                <li className="output__tabs-item all">
                        <a
                           className={`output__tabs-button  js-tab ${type === 'all' ? 'active': ''} `}
                           href="#media-plan-details">Всего</a>
                </li>
                <li className="output__tabs-item">
                        <a
                           className={`output__tabs-button js-tab ${type === 'cian' ? 'active': ''} `}
                           href="#cian-scroller">ЦИАН</a>
                </li>
                <li className="output__tabs-item">
                        <a
                           className={`output__tabs-button js-tab ${type === 'yandex' ? 'active': ''} `}
                           href="#yandex-scroller">Яндекс</a>
                </li>
                <li className="output__tabs-item">
                    <a
                       className={`output__tabs-button js-tab ${type === 'avito' ? 'active': ''} `}
                       href="#media-plan-avito">Avito</a>
                </li>
                <li className="output__tabs-item">
                    <a
                       className={`output__tabs-button js-tab ${type === 'other' ? 'active': ''} `}
                       href="#media-plan-base">Базы недвижимости</a>
                </li>
                <li className="output__tabs-item">
                    <a
                       className={`output__tabs-button js-tab ${type === 'cpl' ? 'active': ''} `}
                       href="#media-plan-cpl">Другие  CPL</a>
                </li>
            </ul>
            <div className="counter-item">
                <div className={`content ${type === 'all' ? 'd-flex' : ''}`}>
                    <p> <span>Бюджет</span> - 32121 руб с НДС</p>
                    <p> <span> План звонков</span> - ТТТ</p>
                    <p> <span> Прогнозируемый CPL</span> - </p>
                    <p> <span>По каналам</span></p>
                    <p> <span>Яндекс</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Циан</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Авито</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Базы</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Другие</span> - NNN руб NN звоноков CPL - NNN руб</p>
                </div>
                <div className={`content ${type === 'yandex' ? 'd-flex' : ''}`}>
                    <p> <span>Бюджет</span> - 87888 руб с НДС</p>
                    <p> <span> План звонков</span> - ТТТ</p>
                    <p> <span> Прогнозируемый CPL</span> - </p>
                    <p> <span>По каналам</span></p>
                    <p> <span>Яндекс</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Циан</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Авито</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Базы</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Другие</span> - NNN руб NN звоноков CPL - NNN руб</p>
                </div>
                <div className={`content ${type === 'cian' ? 'd-flex' : ''}`}>
                    <p> <span>Бюджет</span> - 87888 руб с НДС</p>
                    <p> <span> План звонков</span> - ТТТ</p>
                    <p> <span> Прогнозируемый CPL</span> - </p>
                    <p> <span>По каналам</span></p>
                    <p> <span>Яндекс</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Циан</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Авито</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Базы</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Другие</span> - NNN руб NN звоноков CPL - NNN руб</p>
                </div>
                <div className={`content ${type === 'avito' ? 'd-flex' : ''}`}>
                    <p> <span>Бюджет</span> - 87888 руб с НДС</p>
                    <p> <span> План звонков</span> - ТТТ</p>
                    <p> <span> Прогнозируемый CPL</span> - </p>
                    <p> <span>По каналам</span></p>
                    <p> <span>Яндекс</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Циан</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Авито</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Базы</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Другие</span> - NNN руб NN звоноков CPL - NNN руб</p>
                </div>
                <div className={`content ${type === 'other' ? 'd-flex' : ''}`}>
                    <p> <span>Бюджет</span> - 87888 руб с НДС</p>
                    <p> <span> План звонков</span> - ТТТ</p>
                    <p> <span> Прогнозируемый CPL</span> - </p>
                    <p> <span>По каналам</span></p>
                    <p> <span>Яндекс</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Циан</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Авито</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Базы</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Другие</span> - NNN руб NN звоноков CPL - NNN руб</p>
                </div>
                <div className={`content ${type === 'cpl' ? 'd-flex' : ''}`}>
                    <p> <span>Бюджет</span> - 87888 руб с НДС</p>
                    <p> <span> План звонков</span> - ТТТ</p>
                    <p> <span> Прогнозируемый CPL</span> - </p>
                    <p> <span>По каналам</span></p>
                    <p> <span>Яндекс</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Циан</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Авито</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Базы</span> - NNN руб NN звоноков CPL - NNN руб</p>
                    <p> <span>Другие</span> - NNN руб NN звоноков CPL - NNN руб</p>
                </div>
            </div>
        </div>
    );
};

export default MediaPlanCounter;