import React from 'react';
import MenuOpenButton from "../../../components/menuOpenButton";

const NotesHeader = () => {
    return (
        <header className="header">
            <div className="common__container">
                <div className="header__container">
                    <div className="header__inner">
                        <MenuOpenButton/>
                        <div className="breadcrumbs">
                            <ul className="breadcrumbs__list">
                                <li className="breadcrumbs__item"><span>Уведомления</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NotesHeader;