import React, {useContext, useEffect, useState} from 'react';
import OutputHeader from "./outputHeader";
import OutputDetails from "./outputDetails";
import OutputPlanChart from "./outputPlanChart";
import OutputSalesChart from "./outputSalesChart";
import Menu from "../../components/menu";
import {RightsContext, UserAuthContext, UserContext} from "../../App";
import axios from "axios";
import CallsChart from "../../components/callsChart";
import OutputPriceChart from "./outputPriceChart";
import VasChart from "./vasChatrt";
import AvgPrice from "./avgPrice";
import OutputPlanChartv2 from "./outputPlanChartv2";

const Output = ({match}) => {
    const userAuth = useContext(UserAuthContext)
    const user = useContext(UserContext)
    const rights = useContext(RightsContext)
    const [details,setDetails] = useState({})
    const [images,setImages] = useState('')
    const [graph,setGraph] = useState([])
    const [callsGraph,setCallsGraph] = useState([])
    const [cian,setCian] = useState([])
    const [yandex,setYandex] = useState([])
    const [avgPrice,setAvgPrice] = useState([])
    const [statics, setStatics] = useState([])
    const [cianGraph,setCianGraph] = useState([])
    const [avitoGraph,setAvitoGraph] = useState([])
    const [yandexGraph,setYandexGraph] = useState([])
    const [nastoGraph,setNastoGraph] = useState([])
    const [vas,setVas] = useState([])
    const [cianMix,setCianMix] = useState([])
    const [planAvito,setPlanAvito] = useState()
    const [planYandex,setPlanYandex] = useState()
    const [planCian,setPlanCian] = useState()
    const [planNasto,setPlanNasto] = useState()
    const [other,setOther] = useState()
    const [otherPlan,setOtherPlan] = useState()
    const [campaigns,setCampaigns] = useState([])
    const [update,setUpdate] = useState(false)
    const [avahoGraph,setAvahoGraph] = useState([])
    const [planAvaho,setPlanAvaho] = useState([])
    const [mdvaGraph,setMdvaGraph] = useState([])
    const [planMdva,setPlanMdva] = useState([])
    const [moveGraph,setMoveGraph] = useState([])
    const [planMove,setPlanMove] = useState([])
    useEffect(()=>{
        userAuth();
        if(user !== null){
            axios.post(`https://api.na100.pro/api.php?page=complex&complex_id=${match.params.id}`,user)
                .then(res => {
                    console.log("res data",res.data);
                    setCampaigns(res.data.campaigns)
                    setNastoGraph(res.data.calls_fact.nasto)
                    setCianGraph(res.data.calls_fact.cian)
                    setAvitoGraph(res.data.calls_fact.avito)
                    setYandexGraph(res.data.calls_fact.yandex)
                    setAvahoGraph(res.data.calls_fact.avaho)
                    setMdvaGraph(res.data.calls_fact.mdva)
                    setMoveGraph(res.data.calls_fact['move'])
                    setPlanAvito(res.data.calls_plan.avito)
                    setPlanYandex(res.data.calls_plan.yandex)
                    setPlanCian(res.data.calls_plan.cian)
                    setPlanNasto(res.data.calls_plan.nasto)
                    setPlanAvaho(res.data.calls_plan.avaho)
                    setPlanMove(res.data.calls_plan['move'])
                    setPlanMdva(res.data.calls_plan.mdva)
                    setOther(res.data.calls_fact.other)
                    setOtherPlan(res.data.calls_plan.other)
                    setDetails(res.data)
                    setGraph(res.data.remains)
                    setCian(res.data.cian)
                    setYandex(res.data.yandex)
                    setImages(res.data.images[0])
                    setCianMix(res.data.cian_mix)
                    setAvgPrice(res.data.avg_price)
                })
            axios.post(`https://api.na100.pro/api.php?page=calls&complex=${match.params.id}`,user)
                .then(res => {
                    setCallsGraph(res.data.graph)
                    setStatics(res.data.statistic)
                })
            axios.post(`https://api.na100.pro/api.php?page=vas&complex=${match.params.id}`,user)
                .then(res => {
                    setVas(res.data.vas)
                })
        }
    },[user,match.params.id,update])
    return (
        <div>
            <Menu/>
            <OutputHeader header={details.complex_name}/>
            <div className="common__section">
                <div className="output">
                    <div className="common__container">
                        <div className="output__wrap">
                            <OutputDetails update={update}
                                           setUpdate={setUpdate}
                                           details={details}
                                           images={images}
                                           planYandex={planYandex}
                                           planAvito={planAvito}
                                           planCian={planCian}
                                           planNasto={planNasto}
                                           planOther={otherPlan}
                                           campaigns={campaigns}/>
                            {
                             localStorage.getItem('login') !== 'forma_guru' ?
                                 <OutputPlanChart yandexGraph={yandexGraph}
                                                  cianGraph={cianGraph}
                                                  avitoGraph={avitoGraph}
                                                  nastoGraph={nastoGraph}
                                                  otherGraph={other}
                                                  planYandex={planYandex}
                                                  planAvito={planAvito}
                                                  planCian={planCian}
                                                  planNasto={planNasto}
                                                  planOther={otherPlan}
                                 /> : <OutputPlanChartv2 yandexGraph={yandexGraph}
                                                       avahoGraph={avahoGraph}
                                                       moveGraph={moveGraph}
                                                       mdvaGraph={mdvaGraph}
                                                       cianGraph={cianGraph}
                                                       avitoGraph={avitoGraph}
                                                       nastoGraph={nastoGraph}
                                                       otherGraph={other}
                                                       planAvaho={planAvaho}
                                                       planYandex={planYandex}
                                                       planAvito={planAvito}
                                                       planCian={planCian}
                                                       planNasto={planNasto}
                                                       planOther={otherPlan}
                                                       planMdva={planMdva}
                                                       planMove={planMove}
                                 />
                            }

                            <OutputPriceChart  cian={cian}
                                               yandex={yandex}
                                               cianMix={cianMix}
                                                />
                            <OutputSalesChart graph={graph}/>
                        </div>
                        <AvgPrice graph={avgPrice}/>
                        {/*{*/}
                        {/*    rights.indexOf('lkvas') !== -1 ? vas ?  <VasChart complexId={match.params.id} graph={vas}/> : '' : ''*/}
                        {/*}*/}
                        <CallsChart graph={callsGraph}
                                    complexId={match.params.id}
                                    statics={statics}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Output;