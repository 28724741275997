import React from 'react';
import MenuOpenButton from "../menuOpenButton";
import {Line} from "react-chartjs-2";
import {Link} from "react-router-dom";
import AdvInfoHeader from "../adv-info-header";

const AdvHeader = (props) => {

    return (
        <header className="header advHeader">
            <div className="common__container standardHeader">
                <div className="header__container">
                    <div className="header__inner">
                        <AdvInfoHeader setSelectedId={props.setSelectedId}/>
                        <MenuOpenButton/>
                        <div className="output__tabs-wrap js-tab-wrap">

                            <ul className="output__tabs">
                                <li className="output__tabs-item">
                                    <Link to={'/cian'}  className={`output__tabs-button js-tab ${props.type === 'cian' ? 'active': ''} `} data-action="cian">
                                        ЦИАН
                                    </Link>
                                </li>
                                <li className="output__tabs-item">
                                    <Link to={'/yandex'}  className={`output__tabs-button js-tab ${props.type === 'yandex' ? 'active': ''} `} data-action="avito">
                                        Яндекс Недвижимость
                                    </Link>
                                </li>
                                <li className="output__tabs-item">
                                    <Link to={'/avito'}  className={`output__tabs-button js-tab ${props.type === 'avito' ? 'active': ''} `} data-action="Na100PRO">
                                        Авито
                                    </Link>
                                </li>
                                <li className="output__tabs-item">
                                    <Link to={'/nasto'}  className={`output__tabs-button js-tab ${props.type === 'nasto' ? 'active': ''} `} data-action="yandex">
                                        Na100PRO
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default AdvHeader;