import React, {useContext, useEffect, useState} from 'react';
import xicon from '../../assets/img/img/xicon.png';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import {UserAuthContext, UserContext} from "../../App";

const AddStock = (props) => {
    const [value, setValue] = React.useState()
    const [complex, setComplex] = React.useState()
    const [type, setType] = React.useState('type1')
    const [title, setTitle] = React.useState('')
    const [text, setText] = React.useState('')
    const [complexList, setComplexList] = React.useState([])
    const user = useContext(UserContext)
    const userAuth = useContext(UserAuthContext)

    useEffect(()=>{
        userAuth()
        if(user !== null){
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplexList(res.data.complex.filter(e => e.active === 1))
                })
        }
    },[user])
    useEffect(()=>{
        if(props.data !== null && props.popupType === 'update'){
            setTitle(props.data.campaign_name)
            setText(props.data.campaign_text)
            let date = props.data.campaign_end.split('.')
            setValue(new Date(`${date[1]}-${date[0]}-${date[2]}`))
        }else{
            setTitle('')
            setText('')
            setValue(new Date())
        }
    },[props.data,props.popupType])
    const handleDate = (date)=>{
        let day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate().toString();
        let month = date.getMonth().toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1).toString();
        let year = date.getFullYear();
       return `${day}.${month}.${year}`
    }
    const handleSubmit = ()=>{
        const fd = new FormData()
        fd.append('auth',localStorage.getItem('token'))
        fd.append('campaign_name',title)
        fd.append('campaign_text',text)
        fd.append('campaign_end',handleDate(value))
        if(complex){
            fd.append('complex_id',complex.toString())
        }
        if(props.data){
            fd.append('campaign_id',props.data.campaign_id)
        }
        axios.post('https://api.na100.pro/api.php?page=campupdate',fd)
            .then(res=>{
                if(res.data.result === 'success'){
                    props.setPopupType('')
                    props.setPopup(false)
                }
            })
    }
    const renderComplexes = ()=>{
        return complexList.map((el)=>{
            return <MenuItem value={el.id}>{el.name}</MenuItem>
        })
    }
    const handleComplex = (event) => {
        setComplex(event.target.value)
    }
    return (
        <div className={`addStock ${props.popUp ? 'active' : ''}`}>
            <div className="popUpContainer">
                <div className="flexRow">
                    <h3 className="title">Добавление акции</h3>
                    <button className="buttonClose" onClick={()=>{props.setPopup(false)}}>
                        <img src={xicon} alt="x icon"/>
                    </button>
                </div>
                <div className="flex-column">
                    <div className="title">Название акции</div>
                    <input type="text" value={title} placeholder='Введите название акции' onChange={e=>setTitle(e.target.value)}/>
                </div>
                <div className="flex-column">
                    <div className="title">Описание акции</div>
                    <textarea cols="30" rows="10" onChange={e=>setText(e.target.value)} value={text}> </textarea>
                </div>
                <div className="flexRow">
                    <div className="twiceGrid">
                        <div className="flex-column">
                            <div className="title">Действие акции</div>
                            <div className="date-picker">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        {props.popupType === 'add' ? <div className="flex-column">
                            <div className="title">ЖК</div>
                            <div className="select-mui">
                                <FormControl sx={{ m: 1, minWidth: 414 }}>
                                    <Select
                                        value={complex}
                                        onChange={handleComplex}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {renderComplexes()}
                                    </Select>
                                </FormControl>
                                <p>
                                    Если не выбран ЖК, вы сможете назначить данную акцию на конкретные объекты вручную.
                                </p>
                            </div>
                        </div> : ''}
                    </div>
                </div>
                <div className="flexRow">
                    <div className="twiceGrid">

                    </div>
                </div>
                <div className="fleRow">
                    <div className="buttonsContainer">
                        <button className='buttonLight' onClick={()=>{props.setPopup(false)}}>Отмена</button>
                        <button className='buttonActive' onClick={handleSubmit}>Добавить акцию</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddStock;