import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../../App";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PageLoader from "../../../components/page-loader";
import {Link} from "react-router-dom";

const FlatsStockPopup = (props) => {
    const user = useContext(UserContext);
    const [campaigns, setCampaigns] = React.useState({});
    const [allCampaigns,setAllCampaigns] = useState({})
    const [allCampaignsArray,setAllCampaignsArray] = useState([])
    const [load,setLoad] = useState(true);
    useEffect(()=>{
        if(user !== null){
            axios.post('https://api.na100.pro/api.php?page=campaigns',user)
                .then(res=>{
                    setAllCampaignsArray(res.data.campaigns)
                    let compData = {}
                    for(let item of res.data.campaigns){
                        compData[`${item.campaign_id}`] = false
                    }
                    setAllCampaigns(compData)
                    setLoad(false)
                })
        }
    },[user])

    const handleChange = (event) => {
        setCampaigns({
            ...campaigns,
            [event.target.name]: event.target.checked,
        });
        setAllCampaigns({
            ...allCampaigns,
            [event.target.name]: event.target.checked,
        });
    };
    const handleObjToArray = (obj) => {
        const identifiers = Object.keys(obj)
        let arr = identifiers.filter(function(id) {
            return Number(obj[id])
        })
        return arr;
    }
    const updateStocks = ()=>{
        const fd = new FormData;

        fd.append('auth',localStorage.getItem('token'));
        props.ids.forEach(el => {
            fd.append('id[]',el)
        });
        if(props.type === 'add'){
            fd.append('do','add');
        }else{
            fd.append('do','remove');
        }
        let campData = handleObjToArray(allCampaigns);
        campData.forEach(el => {
            fd.append('campaign[]',el)
        });
        axios.post(`https://api.na100.pro/api.php?page=campproc`,fd)
            .then(res => {
                if(res.data.result === 'success'){
                    props.setOpenStocks(false)
                    props.setUpdate(!props.update)

                }
            })
    }
    const renderStocks = () => {
        let data = [];
        for(let [key,value] of Object.entries(allCampaigns)){
            let ogj = allCampaignsArray.find(e => e.campaign_id == key)
            const row = (
                <li key={key}>
                    <FormControlLabel
                        control={
                            <Checkbox size='small' checked={value} onChange={handleChange} name={key} />
                        }
                        label={ogj.campaign_name}
                    />
                    <p>{ogj.campaign_text}</p>
                </li>)
            data.push(row)
        }
        return data
    }
    const nonStocks = () => {
        return (
            <div className='nonStocks'>
                <h2>Акции отсутствуют</h2>
                <Link to={'/stock'}>Добавить акции</Link>
            </div>
        )
    }
    return (
        <section className="modal modal--big js-modal active stock-popup" data-open="link-list">
                <div className="modal__wrap">
                    <div className="modal__inner">
                        <button onClick={()=>props.setOpenStocks(false)}
                                className="button__close js-close-modal" >x</button>
                        <div className="modal__title">
                            Действующие акции
                        </div>
                        <div className="link__list-wrap">
                            <div className="link__list-col">
                                <ul className="link__list stocksLists">
                                    {load ? <PageLoader/> : Object.entries(allCampaigns).length !== 0 ?
                                        renderStocks() : nonStocks()}
                                </ul>
                            </div>
                            <div className="buttonsContainer">
                                <button className='gray' onClick={()=>props.setOpenStocks(false)}>Отменить </button>
                                <button className='blue' onClick={updateStocks}>Применить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
};

export default FlatsStockPopup;