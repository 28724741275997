import React, {useState} from 'react';

const QuartalItem = (props) => {
    const colors = ['light-green','green','pink','orange','red'];
    const priceHandler = (num)=>{
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }
    const renderData = ()=>{
        return Object.entries(props.data).map(([key, value],index) =>{
            return (
                <div className="competitor__table-row">
                    <div className="competitor__table-item competitor__table-item--main">
                        <span>{index === 0 ? props.complex : ''}</span>
                    </div>
                    <div className="competitor__table-item competitor__table-item--main">
                        <span>{key}</span>
                    </div>
                    <div
                        className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                        <span>{props.price ? priceHandler(Number(value.count_studio)) : value.count_studio}</span>
                    </div>
                    <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                        <span>{props.price ? priceHandler(Number(value.count_1)) : value.count_1}</span>
                    </div>
                    <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                        <span>{props.price ? priceHandler(Number(value.count_2)) : value.count_2}</span>
                    </div>
                    <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                        <span>{props.price ? priceHandler(Number(value.count_3)) : value.count_3}</span>
                    </div>
                    <div className={`competitor__table-item competitor__table-item--status ${colors[Math.floor(Math.random() * colors.length)]}`}>
                        <span>{props.price ? priceHandler(Number(value.count_4)) : value.count_4}</span>
                    </div>
                </div>
            )
        })
    }
    const handleSum = (data,prop) => {
        let result = 0;
        data.map((el)=>{
            result += Number(el[prop])
        })
        return Math.round(result)
    }
    return (
        <>
            {renderData()}
            {props.sum ? <div className="competitor__header">
                <div className="competitor__header-item competitor__header-item--top">
                    <span>Итог</span>
                </div>
                <div className="competitor__header-item competitor__header-item--top">
                    <span></span>
                </div>
                <div className="competitor__header-item competitor__header-item--bottom">
                    <span>{handleSum(Object.values(props.data),'count_studio')}</span>
                </div>
                <div className="competitor__header-item competitor__header-item--bottom">
                    <span>{handleSum(Object.values(props.data),'count_1')}</span>
                </div>
                <div className="competitor__header-item competitor__header-item--bottom">
                    <span>{handleSum(Object.values(props.data),'count_2')}</span>
                </div>
                <div className="competitor__header-item competitor__header-item--bottom">
                    <span>{handleSum(Object.values(props.data),'count_3')}</span>
                </div>
                <div className="competitor__header-item competitor__header-item--bottom">
                    <span>{handleSum(Object.values(props.data),'count_4')}</span>
                </div>
            </div> : ''}

        </>

    );
};

export default QuartalItem;