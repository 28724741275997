import React, {createContext, useEffect, useState} from 'react';
import HomePage from "./pages/homePage";
import {BrowserRouter as Router, Switch, Route, useLocation} from "react-router-dom";
import Flats from "./pages/flats";
import Note from "./pages/note";
import Output from "./pages/output";
import Offer from "./pages/offer";
import Calls from "./pages/calls";
import Login from "./pages/login";
import AOS from "aos";
import axios from "axios";
import MainHeader from "./components/main-header";
import CallsExchange from "./pages/calls-exchange";
import AddObject from "./pages/add-object";
import Comp from "./pages/comp";
import CallsClassified from "./pages/calls-classified/calls-classified";
import Partners from "./pages/partners";
import Faq from "./pages/faq";
import Social from "./pages/social";
import AddVas from "./pages/add-vas";
import Yandex from "./pages/yandex";
import Avito from "./pages/avito";
import Nasto from "./pages/nasto";
import Cian from "./pages/cian";
import AddFlat from "./pages/add-flat";
import Stock from "./pages/stock";
import SmartLots from "./pages/smart-lots";
import NotFoundPage from "./pages/notFoundPage";
import MediaPlan from "./pages/media-plan";
import Statistic from "./pages/statistic";
import Visibility from "./pages/visibility";
import Second from "./pages/second";
import Commercials from "./pages/commercials";
export const UserContext = createContext(null);
export const UserAuthContext = createContext(null);
export const MenuOpen = createContext(null);
export const SetMenuOpen = createContext(null);
export const RightsContext = createContext([]);
export const RenContext = createContext(true);
const App = () => {
    AOS.init();
  const [user,setUser] = useState(null)
  const [menuOpen,setMenuOpen] = useState(false)
  const [rights,setRights] = useState([])
    const [ren,setRen] = useState(true)
  useEffect(()=>{
      const token = localStorage.getItem('token');
      const fd = new FormData();
      fd.append('auth',token)
      if(token){
          setUser(fd)
      }
  },[])
  const handleAuth = ()=>{
      const token = localStorage.getItem('token');
      const fd = new FormData();
      fd.append('auth',token)
      axios.post('https://api.na100.pro/api.php?page=check',fd)
          .then(res => {
              if(res.data.message === "Not authorized"){
                  window.location.href = '/login';
              }else {
                  setRights(res.data.rights)
              }
          })
  }

  return (
      <div id='app'>
          <UserContext.Provider value={user}>
          <UserAuthContext.Provider value={handleAuth}>
          <MenuOpen.Provider value={menuOpen}>
          <SetMenuOpen.Provider value={setMenuOpen}>
          <RightsContext.Provider value={rights}>
              <RenContext.Provider value={ren}>

                      <MainHeader/>
                      <Switch>
                          <Route exact path='/' component={HomePage}/>
                          <Route exact path='/flats/:id?' component={Flats}/>
                          <Route exact path='/notes' component={Note}/>
                          <Route exact path='/output/:id' component={Output}/>
                          <Route exact path='/calls/:id?' component={Calls}/>
                          <Route exact path='/calls-classified/:id?' component={CallsClassified}/>
                          <Route exact path='/offer/:id' component={Offer}/>
                          <Route exact path='/login' component={Login}/>
                          <Route exact path='/calls-exchange' component={CallsExchange}/>
                          <Route exact path='/add-object' component={AddObject}/>
                          <Route exact path='/comp/:id' component={Comp}/>
                          <Route exact path='/partners' component={Partners}/>
                          <Route exact path='/faq' component={Faq}/>
                          <Route exact path='/social' component={Social}/>
                          <Route exact path='/add-vas' component={AddVas}/>
                          <Route exact path='/yandex' component={Yandex}/>
                          <Route exact path='/avito' component={Avito}/>
                          <Route exact path='/nasto' component={Nasto}/>
                          <Route exact path='/cian' component={Cian}/>
                          <Route exact path='/add-flat' component={AddFlat}/>
                          <Route exact path='/stock' component={Stock}/>
                          <Route exact path='/typical-layouts' component={SmartLots}/>
                          <Route exact path='/media-plan' component={MediaPlan}/>
                          <Route exact path='/complex-statistic' component={Statistic}/>
                          <Route exact path='/visibility' component={Visibility}/>
                          <Route exact path='/second' component={Second}/>
                          <Route exact path='/commercials' component={Commercials}/>
                          <Route exact path='*' component={NotFoundPage}/>
                      </Switch>
              </RenContext.Provider>
          </RightsContext.Provider>
          </SetMenuOpen.Provider>
          </MenuOpen.Provider>
          </UserAuthContext.Provider>
          </UserContext.Provider>
      </div>
  );
}

export default App;
