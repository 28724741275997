import React from "react";
import './page-loader.scss';
const PageLoader = (props) => {
    return (
        <div className={`page-loader ${props.type === 'small' ? 'smallLoader' : ''}`}>
            <div className="loader">
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__ball"></div>
            </div>
        </div>
    );
}
export default PageLoader;