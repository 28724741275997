import React, {useEffect, useState} from 'react';
import {Chart} from "react-charts";
import {Link} from "react-router-dom";

const CallsChart = (props) => {
    const [load,setLoad] = useState(500)
    const [graphData,setGraphData] = useState([]);
    const [allChecks,setAllchecks] = useState(false);
    const [stat_0,setStat_0] = useState(false)
    const [stat_1,setStat_1] = useState(true)
    const [stat_2,setStat_2] = useState(false)
    const [stat_3,setStat_3] = useState(true)
    const [stat_4,setStat_4] = useState(false)
    const [stat_5,setStat_5] = useState(false)
    const handleGraphData = ()=>{
        let data = [];
        if(stat_0){
            data.push( {
                label:'s1',
                data: props.graph.map((el)=>{
                    return [el.label,el.stat_0]
                }),
                color:'#eeeb20',
            } );
        }
        if(stat_1){
            data.push(  {
                label:'s2',
                data: props.graph.map((el)=>{
                    return [el.label,el.stat_1]
                }),
                color:'#7CB305',
            });
        }
        if(stat_2){
            data.push(  {
                label:'s2',
                data: props.graph.map((el)=>{
                    return [el.label,el.stat_2]
                }),
                color:'#8f55dd',
            });
        }
        if(stat_3){
            data.push(   {
                label:'s3',
                data: props.graph.map((el)=>{
                    return [el.label,el.stat_3]
                }),
                color:'#f18d25',
            });
        }
        if(stat_4){
            data.push(  {
                label:'s4',
                data: props.graph.map((el)=>{
                    return [el.label,el.stat_4]
                }),
                color:'#26c2ab',
            });
        }
        if(stat_5){
            data.push(   {
                label:'s5',
                data: props.graph.map((el)=>{
                    return [el.label,el.stat_5]
                }),
                color:'#CF1322',
            });
        }
        if(allChecks){
            data.push({
                label:'s6',
                data: props.graph.map((el)=>{
                    return [el.label,Number(el.stat_1) + Number(el.stat_0) + Number(el.stat_2) + Number(el.stat_3) + Number(el.stat_4)]
                }),
                color:'blue',
            });
        }
        return data;
    }
    useEffect(()=>{
        setGraphData(handleGraphData())
    },[stat_0,stat_1,stat_2,stat_3,stat_4,stat_5,allChecks,props.graph])
    useEffect(()=>{
        setLoad(load+1)
    },[props.graph])
    useEffect(()=>{
        let data = '';
        if(stat_0){
            data += '&status[]=0'
        }
        if(stat_1){
            data += '&status[]=1'
        }
        if(stat_2){
            data += '&status[]=2'
        }
        if(stat_3){
            data += '&status[]=3'
        }
        if(stat_4){
            data += '&status[]=4'
        }
        if(stat_5){
            data += '&status[]=5'
        }
        if(props.handleCallsFilter){
            props.handleCallsFilter(data)

        }
    },[stat_0,stat_1,stat_2,stat_3,stat_4,stat_5])
    const series = {
            type: 'bar'
        }
    const axes = [
            { primary: true, type: 'ordinal', position: 'bottom' },
            { position: 'left', type: 'linear', stacked: false,}
        ]
    const priceHandler = (num)=>{
        if(num){
            let numb =  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/, '$1 ')
            return numb
        }
    }
    const monthsList = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    return (
        <div className="period__wrap">
            <div className={`period__title`} style={{justifyContent:props.complexId ? 'space-between' : 'space-between'}}>
                {
                    props.chosenDates ?
                    <h2 className="common__h2">
                        Динамика звонков {props.chosenDates ? 'за' : ''} <span className="js-priod-text">{props.chosenDates.date_begin} - {props.chosenDates.date_end}</span>
                    </h2> :
                    <h2 className="common__h2">
                        Динамика звонков за <span className="js-priod-text">{monthsList[new Date().getMonth()]} {new Date().getFullYear()} г.</span>
                    </h2>
                }
                {
                    props.complexId ? <Link to={`/calls/${props.complexId}`} className="button__report">Отчет по звонкам</Link> : ''
                }
            </div>

            <div className="checkbox__wrap">
                <form className="checkbox__form">
                    {/*<div className="checkbox__wrap-item checkbox__wrap-item--blue">*/}
                    {/*    <label className="checkbox__custom-container">*/}
                    {/*        <input type="checkbox" checked={allChecks} onChange={e => setAllchecks(e.target.checked)}*/}
                    {/*               className="checkbox__custom--hidden"/>*/}
                    {/*        <span className="checkbox__custom">*/}
                    {/*        </span>*/}
                    {/*        <span className="checkbox__custom-text">Все звонки</span>*/}
                    {/*    </label>*/}
                    {/*    <div className="report__col">*/}
                    {/*        <span className="report__col-desc">{props.statics.stat_0 + props.statics.stat_1 + props.statics.stat_3 + props.statics.stat_2+ props.statics.stat_4}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="checkbox__wrap-item checkbox__wrap-item--yellow">*/}
                    {/*    <label className="checkbox__custom-container">*/}
                    {/*        <input type="checkbox"*/}
                    {/*               disabled={props.statics.stat_0 === 0}*/}
                    {/*               checked={stat_0}*/}
                    {/*               onChange={e => setStat_0(e.target.checked)}*/}
                    {/*               className="checkbox__custom--hidden"/>*/}
                    {/*        <span className="checkbox__custom"> </span>*/}
                    {/*        <span className="checkbox__custom-text">В обработке</span>*/}
                    {/*    </label>*/}
                    {/*    <div className="report__col">*/}
                    {/*        <span className="report__col-desc">{props.statics.stat_0}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="checkbox__wrap-item checkbox__wrap-item--green">
                        <label className="checkbox__custom-container">
                            <input type="checkbox" disabled={props.statics.stat_1 === 0}
                                   checked={stat_1} onChange={e => setStat_1(e.target.checked)}
                                   className="checkbox__custom--hidden"/>
                            <span className="checkbox__custom"> </span>
                            <span className="checkbox__custom-text">Целевые</span>
                        </label>
                        <div className="report__col">
                            <span className="report__col-desc">{props.statics.stat_1}</span>
                        </div>
                    </div>
                    <div className="checkbox__wrap-item checkbox__wrap-item--purple">
                        <label className="checkbox__custom-container">
                            <input type="checkbox" disabled={props.statics.stat_2 === 0}
                                   checked={stat_2} onChange={e => setStat_2(e.target.checked)}
                                   className="checkbox__custom--hidden"/>
                            <span className="checkbox__custom"> </span>
                                <span className="checkbox__custom-text">Не целевые</span>
                        </label>
                        <div className="report__col">
                            <span className="report__col-desc">{props.statics.stat_2}</span>
                        </div>
                    </div>
                    <div className="checkbox__wrap-item checkbox__wrap-item--orange">
                        <label className="checkbox__custom-container">
                            <input type="checkbox" disabled={props.statics.stat_3 === 0}
                                   checked={stat_3} onChange={e => setStat_3(e.target.checked)}
                                   className="checkbox__custom--hidden"/>
                            <span className="checkbox__custom"> </span>
                                <span className="checkbox__custom-text">Пропущенные</span>
                        </label>
                        <div className="report__col">
                            <span className="report__col-desc">{props.statics.stat_3}</span>
                        </div>
                    </div>
                    <div className="checkbox__wrap-item checkbox__wrap-item--aqua">
                        <label className="checkbox__custom-container">
                            <input type="checkbox" disabled={props.statics.stat_4 === 0}
                                   checked={stat_4} onChange={e => setStat_4(e.target.checked)}
                                   className="checkbox__custom--hidden"/>
                            <span className="checkbox__custom"> </span>
                                <span className="checkbox__custom-text">На рассмотрении</span>
                        </label>
                        <div className="report__col">
                            <span className="report__col-desc">{props.statics.stat_4}</span>
                        </div>
                    </div>
                    {/*<div className="checkbox__wrap-item checkbox__wrap-item--red">*/}
                    {/*    <div className="report__col">*/}
                    {/*        <div className="report__item">*/}
                    {/*            Оспорили {props.statics.stat_5} звонков на сумму {priceHandler(props.statics.stat_saved)} руб*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </form>
            </div>
            <div className="chart__section" style={ {width:"100%",height:load + 'px'}}>
                <Chart data={graphData} series={series} axes={axes} />
            </div>
        </div>
    );
};

export default CallsChart;