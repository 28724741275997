import React, {useContext, useEffect, useState} from 'react';
import Menu from "../../components/menu";
import axios from "axios";
import {UserContext} from "../../App";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Visibility = () => {
    const [cianRooms,setCianRooms] = useState([])
    const [yandexRooms,setYandexRooms] = useState([])
    const [cianGeo,setCianGeo] = useState([])
    const [yandexGeo,setYandexGeo] = useState([])
    const [complexList,setComplexList] = useState([])
    const [complex,setComplex] = useState(localStorage.getItem('ci'))
    const [cianDate,setCianDate] = useState('');
    const [yandexDate,setYandexDate] = useState('');

    const user = useContext(UserContext)
    useEffect(()=>{
        if(user){
            axios.post(`https://api.na100.pro/api.php?page=yandexcian&complex_id=${complex}`,user)
                .then(res => {
                    setCianRooms(res.data.cian.rooms)
                    setCianGeo(res.data.cian.geo)
                    setYandexGeo(res.data.yandex.geo)
                    setYandexRooms(res.data.yandex.rooms)
                    setCianDate(res.data.cian.date)
                    setYandexDate(res.data.yandex.date)
                })
        }
    },[user,complex])
    useEffect(()=>{
        if(user){
            if(user){
                axios.post(`https://api.na100.pro/api.php?page=ffilter`,user)
                    .then(res => {
                        setComplexList(res.data.complex)
                    })
            }
        }
    },[user])
    const handleTotal = (data) => {

        let total = 0
        let our = 0
        let text = ''
        for(const property in data){

            text += `${property}(${data[property].total}/${data[property].our}), `
            total += data[property].total
            our += data[property].our
        }
        return `${total}*${our}*${text}`;

    }
    const handleCianRooms = () => {
        return  cianRooms.map((e,index) => {
            if(index != 6 && index != 7 && index != 8 && index != 9 && index != 10){
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name}</b></td>
                        <td>{handleTotal(e.result).split('*')[0]}</td>
                        <td>{handleTotal(e.result).split('*')[1]}</td>
                        <td>
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                {handleTotal(e.result).split('*')[2]}
                            </a>
                        </td>
                    </tr>
                )
            }else{
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name} {e.price_min} {e.price_max} </b></td>
                        <td>{handleTotal(e.result).split('*')[0]}</td>
                        <td> {handleTotal(e.result).split('*')[1]}</td>
                        <td>
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                {handleTotal(e.result).split('*')[2]}
                            </a>
                        </td>
                    </tr>
                )
            }
        })
    }
    const handleCianGeo = () => {
        return  cianGeo.map((e,index) => {
            if(index != 7 && index != 8 && index != 9 && index != 10 && index != 11){
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name}  </b></td>
                        <td >
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                <div dangerouslySetInnerHTML={{__html:e.result}}></div>
                            </a>
                        </td>
                    </tr>
                )
            }else{
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name}  {e.price_min} {e.price_max}</b></td>
                        <td >
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                <div dangerouslySetInnerHTML={{__html:e.result}}></div>
                            </a>
                        </td>
                    </tr>
                )
            }
        })
    }
    const handleYandexGeo = () => {
        return  yandexGeo.map((e,index) => {
            if(index != 7 && index != 8 && index != 9 && index != 10 && index != 11){
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name}</b></td>
                        <td >
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                <div dangerouslySetInnerHTML={{__html:e.result}}></div>
                            </a>
                        </td>
                    </tr>
                )
            }else{
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name} - {e.price_min} {e.price_max}</b></td>
                        <td >
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                <div dangerouslySetInnerHTML={{__html:e.result}}></div>
                            </a>
                        </td>
                    </tr>
                )
            }
        })
    }
    const handleCianYandex = () => {
        return  yandexRooms.map((e,index) => {
            if(index != 5 && index != 6 && index != 7 && index != 8 && index != 9){
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name}</b></td>
                        <td>{handleTotal(e.result).split('*')[0]}</td>
                        <td>{handleTotal(e.result).split('*')[1]}</td>
                        <td>
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                {handleTotal(e.result).split('*')[2]}
                            </a>
                        </td>
                    </tr>
                )
            }else{
                return (
                    <tr>
                        <td className='text-primary'><b>{e.name} - {e.price_min} {e.price_max} </b></td>
                        <td>{handleTotal(e.result).split('*')[0]}</td>
                        <td> {handleTotal(e.result).split('*')[1]}</td>
                        <td>
                            <a href={e.url} target="_blank" rel="noopener noreferrer">
                                {handleTotal(e.result).split('*')[2]}
                            </a>
                        </td>
                    </tr>
                )
            }
        })
    }
    const renderComplex = () => {
        return complexList.map((el)=>{
            if(el.active === 1){
                return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
            }
        })
    }
    const handleComplex = (event) => {
        setComplex(event.target.value);
    };
    return (
        <div>
            <Menu/>
            <div className="common__section py-0">
                <div className="output">
                    <div className="common__container">
                        <div className="p-3 d-flex flex-column">
                            <div className="d-flex bg-white p-3">
                                <h3>Видимость</h3>
                                <div className="filter__item js-filter-item">
                                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                                        <Select
                                            value={complex}
                                            onChange={handleComplex}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {renderComplex()}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                Array.isArray(cianRooms) && cianRooms.length !== 0 ? <div className='bg-white p-3 my-2'>
                                    <h6 className='mt-3'>Присутствие в поисковой выдаче ЦИАН
                                        <small className='mx-2 text-secondary'>дата актуальности - {cianDate}</small>
                                    </h6>
                                    <div className='d-flex flex-column  my-4'>
                                        <table className='visibility-table'>
                                            <tr>
                                                <th>Название</th>
                                                <th>всего</th>
                                                <th>наших</th>
                                                <th></th>
                                            </tr>
                                            {handleCianRooms()}
                                        </table>

                                    </div>
                                </div> : ''
                            }
                            {
                                Array.isArray(cianGeo) && cianGeo.length !== 0 ? <div className='bg-white p-3 my-2'>
                                    <h6 className='mt-3'>Каталог новостроек ЦИАН
                                        <small className='mx-2 text-secondary'>дата актуальности - {cianDate}</small>
                                    </h6>
                                    <div className='d-flex flex-column  p-2 my-2'>
                                        <table className='visibility-table2'>
                                            <tr>
                                                <th>Название</th>
                                                <th>Позиция </th>
                                            </tr>
                                            {handleCianGeo()}
                                        </table>

                                    </div>
                                </div> : ''
                            }
                            {
                                Array.isArray(yandexRooms) && yandexRooms.length !== 0 ? <div className='bg-white p-3 my-2'>
                                    <h6 className='mt-3'>
                                        Присутствие в поисковой выдаче Yandex
                                        <small className='mx-2 text-secondary'>дата актуальности - {yandexDate}</small>
                                    </h6>
                                    <div className='d-flex flex-column  my-4'>
                                        <table className='visibility-table'>
                                            <tr>
                                                <th>Название</th>
                                                <th>всего</th>
                                                <th>наших</th>
                                                <th></th>
                                            </tr>
                                            {handleCianYandex()}
                                        </table>

                                    </div>
                                </div> : ''
                            }
                            {
                              Array.isArray(yandexGeo) && yandexGeo.length !== 0 ? <div className='bg-white p-3 my-2'>
                                    <h6 className='mt-3'>
                                        Каталог новостроек Yandex
                                        <small className='mx-2 text-secondary'>дата актуальности - {yandexDate}</small>
                                    </h6>
                                    <div className='d-flex flex-column  p-2 my-2'>
                                        <table className='visibility-table2'>
                                            <tr>
                                                <th>Название</th>
                                                <th>Позиция </th>
                                            </tr>
                                            {handleYandexGeo()}
                                        </table>

                                    </div>
                                </div> : ''
                            }

                        </div>
                    </div>
                    {
                        cianRooms && cianGeo && yandexRooms && yandexGeo ? '' : <div className='bg-white p-3 my-2'>
                            <h1 className='noData'>Идет формирование отчета</h1>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Visibility;