import React from 'react';
import {Link} from "react-router-dom";

const NonComplex = () => {
    return (
        <div className="common__section calls-exchange-page">
            <section className="product">
                <div className="common__container">
                    <div className="js-product-box">
                        <div className="product__block" id="data-container">
                            <div className="product__box ">
                                <div className="leadProductsContainer">
                                    <h3>Партнерская сеть компаний по лидогенерации в сегменте недвижимости.</h3>
                                    <br/>
                                    <p>
                                        Привлеките дополнительные обращения клиентов по ваших объектам в
                                        согласованной форме скрипта и перевода. К платформе подключенные
                                        более 15 проверенных компаний по лидогенерации.
                                    </p>
                                    <p>
                                        Обеспечиваем прослушку и тегирование звонков партнеров,
                                        с возможностью оспариваний нецелевых обращений.
                                    </p>
                                    <Link to='add-object' className='addObject'>Добавить объект</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NonComplex;