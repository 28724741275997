import React from 'react';
import ReactDOM from 'react-dom';
import 'aos/dist/aos.css';
import './index.scss';
import './style.scss';
import './assets/styles/main.scss';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <App />
      </Router>

  </React.StrictMode>,
  document.getElementById('root')
);
