import React, {useContext, useEffect, useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import {UserContext} from "../../App";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Link} from "react-router-dom";
import PageLoader from "../page-loader";

const StockPopup = (props) => {
    const user = useContext(UserContext);
    const [campaigns, setCampaigns] = React.useState({});
    const [allCampaigns,setAllCampaigns] = useState({})
    const [allCampaignsArray,setAllCampaignsArray] = useState([])
    const [load,setLoad] = useState(true);
    useEffect(()=>{
        if(user !== null){
            axios.post('https://api.na100.pro/api.php?page=campaigns',user)
                .then(res=>{
                    setAllCampaignsArray(res.data.campaigns)

                    let selectedIds = [];
                    for(let i = 0; i < props.campaigns.length;i++){
                        selectedIds.push(props.campaigns[i].campaign_id)
                    }
                    let compData = {}
                    for(let item of res.data.campaigns){
                        if(selectedIds.indexOf(item.campaign_id) !== -1){
                            compData[`${item.campaign_id}`] = true
                        }else {
                            compData[`${item.campaign_id}`] = false
                        }
                    }
                    setAllCampaigns(compData)
                    setLoad(false)
                })
        }
    },[user,props.campaigns])
    useEffect(()=>{
        let compData = {}
        for(let item of props.campaigns){
            compData[`${item.campaign_id}`] = false
        }
        setCampaigns(compData)
    },[props.campaigns]);
    const handleChange = (event) => {
        setCampaigns({
            ...campaigns,
            [event.target.name]: event.target.checked,
        });
        setAllCampaigns({
            ...allCampaigns,
            [event.target.name]: event.target.checked,
        });
    };
    const handleObjToArray = (obj) => {
        const identifiers = Object.keys(obj)
        let arr = identifiers.filter(function(id) {
            return Number(obj[id])
        })
        return arr;
    }
    const updateStocks = ()=>{
        const fd = new FormData;
            let complexId = props.id.toString();
            fd.append('auth',localStorage.getItem('token'));
            fd.append('id[]',complexId);
            fd.append('do','update');
            let campData = handleObjToArray(allCampaigns);
            campData.forEach(el => {
                fd.append('campaign[]',el)
            });
            axios.post(`https://api.na100.pro/api.php?page=campproc`,fd)
                .then(res => {
                    if(res.data.result === 'success'){
                        props.setUpdate(!props.update)
                        props.setOpenStocks(false)
                    }
                })
    }
    const renderStocks = () => {
        let data = [];
        for(let [key,value] of Object.entries(allCampaigns)){
            let ogj = allCampaignsArray.find(e => e.campaign_id == key)
            const row = (
                <li key={key}>
                    <FormControlLabel
                        control={
                            <Checkbox size='small' checked={value} onChange={handleChange} name={key} />
                        }
                        label={ogj.campaign_name}
                    />
                    <p>{ogj.campaign_text}</p>
                </li>)
            data.push(row)
        }
        return data
    }
    const nonStocks = () => {
         return (
             <div className='nonStocks'>
                 <h2>Акции отсутствуют</h2>
                 <Link to={'/stock'}>Добавить акции</Link>
             </div>
         )
    }

    return (
        <section className="modal modal--big js-modal active stock-popup" data-open="link-list">
            <div className="modal__wrap">
                <div className="modal__inner">
                    <button onClick={()=>props.setOpenStocks(false)}
                        className="button__close js-close-modal" >x</button>
                    <div className="modal__title">
                        Действующие акции в ЖК.
                    </div>
                    <div className="link__list-wrap">
                        <div className="link__list-col">
                            <ul className="link__list stocksLists">
                                {load ? <PageLoader/> : Object.entries(allCampaigns).length !== 0 ?
                                        renderStocks() : nonStocks()}

                            </ul>
                        </div>
                        {Object.entries(allCampaigns).length !== 0 ?
                            <div className="buttonsContainer">
                                <button className='gray' onClick={()=>props.setOpenStocks(false)}>Отменить </button>
                                <button className='blue' onClick={updateStocks}>Применить</button>
                            </div> : ''}

                    </div>
                </div>
            </div>
        </section>
    );
};

export default StockPopup;