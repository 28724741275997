import React, {useContext, useEffect, useState} from 'react';
import Menu from "../../components/menu";
import CallsHeader from "../../components/callsHeader";
import CallsChart from "../../components/callsChart";
import CallItem from "./callItem";
import axios from "axios";
import {UserContext} from "../../App";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import sprite from "../../assets/img/sprites/sprite.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Calls = ({match}) => {
    const user = useContext(UserContext);
    const [complex,setComplex] = useState([])
    const [complexId,setComplexId] = useState(0)
    const [callsList,setCallsList] = useState([])
    const [callsList2,setCallsList2] = useState([])
    const [callsListToShow,setCallsListToShow] = useState([])
    const [graph,setGraph] = useState([])
    const [productsCount, setProductsCount] = useState(10)
    const [pagesCount, setPagesCount] = useState(0)
    const [page, setPage] = useState(1)
    const [chosenDates, setChosenDates] = useState({})
    const [statics, setStatics] = useState([])
    const [callsFilter, setCallsFilter] = useState()
    const [sectionOpen, setSectionOpen] = useState(false);
    const [chooseStatus, setChooseStatus] = useState(0);
    const [callServices, setCallServices] = useState({});
    const [callListForExport,setCallListForExport] = useState([])
    const dateFormatChanger = (date) => {
        let month = date.getMonth();
        let dateFormat = date.getDate();
        if(month.toString().length === 1){
            month = '0' + (date.getMonth() + 1);
        }else {
            month = date.getMonth() + 1
        }
        if(dateFormat.toString().length === 1){
            dateFormat = '0' + date.getDate();
        }
        return dateFormat + '.' + month + '.' + date.getFullYear()
    }
    const [dates,setDates] = useState({
        startDay:dateFormatChanger(new Date()),
        endDay:dateFormatChanger(new Date()),
    })
    const [sort,setSort] = useState('')
    const [order,setOrder] = useState('asc')
    const datesHandler = (e) => {
        setDates({
            startDay: dateFormatChanger(e.startDay),
            endDay: dateFormatChanger(e.endDay),
        })
    }
    const handleSortingService = (sort,order)=>{
        setSort(sort)
        setOrder(order)
    }
    const handleCallServicesData = (data) => {
        let servicesList = data.map(e => e.call_service);
        let services = [...new Set(servicesList)];
        let newData = {};
        services.map((el)=>{
            if(el){
                newData[el] = true;
            }
        })
        return newData;
    }
    useEffect(()=>{
        if(user !== null){
            user.append('sort',sort)
            user.append('order',order)

            axios.post(`https://api.na100.pro/api.php?page=calls&complex=${match.params.id ? match.params.id : complexId}&from=${dates.startDay}&to=${dates.endDay}${callsFilter ? callsFilter : ''}`,user)
                .then(res => {
                    setGraph(res.data.graph)
                    setCallsList(res.data.calls)
                    setCallsList2(res.data.calls)
                    setCallListForExport(res.data.calls)
                    setChosenDates(res.data.dates)

                    if(chooseStatus === 0){
                        setCallsListToShow(res.data.calls.slice(0,10))
                    }else{
                        setCallsListToShow(res.data.calls.filter(e=>Number(e.call_status) === chooseStatus).slice(0,10))
                    }
                })
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplex(res.data.complex)
                })
        }
    },[user,dates,complexId,match.params.id,sort,order,callsFilter])
    useEffect(()=>{
        if(user !== null){
            user.append('sort',sort)
            user.append('order',order)
            axios.post(`https://api.na100.pro/api.php?page=calls&complex=${match.params.id ? match.params.id : complexId}&from=${dates.startDay}&to=${dates.endDay}`,user)
                .then(res => {
                    setStatics(res.data.statistic)
                    setGraph(res.data.graph)
                    setCallsList(res.data.calls)
                    setCallsList2(res.data.calls)
                    setCallServices(handleCallServicesData(res.data.calls));
                    setChosenDates(res.data.dates)
                    if(chooseStatus === 0){
                        setCallsListToShow(res.data.calls.slice(0,10))
                    }else{
                        setCallsListToShow(res.data.calls.filter(e=>Number(e.call_status) === chooseStatus).slice(0,10))
                    }


                })
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplex(res.data.complex)
                })
        }
    },[user,dates,complexId,match.params.id,sort,order])
    useEffect(()=>{
        if(user !== null) {
            let data = callsList;
            setPagesCount(Math.ceil(data.length / productsCount))
            let pageinationData = data.slice((page - 1) * productsCount, page * productsCount)
            setCallsListToShow(pageinationData);
        }
    },[callsList,page,productsCount])
    const handleComplexId = (id) => {
        setComplexId(id);
    }
    const handlePage = (event, value) => {
        setPage(value);
    };
    const handleChange = (event) => {
        setProductsCount(event.target.value);
    };
    const renderItems = () => {
        return callsListToShow.map((el)=>{
            return <CallItem key={el.call_id} data={el}/>
        })
    }
    const handleClickAwaySection = () => {
        setSectionOpen(false);
    };
    const handleSectionOpen = () => {
        setSectionOpen((prev) => !prev);
    };
    const handleCallsFilter = (data)=>{
        setCallsFilter(data)
    }
    const handleFileName = ()=>{
        let date = new Date();
        return `NA100PRO_${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    }
    const handleServices = (event) => {
        setCallServices({
            ...callServices,
            [event.target.name]: event.target.checked,
        });
    }
    useEffect(()=>{
        let data = [];
        let selServices = [];
        for(let value in callServices){
            if(callServices[value] === true){
                selServices.push(value);
            }
        }
        callsList2.map((el)=>{
            if(chooseStatus === 0){
                if(selServices.indexOf(el.call_service) !== -1 || el.call_service === null){
                    data.push(el)
                }
            }else{
                if(selServices.indexOf(el.call_service) !== -1 && Number(el.call_status) === chooseStatus){
                    data.push(el)
                }
            }
        })

        setCallsList(data)
    },[callServices])
    const renderCallservices = () => {
        let data = [];
        for(let [key,value] of Object.entries(callServices)){
            const row = (
                <div key={key} className="checkbox__wrap-item checkbox__wrap-item--filter">
                    <FormControlLabel
                        control={
                            <Checkbox size='small' checked={value} onChange={handleServices} name={key} />
                        }
                        label={key}
                    />
                </div>)
            data.push(row)
        }
        return data
    }
    useEffect(()=>{
        let data =[];
        if(chooseStatus === 0){
            setCallsList(callsList2)
        }else{
            callsList2.map((e)=>{
                if(Number(e.call_status) === chooseStatus){
                    data.push(e)
                }
            })
            setCallsList(data)
        }
    },[chooseStatus,callsList2])
    return (
        <div>
            <Menu/>
            <CallsHeader
                datesHandler={datesHandler} complex={complex}
                handleComplexId={handleComplexId} matchId={match.params.id}
                callsList={callsList}
                exportData={callListForExport}
                fileName={handleFileName()}
            />
            <div className="common__section">
                <section className="period">
                    <div className="common__container">
                        <CallsChart  graph={graph} chosenDates={chosenDates} statics={statics}/>
                        <div className="callsFilter filter">
                            <div className="common__container">
                                <div className="filter__box">
                                    <div className="filter__button-wrap">
                                        <button className="button__filter button__filter--filter js-show-filter">
                                            Фильтры
                                        </button>
                                        <button className="button__filter button__filter--sort js-show-filter">
                                            Сортировка
                                        </button>
                                    </div>
                                    <div className="filter__container js-filter-container">
                                        <div className="filter__form js-filter justify-content-between">
                                            <div className="callsTabsContainer">
                                                <ul className="output__tabs">
                                                    <li className="output__tabs-item all me-3">
                                                        <button onClick={()=>setChooseStatus(0)}
                                                                className={`output__tabs-button js-tab ${chooseStatus === 0 ? 'active' : ''}`}>Все звонки</button>
                                                    </li>
                                                    <li className="output__tabs-item all me-3">
                                                        <button onClick={()=>setChooseStatus(1)}
                                                            className={`output__tabs-button js-tab ${chooseStatus === 1 ? 'active' : ''}`}>Целевые звонки</button>
                                                    </li>
                                                    <li className="output__tabs-item all me-3">
                                                        <button onClick={()=>setChooseStatus(3)}
                                                            className={`output__tabs-button js-tab ${chooseStatus === 3 ? 'active' : ''}`}>Пропущенные</button>
                                                    </li>
                                                    <li className="output__tabs-item all">
                                                        <button onClick={()=>setChooseStatus(4)}
                                                            className={`output__tabs-button js-tab ${chooseStatus === 4 ? 'active' : ''}`}>На рассмотрении</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="filter__form-inner">
                                                <div className="filter__inner">
                                                    <div className="filter__item js-filter-item">
                                                        <ClickAwayListener onClickAway={handleClickAwaySection}>
                                                            <Box sx={{ position: 'relative' }}>
                                                                <button className="filter__button js-filter-button" onClick={handleSectionOpen} data-action="modal">
                                                                    <span className="filter__button-text">
                                                                        Площадки
                                                                    </span>
                                                                    <div className="filter__button-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                                                             viewBox="0 0 10 6"
                                                                             fill="none">
                                                                            <path
                                                                                d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z"
                                                                                fill="black" fillOpacity="0.45"/>
                                                                        </svg>
                                                                    </div>
                                                                </button>

                                                                {sectionOpen ? (
                                                                    <div className="filter__modal js-filter-modal active">
                                                                        <div className="filter__modal-inner">
                                                                            <div className="checkbox__form">
                                                                                {renderCallservices()}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </Box>
                                                        </ClickAwayListener>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="period__table-container">
                            <div className="period__table">
                                <div className="period__table-header">
                                    <div className="period__table-header-item">
                                        <div className="period__table-header-inner">
                                            Дата
                                        </div>
                                    </div>
                                    <div className="period__table-header-item">
                                        <div className="period__table-header-inner">
                                            Номер клиента
                                        </div>
                                    </div>
                                    <div className="period__table-header-item pl-item">
                                        <div className="period__table-header-inner">
                                            Площадка
                                        </div>
                                        <div className="common__sort">
                                            <button
                                                onClick={()=>{handleSortingService('service','asc')}}
                                                style={{backgroundImage:`url(${sprite}#triangle)`}}
                                                className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                            <button
                                                onClick={()=>{handleSortingService('service','desc')}}
                                                style={{backgroundImage:`url(${sprite}#triangle)`}}
                                                className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                        </div>
                                    </div>
                                    <div className="period__table-header-item">
                                        <div className="period__table-header-inner">
                                            Статус
                                        </div>
                                        <div className="common__sort">
                                            <button
                                                onClick={()=>{handleSortingService('status','asc')}}
                                                style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                            <button
                                                onClick={()=>{handleSortingService('status','asc')}}
                                                style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                        </div>
                                    </div>
                                    <div className="period__table-header-item pl-item">
                                        <div className="period__table-header-inner">
                                            Стоимость
                                        </div>
                                        <div className="common__sort">
                                            <button
                                                onClick={()=>{handleSortingService('price','asc')}}
                                                style={{backgroundImage:`url(${sprite}#triangle)`}}
                                                className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                            <button
                                                onClick={()=>{handleSortingService('price','desc')}}
                                                style={{backgroundImage:`url(${sprite}#triangle)`}}
                                                className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                        </div>
                                    </div>
                                    <div className="period__table-header-item">
                                        <div className="period__table-header-inner">
                                            Продолжительность
                                        </div>
                                    </div>
                                    <div className="period__table-header-item playerHeader">
                                        <div className="period__table-header-inner">
                                            Плеер
                                        </div>
                                    </div>
                                </div>
                                <div className="period__table-body">
                                    {renderItems()}
                                </div>
                                <div className="pagination__wrap">
                                    <div className="pagination">
                                        <div className="pagination__select">
                                            <FormControl sx={{ m: 1, minWidth: 165,maxHeight:35 }}>
                                                <Select
                                                    value={productsCount}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value={10}>Выводить по 10</MenuItem>
                                                    <MenuItem value={20}>Выводить по 20</MenuItem>
                                                    <MenuItem value={100}>Выводить по 100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="pagination__list-wrap">
                                            <Stack spacing={2}>
                                                <Pagination count={pagesCount} shape="rounded"  page={page} onChange={handlePage}/>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Calls;