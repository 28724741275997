import React from 'react';
import Zoom from "react-medium-image-zoom";
import {Link} from "react-router-dom";

const SecondItem = (props) => {
    const {second_id,second_image,second_image_full,second_rooms,second_price,second_area,second_level,
        second_head,second_street,second_house,region_name,complex_name} = props.data;
    const login = localStorage.getItem('login')
    const priceHandler = (num)=>{
        let fv = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return fv.replace(',',' ')
    }

    return (
        <div className="apartment__table-row flatItem secondItem">
            <div className="apartment__table-body-item apartment__table-body-item--big">
                <div className="apartment__table-inner position-relative">
                    <div className="apartment__plan">
                        <Zoom>
                            <picture>
                                <img
                                    alt="that wanaka tree"
                                    src={second_image}
                                    width="100%"
                                />
                            </picture>
                        </Zoom>
                    </div>
                    <Link  to={`#`} className="apartment__link">{second_head}</Link>
                    {login === 'ENCO_user' ? <div className='platformsWrapper'>
                        <a href={props.url?.av ? props.url.av : ''} target="_blank" rel="noopener noreferrer">Авито</a>
                        <a href={props.url?.ci ? props.url.ci : ''} target="_blank" rel="noopener noreferrer">ЦИАН</a>
                        <a href={props.url?.ya ? props.url.ya : ''} target="_blank" rel="noopener noreferrer">Яндекс</a>
                    </div> : ''}
                </div>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Улица</span>
                <span className='flat_building'>{second_street}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Дом</span>
                <span>{second_house}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Этаж</span>
                <span>{second_level}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Регион</span>
                <span>{region_name}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Комнат</span>
                <span>{second_rooms}</span>
            </div>
            <div className="apartment__table-body-item apartment__table-body-item--middle">
                <span className="apartment__table-body-name">Площадь, м2</span>
                <span>{second_area}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Цена, ₽</span>
                <div className="apartment__table-box">
                    <span className='price_flat'>{priceHandler(second_price)}</span>
                </div>
            </div>
        </div>
    );
};

export default SecondItem;