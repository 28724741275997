import React, {useContext, useEffect, useState} from 'react';
import './comp.scss';
import axios from "axios";
import {UserAuthContext, UserContext} from "../../App";
import Menu from "../../components/menu";
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CompItem from "./comp-item";
import CompHeader from "./compHeader";
import QuartalItem from "./quartal-item";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Comp = ({match}) => {
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const [quartal, setQuartal] = React.useState([]);
    const [minQuartal, setMinQuartal] = React.useState([]);
    const [complexId, setComplexId] = React.useState('');
    const [allLots_rooms, setAllLots_rooms] = React.useState([]);
    const [allminPrice, setAllminPrice] = React.useState([]);
    const [allminpricerooms, setAllminpricerooms] = React.useState([]);
    const [allminarearooms, setAllminarearooms] = React.useState([]);
    const [load,setLoad] = useState(false);

    const renderItems = (data,price)=>{
        return data.map((el,index)=>{
            return (
                <CompItem price={price}  data={el}/>
            )
        })
    }
    const renderQuartarItems = (data,price,sum)=>{
        return Object.entries(data).map(([key, value]) => <QuartalItem sum={sum} price={price} complex={key} data={value}/>)
    }
    useEffect(()=>{
        userAuth();
        if(user !== null){
            axios.post(`https://api.na100.pro/api.php?page=comp&complex=${complexId}`,user)
                .then(res=>{
                    if(!complexId){
                        return false
                    }
                    setQuartal(res.data.quartal)
                    setMinQuartal(res.data.min_price_quartal)
                    setAllLots_rooms(res.data.lots_rooms);
                    setAllminPrice(res.data.min_price);
                    setAllminarearooms(res.data.min_area_rooms);
                    setAllminpricerooms(res.data.min_price_rooms);
                    setLoad(true)
                })
                .catch(err=>{
                    setLoad(true)
                })
        }
    },[user,complexId])
    useEffect(()=>{
        setComplexId(match.params.id)
    },[])
    const changeComplex = (id)=>{
        setComplexId(id)
    }
    const handleSum = (data,prop) => {
        let result = 0;
        data.map((el)=>{
            result += Number(el[prop])
        })
        return Math.round(result)
    }
    return (
        <div className="main__page">
            <Menu/>
            <CompHeader complexId={match.params.id} changeComplex={changeComplex}/>
            <div className="common__section">
                <div className="competitor">
                    <div className="common__container">
                        <div className="competitor__wrap pageComp">
                            {allLots_rooms.length === 0 && allminPrice.length === 0 && quartal.length === 0 && load ?
                                <h1 className='noData'>Отсутствуют данные</h1> :
                            <>
                                <div className="comp-item competitor__inner">
                                    <div className="itemMainTitle">
                                    <span className="competitor__top-item">
                                        Количество квартир в продаже
                                    </span>
                                    </div>
                                    <div className="competitor__top">
                                    <span className="competitor__top-item">
                                        Количество квартир в продаже
                                    </span>
                                    </div>
                                    <div className="competitor__header">
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>ЖК</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>Застройщик</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>Студия</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>1</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>2</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>3</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>4 и более</span>
                                        </div>
                                    </div>
                                    <div className="competitor__table">
                                        {renderItems(allLots_rooms)}
                                    </div>
                                    <div className="competitor__header">
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>Итог</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span></span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>{handleSum(allLots_rooms,'count_studio')}</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>{handleSum(allLots_rooms,'count_1')}</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>{handleSum(allLots_rooms,'count_2')}</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>{handleSum(allLots_rooms,'count_3')}</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>{handleSum(allLots_rooms,'count_4')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="comp-item competitor__inner">
                                    <div className="itemMainTitle">
                                    <span className="competitor__top-item">
                                        Минимальная стоимость квадратного метра
                                    </span>
                                    </div>
                                    <div className="competitor__top">
                                    <span className="competitor__top-item">
                                        Минимальная стоимость квадратного метра
                                    </span>
                                    </div>
                                    <div className="competitor__header">
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>ЖК</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>Застройщик</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>Студия</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>1</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>2</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>3</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>4 и более</span>
                                        </div>
                                    </div>
                                    <div className="competitor__table">
                                        {renderItems(allminPrice,true)}
                                    </div>
                                </div>
                                <div className="comp-item competitor__inner">
                                    <div className="itemMainTitle">
                                    <span className="competitor__top-item">
                                        Сроки сдачи корпусов
                                    </span>
                                    </div>
                                    <div className="competitor__top">
                                    <span className="competitor__top-item">
                                        Сроки сдачи корпусов
                                    </span>
                                    </div>
                                    <div className="competitor__header">
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>ЖК</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>Дата сдачи</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>Студия</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>1</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>2</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>3</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>4 и более</span>
                                        </div>
                                    </div>
                                    <div className="competitor__table">
                                        {renderQuartarItems(quartal,false,true)}
                                    </div>
                                </div>
                                <div className="comp-item competitor__inner">
                                    <div className="itemMainTitle">
                                    <span className="competitor__top-item">
                                        Минимальная стоимость квадратного метра с учетом сроков сдачи
                                    </span>
                                    </div>
                                    <div className="competitor__top">
                                    <span className="competitor__top-item">
                                        Минимальная стоимость квадратного метра с учетом сроков сдачи
                                    </span>
                                    </div>
                                    <div className="competitor__header">
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>ЖК</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>Дата сдачи</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>Студия</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>1</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>2</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>3</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>4 и более</span>
                                        </div>
                                    </div>
                                    <div className="competitor__table">
                                        {renderQuartarItems(minQuartal,true)}
                                    </div>
                                </div>
                                <div className="comp-item competitor__inner">
                                    <div className="itemMainTitle">
                                    <span className="competitor__top-item">
                                        Минимальная площадь квартир с учетом комнатности
                                    </span>
                                    </div>
                                    <div className="competitor__top">
                                    <span className="competitor__top-item">
                                        Минимальная площадь квартир с учетом комнатности
                                    </span>
                                    </div>
                                    <div className="competitor__header">
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>ЖК</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>Застройщик</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>Студия</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>1</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>2</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>3</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>4 и более</span>
                                        </div>
                                    </div>
                                    <div className="competitor__table">
                                        {renderItems(allminarearooms)}
                                    </div>
                                </div>
                                <div className="comp-item competitor__inner">
                                    <div className="itemMainTitle">
                                    <span className="competitor__top-item">
                                       Минимальная стоимость квартир с учетом комнатности
                                    </span>
                                    </div>
                                    <div className="competitor__top">
                                    <span className="competitor__top-item">
                                       Минимальная стоимость квартир с учетом комнатности
                                    </span>
                                    </div>
                                    <div className="competitor__header">
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>ЖК</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--top">
                                            <span>Застройщик</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>Студия</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>1</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>2</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>3</span>
                                        </div>
                                        <div className="competitor__header-item competitor__header-item--bottom">
                                            <span>4 и более</span>
                                        </div>
                                    </div>
                                    <div className="competitor__table">
                                        {renderItems(allminpricerooms,true)}
                                    </div>
                                </div>
                            </>
                             }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;