import React, {useContext, useEffect, useMemo, useState} from 'react';
import Filter from "./filter";
import axios from "axios";
import FlatsHeader from "./flatsHeader";
import FlatItem from "./flatItem";
import Stack from "@mui/material/Stack";
import Pagination from '@mui/material/Pagination';
import sprite from '../../assets/img/sprites/sprite.svg';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RightsContext, UserAuthContext, UserContext} from "../../App";
import Menu from "../../components/menu";
import PageLoader from "../../components/page-loader";
import {Link} from 'react-router-dom'
import MenuOpenButton from "../../components/menuOpenButton";
import FlatsStockPopup from "./flats-stock-popup";
import ContentPopup from "../../components/content-popup";


const Flats = ({match}) => {
    const [complex,setComplex] = useState([]);
    const [vases,setVases] = useState([]);
    const [sections,setSections] = useState([]);
    const [buildings,setBuildings] = useState([]);
    const [flats,setFlats] = useState([]);
    const [flatsTOShow,setFlatsToShow] = useState([]);
    const [complexId,setComplexId] = useState(match.params.id)
    const [checks,setChecks] = useState({});
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [productsCount, setProductsCount] = useState(10);
    const [pageLoader, setPageLoader] = useState(false);
    const [formD,setFormD] = useState('');
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const rights = useContext(RightsContext);
    const [openStocks,setOpenStocks] = useState(false);
    const [stockType,setStockType] = useState('')
    const [update,setUpdate] = useState(false);
    const [deletePopupOpen,setDeletePopupOpen] = useState(false)
    const [allCampaigns,setAllCampaigns] = useState({})
    const [allCampaignsArray,setAllCampaignsArray] = useState([])
    const handlePage = (event, value) => {
        setPage(value);
    };
    const resetChecks = () => {
        setChecks({});
    }
    const handleChange = (event) => {
        setProductsCount(event.target.value);
    };
    const flatsFilter = (flatsList) => {
         let firsData = [];
         let secondData = [];
         flatsList.map((el)=>{
             if(el.flat_campaign !== '' && el.flat_vas !== null){
                firsData.push(el)
             }else
             {
                 secondData.push(el)
             }
         })
        return firsData.concat(secondData) ;
    }
    useEffect(async ()=>{
        setPageLoader(true)
        userAuth()
        if(user !== null){
            axios.post(` https://api.na100.pro/api.php?page=ffilter&complex_id=${complexId}`,user)
                .then(res => {
                    setVases(res.data.vas)
                    setSections(res.data.sections)
                    setBuildings(res.data.building)
                })

            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplex(res.data.complex)
                })

            let flatsData = user
            if(complexId){
                flatsData.append('complex',complexId);
            } else if(match.params.id !== undefined){
                flatsData.append('complex',match.params.id);
            }
            flatsData.append('start','0')
            flatsData.append('limit','999999999')
            let d =
             axios.post(`https://api.na100.pro/api.php?page=flats`,flatsData)
                .then(res => {
                      let data = flatsFilter(res.data.flats)
                      setFlats(data)
                      setFlatsToShow(res.data.flats.slice(0,10))
                      setPagesCount(Math.ceil(res.data.flats.length / productsCount))
                      setPageLoader(false)
                })
                .catch(er => {
                    setPageLoader(false)
                })
        }
        resetChecks()
    },[user,complexId,update])
    useEffect(()=>{
        if(user !== null){
            let data = flats;
            setPagesCount(Math.ceil(data.length / productsCount))
            let pageinationData = data.slice((page - 1) * productsCount,page * productsCount)
            setFlatsToShow(pageinationData);
        }
    },[page,productsCount,flats])
    const handleSorting = (sort,order)=>{
        if(!sort || !order){
            return false
        }
        setPageLoader(true)
        let fd = '';
        if(formD !== ''){
            fd = formD
        }else {
            fd = new FormData;
            fd.append('start','0')
            fd.append('limit','999999999')
            fd.append('auth',localStorage.getItem('token'))
        }

        fd.append('complex',complexId.toString())
        fd.append('sort',sort)
        fd.append('order',order)
        axios.post(`https://api.na100.pro/api.php?page=flats`,fd)
            .then(res => {
                setFlats(res.data.flats)
                setPageLoader(false)
            })
    }
    const handleChecks = (event) => {
        setChecks({
            ...checks,
            [event.target.name]: event.target.checked,
        });
    };
    const handleFlatsData = (data) => {
        data.append('start','0')
        data.append('limit','999999999')
        setFormD(data);
        setPageLoader(true)
        axios.post(`https://api.na100.pro/api.php?page=flats`,data)
            .then(res => {
                setFlats(res.data.flats)
                setPageLoader(false)
            })
            .catch(er => {
                setPageLoader(false)
            })
    }
    const renderFlats = () => {
        return flatsTOShow.map((el,i)=>{
            return <FlatItem checkes={checks} handleChecks={handleChecks} key={el.flat_id} i={i} data={el}/>
        })
    }
    const resetData = () => {
        setPageLoader(true)
        axios.post(`https://api.na100.pro/api.php?page=flats`,user)
            .then(res => {
                setFlats(res.data.flats)
                setFlatsToShow(res.data.flats.slice(0,10))
                setPagesCount(Math.ceil(res.data.flats.length / productsCount))
                setPageLoader(false)
            })
            .catch(er => {
                setPageLoader(false)
            })
    }
    useEffect(()=>{
        if(user !== null){
            axios.post('https://api.na100.pro/api.php?page=campaigns',user)
                .then(res=>{
                    setAllCampaignsArray(res.data.campaigns)
                    let compData = {}
                    for(let item of res.data.campaigns){
                        compData[`${item.campaign_id}`] = false
                    }
                    setAllCampaigns(compData)
                })
        }
    },[user])
    const handleObjToArray = (obj) => {
        const identifiers = Object.keys(obj)
        let arr = identifiers.filter(function(id) {
            return Number(obj[id])
        })
        return arr;
    }
    const handleDeleteStocks = () => {
        const fd = new FormData;

        fd.append('auth',localStorage.getItem('token'));
        Object.keys(checks).forEach(el => {
            fd.append('id[]',el)
        });
        fd.append('do','remove');


        allCampaignsArray.forEach(el => {
            let idofCoamp = el.campaign_id.toString();
            fd.append('campaign[]',idofCoamp)
        });
        axios.post(`https://api.na100.pro/api.php?page=campproc`,fd)
            .then(res => {
                if(res.data.result === 'success'){

                }
            })
    }
    const handleRender = () => {
        let y = 0
        for (let i = 0; i < 1000 * 1000;i++){
           localStorage.setItem('auth:/','')
        }
        return y
    }
    return (
        <div className='second'>
            {pageLoader ? <PageLoader/> : ''}
            <Menu/>
            {/*{handleRender()}*/}
            <FlatsHeader/>
            {deletePopupOpen ?
                    <ContentPopup popupOpen={setDeletePopupOpen}>
                        <div className='stockPage-deletePopup-content'>
                            <p>В случае отключения акции, она будет снята со всех объектов.
                                Вы уверены что хотите отключить акцию?</p>
                            <div className='buttonsContainer'>
                                <button onClick={()=>{setDeletePopupOpen(false)}}>Нет</button>
                                <button onClick={()=>{
                                    setDeletePopupOpen(false)
                                    handleDeleteStocks()
                                }}>Отключить</button>
                            </div>
                        </div>
                    </ContentPopup> : ''
            }
            <div className="flatsPopupContainer">
                {openStocks ? <FlatsStockPopup setUpdate={setUpdate} update={update} type={stockType} ids={Object.keys(checks)} setOpenStocks={setOpenStocks}/> : ''}
            </div>
            <div className="common__section">
                <div className="filter">
                    <div className="common__container">
                        <div className="filter__box">
                            <div className="filter__container">
                                <div className="header__container">
                                    <div className="header__inner">
                                        <MenuOpenButton/>
                                        <div className="output__tabs-wrap js-tab-wrap">
                                            <ul className="output__tabs">
                                                <li className="output__tabs-item" style={{marginRight:'10px'}}>
                                                    <Link to={`/flats/${localStorage.getItem('ci')}`}  className={`output__tabs-button js-tab active`}>
                                                        Обычные лоты
                                                    </Link>
                                                </li>
                                                {
                                                    rights.indexOf('second') !== -1 ? <li className="output__tabs-item me-3">
                                                        <Link to={'/second'}  className={`output__tabs-button js-tab`}>
                                                            Трейд-ин
                                                        </Link>
                                                    </li> : ''
                                                }
                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/typical-layouts'}  className={`output__tabs-button js-tab`}>
                                                        Типовые планировки
                                                    </Link>
                                                </li>

                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/commercials'}  className={`output__tabs-button js-tab`}>
                                                        Коммерческая недвижимость
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Filter resetData={resetData}
                        handleFlatsData={handleFlatsData}
                        complex={complex}
                        sections={sections}
                        buildings={buildings}
                        complexId={match.params.id}
                        vases={vases}
                        changeComplex={setComplexId}
                        handleSorting={handleSorting}
                />
                <div className="filter">
                    <div className="common__container">
                        <div className="filter__box flatsFilter">
                            <div className="filter__container vasTitleContainer">
                                {Object.keys(checks).filter(key => checks[key]).length === 0 ? 'Выберите квартиры для усиления продвижения.'
                                    :<div className='selectedFlatsDesc'>
                                      <p>
                                          {`Выбрано ${Object.keys(checks).filter(key => checks[key]).length} квартир`}
                                      </p>
                                        <span onClick={resetChecks}>Снять выделение </span>
                                    </div>}
                                {
                                    Object.keys(checks).filter(key => checks[key]).length !== 0 ?
                                    <>
                                        {/*<Link to={'/add-vas'} className="button button--switch  active my-3">Усилить продвижение</Link>*/}
                                        <button className="button button--switch  active my-3" onClick={()=>{setOpenStocks(true);setStockType('add')}}>Назначить акции</button>
                                        <button className="button my-3" onClick={()=>{setDeletePopupOpen(true);setStockType('remove')}}>Снять акции</button>
                                    </> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apartment">
                    <div className="common__container">
                        <div className="apartment__table">
                            <div className="apartment__table-header">
                                <div className="apartment__table-header-item apartment__table-header-item--small">
                                    <span>
                                        №
                                    </span>
                                </div>
                                <div className="apartment__table-header-item apartment__table-header-item--big">
                                    <span>
                                        Объявление
                                    </span>
                                </div>
                                <div className="apartment__table-header-item" >
                                    <span>Корпус</span>
                                    <div className="common__sort">
                                        <button
                                            onClick={()=>{handleSorting('building','asc')}}
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--up js-sort-button">
                                        </button>
                                        <button
                                            onClick={()=>{handleSorting('building','desc')}}
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--down js-sort-button">
                                        </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Секция</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('section','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('section','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Этаж</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('level','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('level','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                <span>№ кварт.</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('num','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('num','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                <span>Комнат</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('rooms','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('rooms','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item apartment__table-header-item--middle">
                                    <span>Площадь, м2</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('area','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('area','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Цена, ₽</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('price','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('price','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span></span>
                                    <div className="common__sort">

                                    </div>
                                </div>
                            </div>
                            <div className="apartment__table-body">
                                {renderFlats()}
                            </div>
                            <div className="pagination__wrap">
                                <div className="pagination">
                                    <div className="pagination__select">
                                        <FormControl sx={{ m: 1, minWidth: 165,maxHeight:'35px' }}>
                                            <Select
                                                value={productsCount}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={10}>Выводить по 10</MenuItem>
                                                <MenuItem value={20}>Выводить по 20</MenuItem>
                                                <MenuItem value={100}>Выводить по 100</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="pagination__list-wrap">
                                        <Stack spacing={2}>
                                            <Pagination count={pagesCount} shape="rounded"  page={page} onChange={handlePage}/>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Flats;