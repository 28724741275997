import React from 'react';
import MenuOpenButton from "../../../components/menuOpenButton";

const OfferHeader = (props) => {
    return (
        <header className="header header--reload">
            <div className="common__container">
                <div className="header__container">
                    <div className="header__inner">
                        <MenuOpenButton/>
                        <div className="select__container">

                        </div>
                        <div className="breadcrumbs">
                            <ul className="breadcrumbs__list">
                                <li className="breadcrumbs__item"><span>Квартиры ЖК</span></li>
                                <li className="breadcrumbs__item"><span>{props.head}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="header__group">
                        <div className="product__info product__info--header">
                            <div className="product__info-icon">

                            </div>
                            <span className="product__info-text">
                                    Обновлено 21.02.2021 в 10:26
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    );
};

export default OfferHeader;