import React from 'react';
import MenuOpenButton from "../../../components/menuOpenButton";

const OutputHeader = (props) => {
    return (
        <header className="header header--output">

            <div className="common__container">

                <div className="header__container">
                    <div className="header__inner">
                        <MenuOpenButton/>
                        <div className="breadcrumbs">
                            <ul className="breadcrumbs__list">
                                <li className="breadcrumbs__item"><span>Общие показатели</span></li>
                                <li className="breadcrumbs__item"><span>{props.header}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="header__group">
                        <div className="form__datepicker-wrap">
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default OutputHeader;