import React, {useContext, useEffect, useState} from 'react';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {UserAuthContext, UserContext} from "../../../App";
import MenuOpenButton from "../../../components/menuOpenButton";

const CompHeader = (props) => {
    const [jk, setJk] = useState('');
    const [complex, setComplex] = useState([]);
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    useEffect(()=>{
        userAuth()
        if(user !== null){
            axios.post(` https://api.na100.pro/api.php?page=ffilter`,user)
                .then(res => {
                    setComplex(res.data.complex.filter(e => e.active === 1))
                })
        }

    },[user])
    useEffect(()=>{
        setJk(props.complexId)
    },[])
    const handleJk = (event) => {
        setJk(event.target.value);
        props.changeComplex(event.target.value)
    };
    const renderComplex = () => {
        return complex.map((el)=>{
            if(el.active === 1){
                return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
            }
        })
    }
    return (
        <header className="header">
            <div className="common__container">
                <div className="header__container">
                    <div className="header__inner">
                        <MenuOpenButton/>
                        <div className="breadcrumbs">
                            <ul className="breadcrumbs__list">
                                <li className="breadcrumbs__item"><span>Отчёт по конкурентам</span></li>
                            </ul>
                        </div>
                        <div className="filter__item js-filter-item">
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <Select
                                    value={jk}
                                    onChange={handleJk}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {/*<MenuItem value={'all'}>Все ЖК</MenuItem>*/}
                                    {renderComplex()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default CompHeader;