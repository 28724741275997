import React from 'react';
import MenuOpenButton from "../../../components/menuOpenButton";

const FlatsHeader = (props) => {
    return (
        <header className="header header--call">

        <div className="common__container">
            <div className="header__container">
                <div className="header__inner">
                    <MenuOpenButton/>
                    <div className="breadcrumbs">
                        <ul className="breadcrumbs__list">
                            <li className="breadcrumbs__item"><span>{props.text ? props.text : 'Квартиры в продаже'}</span></li>
                        </ul>
                    </div>
                </div>
                <form action="/index.php" method="get" style={{display:'none'}}>
                    <input type="hidden" name="page" value="flats"/>
                        <input type="hidden" name="limit" value=""/>
                </form>
            </div>
        </div>
        </header>
    );
};

export default FlatsHeader;