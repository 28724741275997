import React, {useContext, useEffect, useState} from 'react';
import './calls-exchange.scss';
import Menu from "../../components/menu";
import HomeHeader from "../homePage/homeHeader";
import NonComplex from "./no-complex";
import axios from "axios";
import {RightsContext, UserAuthContext, UserContext} from "../../App";
import ProductItem from "../homePage/productItem";

const CallsExchange = () => {
    const [complexes,setComplexes] = useState([])
    const [res,setRes] = useState(false)
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const rights = useContext(RightsContext)
    useEffect(()=>{
        if(rights.indexOf('lklead') === -1){
            window.location.href = '/no-access'
        }
    },[])
    useEffect(()=>{
        userAuth();
        if(user !== null){
            axios.post('https://api.na100.pro/api.php?page=leadcomplex',user)
                .then(res => {
                    res.data.message !== 'Access Denied' ? setComplexes(res.data.complex) : setComplexes([])
                    setRes(true)
                })
        }
    },[user])
    const renderComplexes = ()=>{
        return complexes.map((el)=>{
            return <ProductItem key={el.complex_id} data={el} updated={false} url={'calls'} apartment={false}/>
        })
    }
    return (
        <div>
            <Menu/>
            <HomeHeader
                button={true}
                addObject={true}
                title={false}
                activation={'all'}
                showButtons={false}
            />
            {
                res ? complexes.length !== 0 ? <div className="common__section classifiedPage">
                    <section className="product">
                        <div className="common__container">
                            <div className="js-product-box">
                                <div className="product__block" id="data-container">
                                    <div className="product__box">
                                        {renderComplexes()}
                                    </div>
                                    {/*{products.length !==  allProducts.length && products.length >= 10  ? <div className="showMore" onClick={handleShowMore}>Показать еще {products.length} из {allProducts.length}</div> : ''}*/}
                                </div>
                            </div>
                        </div>
                    </section>
                </div> : <NonComplex/> : ''
            }
        </div>
    );
};

export default CallsExchange;