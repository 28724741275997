import React, {useContext, useEffect, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import NotesHeader from "./notesHeader";
import NoteItem from "./noteItem";
import Menu from "../../components/menu";
import axios from "axios";
import {UserContext} from "../../App";
const Note = () => {
    const user = useContext(UserContext);
    const [notes,setNotes] = useState([]);
    const [notesToShow,setNotesToShow] = useState([]);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [productsCount, setProductsCount] = useState(10);

    useEffect(()=>{
        if(user !== null){
            axios.post('https://api.na100.pro/api.php?page=news',user)
                .then(res => {
                    setNotes(res.data.news)
                    setNotesToShow(res.data.news.slice(0,10))
                })
        }
    },[user])
    useEffect(()=>{
        let dataList = notes;
        setPagesCount(Math.ceil(dataList.length / productsCount))
        let pageinationData = dataList.slice((page - 1) * productsCount,page * productsCount)
        setNotesToShow(pageinationData);
    },[notes,page,productsCount])
    const handlePage = (event, value) => {
        setPage(value);
    };
    const handleChange = (event) => {
        setProductsCount(event.target.value);
    };
    const renderNotes = () => {
        return notesToShow.map((e)=>{
            return <NoteItem key={e.news_id} note={e}/>
        })
    }
    return (
        <div>
            <Menu/>
            <NotesHeader/>
            <div className="common__section">
                <div className="notification-page">
                    <div className="common__container">
                        <div className="notification-page__block">
                            <div className="notification-page__inner">
                                <ul className="notification-page__list">
                                    {renderNotes()}
                                </ul>
                                <div className="pagination__wrap">
                                    <div className="pagination">
                                        <div className="pagination__select">
                                            <FormControl sx={{ m: 1, minWidth: 165,maxHeight:35 }}>
                                                <Select
                                                    value={productsCount}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value={10}>Выводить по 10</MenuItem>
                                                    <MenuItem value={20}>Выводить по 20</MenuItem>
                                                    <MenuItem value={100}>Выводить по 100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="pagination__list-wrap">
                                            <Stack spacing={2}>
                                                <Pagination count={pagesCount} shape="rounded"  page={page} onChange={handlePage}/>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Note;