import React, {useContext, useEffect, useState} from 'react';
import './main-header.scss';
import {Link} from "react-router-dom";
import logo from "../../assets/img/img/logo.jpg";
import classyfide from "../../assets/img/svg/classyfide.svg";
import callstore from "../../assets/img/svg/callstore.svg";
import laptop2 from "../../assets/img/svg/laptop2.svg";
import {RightsContext} from "../../App";
const MainHeader = () => {
    const rights = useContext(RightsContext)
    const [chapter, setChapter] = useState('class')
    const [path,setPath] = useState(window.location.pathname);
    const handleChapter = (chapter)=>{
        localStorage.setItem('chapter',chapter);
        setChapter(chapter)
    }
    return (
        <div className='mainHeader'>
            <div className="common__container">
                <div className="menu__header">
                    <div className="menu__logo-wrap">
                        <Link to='/' className="menu__logo">
                            <img src={logo} alt="na100pro"/>
                        </Link>
                        <span className="menu__logo-text">
                        Система продвижения объектов недвижимости в сети Интернет
                    </span>
                    </div>
                </div>
                <ul className='nav'>
                    {
                        window.location.pathname !== "/login" ?  <li>
                            <Link
                                className={chapter === 'class' && path !== '/login' ? 'activeLink' : ''}
                                to={path !== '/login' ?  '/' : '#'}
                                onClick={()=>handleChapter('class')}><img src={laptop2} alt=""/>
                                Классифайды
                            </Link>
                        </li> : ''
                    }

                    {
                        rights.indexOf('lklead') !== -1 ? <li>
                            <Link className={chapter === 'call' && path !== '/login' ? 'activeLink' : ''}
                                  to={path !== '/login' ?  '/calls-exchange' : '#'} onClick={()=>handleChapter('call')}><img src={callstore} alt=""/>Биржа звонков</Link>
                        </li> : ''
                    }

                    {/*<li><Link to={path !== '/login' ?  '/social' : '#'} className={chapter === 'social' && path !== '/login' ? 'activeLink' : ''} onClick={()=>handleChapter('social')}>*/}
                    {/*    <img src={classyfide} alt=""/>Социальные сети</Link>*/}
                    {/*</li>*/}
                </ul>
            </div>
        </div>
    );
};

export default MainHeader;