import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {MenuOpen, SetMenuOpen} from "../../../App";
import MenuOpenButton from "../../../components/menuOpenButton";

const HomeHeader = (props) => {
    const {activation} = props
    return (
    <header className="header">
        <div className="common__container">
            <div className="header__container">
                <div className="header__inner">
                    <MenuOpenButton/>
                    <div className="breadcrumbs">
                        <ul className="breadcrumbs__list">
                            <li className="breadcrumbs__item"><span>{props.title ? 'Общие показатели' : ''}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="header__group">
                <div className="button__group js-switch-container">
                    {props.showButtons !== false ?
                        <>
                            <div className="button__item"><button className={`button button--switch js-button-switch ${activation === 'all' ? 'active' : ''}`} data-action="all" onClick={()=>{
                                props.setActivation('all')}}>Все</button></div>
                            <div className="button__item"><button className={`button button--switch js-button-switch ${activation === 'active' ? 'active' : ''}`} data-action="active" onClick={()=>{
                                props.setActivation('active')}}>Активные</button></div>
                            <div className="button__item"><button className={`button button--switch js-button-switch home-header-last-button ${activation === 'nonactive' ? 'active' : ''}`} data-action="nonactive" onClick={()=>{
                                props.setActivation('nonactive')}}>Неактивные</button></div>
                        </> : ''
                    }


                    {props.button ? <Link to={`${props.addObject ? '/add-object' : '#'}`} className={`add-object-button ${props.addObject ? '' : 'disabledButton'}`}>Добавить объект</Link> : ''}

                </div>
                </div>
            </div>
        </div>
    </header>
);
};

export default HomeHeader;