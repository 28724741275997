import React from 'react';
import vasicons from '../../assets/img/img/vasicons.png'
import {Link} from "react-router-dom";
const AdvFlatItem = (props) => {
    return (
        <div className='advFlatItem'>
            <div className="details">
                <img src={props.data.flat_image_full} className='flatImage' alt="flat image"/>
                <div className="des">
                    <Link to={`offer/${props.data.flat_id}`} className="title">{props.data.flat_title}</Link>
                    {/*<div className="dataContainer">*/}
                    {/*    <img src={vasicons} className='icons' alt=""/>*/}
                    {/*    <p className='vasText' dangerouslySetInnerHTML={{__html:props.data.flat_vas.replace('<br/>-','<br/>')}}></p>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default AdvFlatItem;