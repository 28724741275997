import React, {useContext, useEffect, useState} from 'react';
import OfferHeader from "./offerHeader";
import axios from "axios";
import {UserContext} from "../../App";
import Menu from "../../components/menu";
import {Link} from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'
import PageLoader from "../../components/page-loader";
const Offer = ({match}) => {
    const [flat,setFlat] = useState({})
    const [images,setImages] = useState([])
    const [services,setServices] = useState([])
    const [pageLoader, setPageLoader] = useState(false);

    const user = useContext(UserContext);
    useEffect(()=>{
        setPageLoader(true)
        if(user !== null){
            axios.post(`https://api.na100.pro/api.php?page=offer&id=${match.params.id}`,user)
                .then(res => {
                    console.log(res.data);
                    setFlat(res.data)
                    setImages(res.data.images)
                    setServices(res.data.services)
                    setPageLoader(false)
                })
        }
    },[user,match])
    const priceHandler = (num)=>{
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    const renderImages = () => {
        return images.map((el)=>{
            return (
                <li key={el.image_full} className="offer__img-item">
                    <Zoom>
                        <picture>
                            <img
                                alt="that wanaka tree"
                                src={el.image_full}
                                width="100%"
                            />
                        </picture>
                    </Zoom>
                </li>
            )
        })
    }
    const renderServices = () => {
        return services.map((el)=>{
            return (
                <li key={el.service_link} className="block__link-item">
                    <a href={el.service_link} target='_blank' rel="noopener noreferrer" className="block__link-link"  >
                        <span>
                            {el.service_name}
                        </span>
                    </a>
                </li>

            )
        })
    }
    const handleCianUrl = () => {
         let serviceUrl = services.filter(e => e.service_name === 'cian');
         return serviceUrl.length !== 0 ? serviceUrl[0].service_link : flat.complex_url_cian;
    }
    const handleAvitoUrl = () => {
         let serviceUrl = services.filter(e => e.service_name === 'avito');
         return serviceUrl.length !== 0 ? serviceUrl[0].service_link : flat.complex_url_avito;
    }
    const handleYandexUrl = () => {
         let serviceUrl = services.filter(e => e.service_name === 'Яндекс.Недвижимость');
         return serviceUrl.length !== 0 ? serviceUrl[0].service_link : flat.complex_url_yandex;
    }
    return (
        <div>
            <Menu/>
            <OfferHeader head={flat.complex_name}/>
            <div className="common__section">
                {pageLoader ? <PageLoader/> : ''}
                <div className="offer">
                    <div className="common__container">
                        <div className="offer__inner">
                            <div className="offer__item">
                                <div className="offer__reload">
                                    <div className="product__info product__info--header">
                                        <div className="product__info-icon">

                                        </div>
                                        <span className="product__info-text">
                                        Обновлено {flat.flat_update}
                                    </span>
                                    </div>
                                </div>
                                <div className="offer__header">
                                    <h2 className="offer__title">
                                        {flat.flat_head}
                                    </h2>
                                    <div className="button__wrap button__wrap--switch">
                                        <Link to={`/offer/${flat.prev}`} className="button__page button__page--prev">
                                            <span>
                                                Предыдущее объявление
                                            </span>
                                        </Link>
                                        <Link to={`/offer/${flat.next}`} className="button__page button__page--next">
                                        <span>
                                            Следующее объявление
                                        </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="offer__info">
                                    <div className="offer__info-main">
                                        <span className="offer__info-calc">
                                            {priceHandler(Number(flat.flat_price))} ₽
                                        </span>
                                        <span className="offer__info-area">
                                            {flat.flat_price_metr} ₽/м2
                                        </span>
                                    </div>
                                    <div className="offer__info-box">
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Количество комнат
                                        </span>
                                        <span className="offer__info-text">
                                            {flat.flat_rooms}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Этаж
                                        </span>
                                        <span className="offer__info-text">
                                            {flat.flat_level} / {flat.section_level}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Общая площадь
                                        </span>
                                        <span className="offer__info-text">
                                            {flat.flat_area} м2
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Кухня
                                        </span>
                                        <span className="offer__info-text">
                                            {flat.flat_kitchen} м2
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Вид из окон
                                        </span>
                                        <span className="offer__info-text">
                                            {flat.flat_window}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Ремонт
                                        </span>
                                        <span className="offer__info-text">
                                          {flat.flat_repair}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Комнаты
                                        </span>
                                        <span className="offer__info-text">
                                           {flat.flat_type}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Секция
                                        </span>
                                        <span className="offer__info-text">
                                           {flat.section_num}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Жилая площадь
                                        </span>
                                        <span className="offer__info-text">
                                           {flat.flat_live} м2
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Состояние
                                        </span>
                                        <span className="offer__info-text">
                                           {flat.flat_quality}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Отделка
                                        </span>
                                        <span className="offer__info-text">
                                           {flat.flat_decor}
                                        </span>
                                        </div>
                                        <div className="offer__info-item">
                                        <span className="offer__info-title">
                                            Покрытие пола
                                        </span>
                                        <span className="offer__info-text">
                                           {flat.flat_floor}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="offer__item">
                                <div className="offer__desc">
                                    <div className="offer__text-inner js-text-action-box">
                                        <div className="offer__text js-text-action-content" dangerouslySetInnerHTML={{__html:flat.flat_desc}}></div>
                                        <button className="button__open js-button-show-content open">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                                 viewBox="0 0 10 6" fill="none">
                                                <path
                                                    d="M9.15167 0.143158H8.31461C8.25769 0.143158 8.20412 0.17106 8.17064 0.216819L4.99988 4.58736L1.82912 0.216819C1.79564 0.17106 1.74207 0.143158 1.68515 0.143158H0.848094C0.775549 0.143158 0.733139 0.225747 0.775549 0.284899L4.71082 5.71012C4.85367 5.90655 5.14608 5.90655 5.28783 5.71012L9.22309 0.284899C9.26662 0.225747 9.22421 0.143158 9.15167 0.143158Z"
                                                    fill="black" fillOpacity="0.45"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="offer__desc-box">
                                        <div className="offer__img">
                                            <ul className="offer__img-list">
                                                {renderImages()}
                                            </ul>
                                        </div>
                                        <div className="offer__mark">
                                            <ul className="offer__mark-list">
                                                <li className="offer__mark-item">
                                                    <span className="offer__mark-desc">Пассажирский лифт</span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.liftp === 'Нет' ? 'negative' : 'positive'}`}>
                                                    <span>
                                                        {flat.liftp}
                                                    </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Свободная планировка
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.freeplan === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                    {flat.freeplan}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Лоджия
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.lodgia === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                    {flat.lodgia}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Парковая зона
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.parking === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                    {flat.parking}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Грузовой лифт
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.liftg === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.liftg}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Телефон
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.phone === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.phone}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Магазины
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.shop === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.shop}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    торг
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.haggle === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.haggle}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                   Санузел совмещенный
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.sus === 'Нет' ? 'negative' : 'positive'}`}>
                                                    <span>
                                                       {flat.sus}
                                                    </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Мусоропровод
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.trash === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.trash}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Парковка
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.parking === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.parking}
                                                </span>
                                                </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    рассрочка
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.deferred === 'Нет' ? 'negative' : 'positive'}`}>
                                                    <span>
                                                       {flat.deferred}
                                                    </span>
                                                </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Санузел раздельный
                                                </span>
                                                    <div className={`offer__mark-status offer__mark-status--${flat.sur === 'Нет' ? 'negative' : 'positive'}`}>
                                                    <span>
                                                       {flat.sur}
                                                    </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Торговые центры
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.market === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.market}
                                                </span>
                                                </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Охрана
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.security === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.security}
                                                </span>
                                                    </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    ипотека
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.mortgage === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.mortgage}
                                                </span>
                                                </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    студия
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.studio === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.studio}
                                                </span>
                                                </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Балкон
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.balcon === 'Нет' ? 'negative' : 'positive'}`}>
                                                    <span>
                                                       {flat.balcon}
                                                    </span>
                                                </div>
                                                </li>
                                                <li className="offer__mark-item">
                                                <span className="offer__mark-desc">
                                                    Детская площадка
                                                </span>
                                                <div className={`offer__mark-status offer__mark-status--${flat.child === 'Нет' ? 'negative' : 'positive'}`}>
                                                <span>
                                                   {flat.child}
                                                </span>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="offer__item">
                                <div className="block__link-box">
                                    <h3 className="common__h2">Подключенные площадки</h3>
                                    <div className="block__link">
                                        <ul className="block__link-list">
                                            {
                                                localStorage.getItem('login') === 'forma_guru' ? <>
                                                    <li className="block__link-item">
                                                        <a href={flat.complex_url_m2} target='_blank' rel="noopener noreferrer" className="block__link-link">
                                                <span>
                                                    M2
                                                </span>
                                                        </a>
                                                    </li>
                                                    <li className="block__link-item">
                                                        <a href={flat.complex_url_avaho} target='_blank' rel="noopener noreferrer" className="block__link-link">
                                                <span>
                                                    Avaho
                                                </span>
                                                        </a>
                                                    </li>
                                                    <li className="block__link-item">
                                                        <a href={flat.complex_url_move} target='_blank' rel="noopener noreferrer" className="block__link-link">
                                                <span>
                                                    Move.su
                                                </span>
                                                        </a>
                                                    </li></> : <>
                                                    <li className="block__link-item">
                                                        <a href={handleCianUrl()} target={flat.complex_url_cian ? '_blank' : '_self'} rel="noopener noreferrer" className="block__link-link">
                                                <span>
                                                    ЦИАН
                                                </span>
                                                        </a>
                                                    </li>
                                                    <li className="block__link-item">
                                                        <a href={handleYandexUrl()} target={flat.complex_url_yandex ? '_blank' : '_self'} rel="noopener noreferrer" className="block__link-link">
                                                <span>
                                                    Яндекс
                                                </span>
                                                        </a>
                                                    </li>
                                                    <li className="block__link-item">
                                                        <a href={handleAvitoUrl()} target={flat.complex_url_avito ? '_blank' : '_self'} rel="noopener noreferrer" className="block__link-link">
                                                <span>
                                                    Авито
                                                </span>
                                                        </a>
                                                    </li>
                                                </>
                                            }

                                        </ul>
                                    </div>
                                </div>
                                <div className="block__link-box">
                                    <span className=" common__h2">Охватные площадки Na100pro</span>
                                    <div className="block__link">
                                        <ul className="block__link-list">
                                            {renderServices()}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Offer;