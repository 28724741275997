import React from 'react';
import MenuOpenButton from "../../../components/menuOpenButton";

const MediaHeader = () => {
    return (
        <header className="header">
            <div className="common__container standardHeader">
                <div className="header__container">
                    <div className="header__inner">
                        <MenuOpenButton/>
                        <div className="breadcrumbs">
                            <ul className="breadcrumbs__list">
                                <li className="breadcrumbs__item">
                                    <span>Бриф на запуск рекламной кампании</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default MediaHeader;