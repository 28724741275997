import React from 'react';
import Menu from "../../components/menu";
import HomeHeader from "../homePage/homeHeader";
import NonComplexSocial from "./non-complex-social";

const Social = () => {
    return (
        <div>
            <Menu/>
            <HomeHeader button={true} title={false} activation={'all'}/>
            <NonComplexSocial/>
        </div>
    );
};

export default Social;