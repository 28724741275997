import React, {useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";

const OutputSalesChart = (props) => {
    const [graph,setGraph] = useState([]);
    const graphsDataCorrecter = (data)=>{
        if(!data){
            return [];
        }
        let firstDate = data[0]
        let secondDate = data[1]
        if(!firstDate && !secondDate){
            return [];
        }
        let dateArrayNfd = firstDate.date.split('.')
        let dateArrayNsd = firstDate.date.split('.')
        let nfd = new Date().setFullYear(1970,Number(dateArrayNfd[1]),Number(dateArrayNfd[0]))
        let nsd = new Date().setFullYear(1970,Number(dateArrayNsd[1]),Number(dateArrayNsd[0]))
        if(nfd > nsd){
            return data.reverse
        }else{
            return data
        }


    }
    useEffect(()=>{
        setGraph(props.graph.reverse())
    },[props.graph])
    const monthsList = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль',
        'Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    return (
        <div className="output__item">
            <span className="output__title output__title--margin graph-sales">
                Динамика остатков квартир за {monthsList[new Date().getMonth()]} {new Date().getFullYear()} г.
            </span>
            <div className="output__container">

                <div className="output__plan-wrap vasChartSection">
                    {graph.length !== 0 > 0 ? <Line data={{
                        labels: graph.map((el)=>{
                            return el.date
                        }),
                        datasets: [{
                            legend: false,
                            data: graph.map((el)=>{
                                return el.count
                            }),
                            borderColor: '#436FFD',
                            fill: true,
                            backgroundColor: 'rgba(67, 111, 253, 0.62)',
                        }],
                    }}
                    width={100}
                    height={280}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {legend: false},
                        scales: {
                            y: {
                                ticks: {
                                    stepSize: 1,
                                    beginAtZero: true,
                                },
                            },
                        },
                    }}
                    /> : <p className='noGraph'>Отсутствуют данные</p>}

                </div>
            </div>
        </div>
    );
};

export default OutputSalesChart;