import React, {useEffect, useState} from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'
import sprite from '../../assets/img/sprites/sprite.svg';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import './callsHeader.scss';
import MenuOpenButton from "../menuOpenButton";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import OutsideClickHandler from 'react-outside-click-handler';

const CallsHeader = (props) => {
    const [jk, setJk] = useState(props.matchId ? props.matchId : 'all');
    const [pickerOpen, setPickerOpen] = useState(false);
    const [dateChoosed, setChoosed] = useState('month');
    const [dates, setDates] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            type:3,
        }
    ]);
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
        let nd = []
        apiData.map((e)=>{
            nd.push({
                Дата:e.call_date,
                Номер:e.call_number,
                ЖК :e.call_complex,
                Площадка:e.call_service,
                Продолжительность:e.call_duration,
                Статус:e.call_status_text,
                mp3:e.mp3_source,
            })
        })
        const ws = XLSX.utils.json_to_sheet(nd);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    useEffect(()=>{
        props.datesHandler({
            startDay:dates[0].startDate,
            endDay:dates[0].endDate,
        })
        props.handleComplexId(jk)
    },[dates,jk])
    useEffect(()=>{
        setPickerOpen(false)
    },[dates])
    const handlePickerOpen = () => {
        setPickerOpen((prev) => !prev);
    };
    const handleJk = (event) => {
        setJk(event.target.value);
    }
    const renderComplex = () => {
        if(!props.matchId){
            return props.complex.map((el)=>{
                if(el.active === 1){
                    return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                }
            })
        }else{
            let com = props.complex.find(e => e.id === props.matchId)
            if(com){
                return  <MenuItem key={com.id} value={com.id}>{com.name}</MenuItem>
            }
        }
    }
    const handleDates = (e) => {
        let from = new Date();
        let to = new Date();
        switch (e) {
            case 1:
                from = new Date();
                to = new Date();
                setChoosed('day')
            break;
            case 2:
                Date.prototype.GetFirstDayOfWeek = function() {
                    return (new Date(this.setDate(this.getDate() - this.getDay()+ (this.getDay() == 0 ? -6:1) )));
                }
                Date.prototype.GetLastDayOfWeek = function() {
                    return new Date(this.setDate(this.getDate() - (this.getDay() == 0 ? 7 : this.getDay()) + 7));
                }
                let today = new Date();
                from = today.GetFirstDayOfWeek();
                to = today.GetLastDayOfWeek();
                setChoosed('week')
                break;
            case 3:
                let date = new Date();
                 from = new Date(date.getFullYear(), date.getMonth(), 1);
                 to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                setChoosed('month')
            break;
        }
        let data = [
            {
                startDate: from,
                endDate: to,
                key: 'selection',
            }
        ]
        setDates(data)
    }
    useEffect(()=>{
        handleDates(3)
    },[])
    return (
        <header className="header header--call">
            <div className="common__container">
                <div className="header__container">
                    <div className="header__inner">
                        <MenuOpenButton/>
                        <div className="breadcrumbs">
                            <ul className="breadcrumbs__list">
                                <li className="breadcrumbs__item"><span>Отчет по звонкам</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="header__group callsHeaderSelect">
                        <div className="select__wrap select__wrap--all">
                            <FormControl sx={{ m: 1, minWidth: 120,width:'100%',height:28, }}>
                                <Select
                                    value={jk}
                                    onChange={handleJk}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {!props.matchId && <MenuItem value={'all'}>Все ЖК</MenuItem> }
                                    {renderComplex()}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="button__group button__group--date js-switch-container">
                            <div className="button__item">
                                <button
                                        className={`button button--switch js-button-switch ${dateChoosed === 'day' ? 'active' : ''}`}
                                        data-action="today" onClick={()=>{handleDates(1)}}>Сегодня
                                </button>
                            </div>
                            <div className="button__item">
                                <button className={`button button--switch js-button-switch ${dateChoosed === 'week' ? 'active' : ''}`}
                                        data-action="week"
                                        onClick={()=>{handleDates(2)}}>За
                                    неделю
                                </button>
                            </div>
                            <div className="button__item">
                                <button className={`button button--switch js-button-switch ${dateChoosed === 'month' ? 'active' : ''}`}
                                        data-action="month" onClick={()=>{handleDates(3)}}>За месяц
                                </button>
                            </div>
                        </div>
                        <div className="form__datepicker-wrap">

                            <button className='dpButton' style={{backgroundImage:`url(${sprite}#calendar)`}} onClick={()=>setPickerOpen(true)}> </button>
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                   setPickerOpen(false)
                                }}
                            >
                                <div className={`dpContainer ${pickerOpen ? 'pickerOpen' : ''}`} >
                                    <DateRange
                                        onChange={item => setDates([item.selection])}
                                        ranges={dates}
                                    />
                                </div>
                            </OutsideClickHandler>

                        </div>
                        <button
                            className="button__download button__download--cut"
                            style={{backgroundImage:`url(${sprite}#download)`}}
                            onClick={(e) => exportToCSV(props.exportData, props.fileName)}> </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default CallsHeader;