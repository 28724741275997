import React, {useContext, useEffect, useState} from 'react';
import Menu from "../../components/menu";
import StockHeader from "./stock-header";
// import miclogo from '../../assets/img/img/miclogo.png'
import StockItem from "../../components/stock-item";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import Stack from "@mui/material/Stack";
// import Pagination from "@mui/material/Pagination";
import AddStock from "../../components/add-stock";
import {UserAuthContext, UserContext} from "../../App";
import axios from "axios";
import ContentPopup from "../../components/content-popup";
const Stock = () => {
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const [stocks,setStocks] = useState([])
    const [popUp,setPopup] = useState(false);
    const [dataForUpdate,setDataForUpdate] = useState(null)
    const [client,setClient] = useState({})
    const [popupType,setPopupType] = useState('add')
    const [deletePopupOpen,setDeletePopupOpen] = useState(false)
    const [deleteStockId,setDeleteStockId] = useState()
    useEffect(()=>{
        userAuth()
        if(user !== null){
            axios.post('https://api.na100.pro/api.php?page=campaigns',user)
                .then(res=>{
                    setStocks(res.data.campaigns)
                    setClient(res.data.client)
                })
        }
    },[user,popupType])
    const renderStocks = ()=>{
        return stocks.map((el)=>{
            return <StockItem key={el.campaign_id}
                              data={el}
                              setDataForUpdate={setDataForUpdate}
                              setPopup={setPopup}
                              deleteStock={deleteStock}
                              client={client}
                              setPopupType={setPopupType}
                              setDeleteStockId={setDeleteStockId}
                              setDeletePopupOpen={setDeletePopupOpen}
            />
        })
    }
    const deleteStock = (id) => {
        const fd = new FormData
        fd.append('campaign_disable','1');
        fd.append('campaign_id',id);
        fd.append('auth',localStorage.getItem('token'));
        axios.post(`https://api.na100.pro/api.php?page=campupdate`,fd)
            .then(res => {
                ;
            })
    }

    return (
        <div>
            <Menu/>
            <StockHeader setPopup={setPopup} setPopupType={setPopupType} setDataForUpdate={setDataForUpdate}/>
            <AddStock  popUp={popUp} setPopupType={setPopupType} popupType={popupType} setPopup={setPopup} data={dataForUpdate}/>
            <div className={`common__section ${stocks.length === 0 ? 'pb-0 pt-0' : ''}`}>
                {
                    stocks.length !== 0 ? <div className='period'>
                        <div className="common__container">
                            <div className='period__wrap'>
                                {renderStocks()}
                            </div>
                        </div>
                    </div> : ''
                }
                {
                    deletePopupOpen ?
                    <ContentPopup popupOpen={setDeletePopupOpen}>
                        <div className='stockPage-deletePopup-content'>
                            <p>В случае отключения акции, она будет снята со всех объектов.
                                Вы уверены что хотите отключить акцию?</p>
                            <div className='buttonsContainer'>
                                <button onClick={()=>{setDeletePopupOpen(false)}}>Нет</button>
                                <button onClick={()=>{
                                    setDeletePopupOpen(false)
                                    deleteStock(deleteStockId)
                                    setPopupType(deleteStockId)
                                }}>Отключить</button>
                            </div>
                        </div>

                    </ContentPopup> : ''
                }
            </div>
            {stocks.length === 0 ? <div className='bg-white p-3 my-2'>
                <h1 className='noData'>Отсутствуют данные</h1>
            </div> : ''}
        </div>
    );
};

export default Stock;