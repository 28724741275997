import React from 'react';
import Menu from "../../components/menu";
import {Link} from "react-router-dom";

const NotFoundPage = () => {
    return (
        <div className="common__section">
            <Menu/>
            <div className='period'>
                <div className="common__container">
                    <div className='period__wrap'>
                        <div className='none-content'>
                            <h1>Ошибка прав доступа к разделу личного кабинета.</h1>
                            <p> Обратитесь к администратору для получения прав доступа к запрашиваемому разделу.</p>
                            <Link to={'/'}>перейти на главную страницу</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;