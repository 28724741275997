import React from 'react';
import Zoom from "react-medium-image-zoom";
import {Link} from "react-router-dom";

const CommercialItem = (props) => {
    const {flat_image,flat_rooms,flat_section,flat_price,flat_area,flat_level,complex_name,
        flat_title} = props.data;
    const priceHandler = (num)=>{
        let fv =  num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return fv.replace(',',' ')
    }
    return (
        <div className="apartment__table-row flatItem">
            <div className="apartment__table-body-item apartment__table-body-item--big">
                <div className="apartment__table-inner">
                    <div className="apartment__plan">
                        <Zoom>
                            <picture>
                                <img
                                    alt="that wanaka tree"
                                    src={flat_image}
                                    width="100%"
                                />
                            </picture>
                        </Zoom>
                    </div>
                    <Link  to={`#`} className="apartment__link">{flat_title}</Link>
                </div>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">ЖК</span>
                <span className='flat_building'>{complex_name}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Этаж</span>
                <span>{flat_level}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Секция</span>
                <span>{flat_section}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Комнат</span>
                <span>{flat_rooms}</span>
            </div>
            <div className="apartment__table-body-item apartment__table-body-item--middle">
                <span className="apartment__table-body-name">Площадь, м2</span>
                <span>{flat_area}</span>
            </div>
            <div className="apartment__table-body-item">
                <span className="apartment__table-body-name">Цена, ₽</span>
                <div className="apartment__table-box">
                    <span className='price_flat'>{priceHandler(flat_price.toString())}</span>
                </div>
            </div>
        </div>
    );
};

export default CommercialItem;