import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../App";
import axios from "axios";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Menu from "../../components/menu";
import AdvHeader from "../../components/advHeader";
import CallsChart from "../../components/callsChart";
import ChartStandard from "../../components/chartStandard";
import sprite from "../../assets/img/sprites/sprite.svg";
import AuctionTableRow from "../../components/auctionTableRow";
import AdvListSettings from "../../components/adv-list-settings";
import {Line} from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AuctionSection from "./auction-section";


const Cian = () => {
    const [graph,setGraph] = useState([])
    const [chosenDates, setChosenDates] = useState({})
    const [statics, setStatics] = useState([])
    const [history,setHistory] = useState([])
    const [ceoSearch, setCeoSearch] = React.useState('type1');
    const [data,setData] = useState()
    const [catalog,setCatalog] = useState([])
    const [catalogMix,setCatalogMix] = useState([])
    const [flats,setFlats] = useState([])
    const [bets,setBets] = useState({})
    const [dateType,setDateType] = useState(0)
    const [selectedId,setSelectedId] = useState(localStorage.getItem('selectedId'))
    const user = useContext(UserContext)
    const handleGraphData = (data) => {
        let fineldata = [];
        for (const property in data) {
            let handledata = {}
            for (const el in data[property]){
                handledata.stat_0 = data[property].count_0
                handledata.stat_1 = data[property].count_1
                handledata.stat_2 = data[property].count_2
                handledata.stat_3 = data[property].count_3
                handledata.stat_4 = data[property].count_4
                handledata.stat_5 = data[property].count_5
                handledata.count_all = data[property].stat_all
            }
            let newData = {
                label:property,
                ...handledata
            }
            fineldata.push(newData)
        }
        return fineldata
    }
    const handleStatisticData = (data) => {
        return {
            stat_all:data.total_all,
            stat_0:data.total_0,
            stat_1:data.total_1,
            stat_2:data.total_2,
            stat_3:data.total_3,
            stat_4:data.total_4,
            stat_5:data.total_5,
        }
    }
    const handleCatalogData = (data) => {
       return data.map((el)=>{
           return {
               date:el.date,
               count:el.place,
           }
       })
    }
    useEffect(()=>{
        if(user !== null){
            axios.post(`https://api.na100.pro/api.php?page=class&complex_id=${selectedId ? selectedId : localStorage.getItem('ci')}`,user)
                .then(res => {
                    setGraph(handleGraphData(res.data.cian.graph))
                    setStatics(handleStatisticData(res.data.cian.graph_total))
                    setCatalog(handleCatalogData(res.data.catalog))
                    setCatalogMix(handleCatalogData(res.data.catalog_mix))
                    setFlats(res.data.cian.flats)
                    setHistory(res.data.cian.history)
                    setBets(res.data.cian.bets)
                })
        }
    },[user,selectedId])
    const handleCallsFilter = (data)=>{

    }
    const handleChange = (event) => {
        setCeoSearch(event.target.value);
    };
    const monthsList = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль',
        'Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    const handleToday = (day) => {
        let date = '';
        if(day === 'toDay'){
            date = new Date().getDate();
        }else {
            date = new Date().getDate() - 1;
        }
        let month = new Date().getMonth() + 1;
        if(date.toString().length === 1){
            date = '0' + date;
        }
        if(month.toString().length === 1){
            month = '0' + month;
        }
        return `${date}.${month}.${new Date().getFullYear()}`;
    }
    const monthLength = (date) => {
        let month = date.getMonth() + 1
        return month.toString().length
    }
    const getDaysInMonth = (month, year) => {
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
            days.push(`${new Date(date).getDate().toString().length === 1 ? '0' + new Date(date).getDate() : new Date(date).getDate()}.${monthLength(new Date(date)) === 1 ? '0' + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1}.${new Date(date).getFullYear()}`);
            date.setDate(date.getDate() + 1);
        }
        return days;
    }
    const renderAuctions = () => {
        let toDayDate = handleToday('toDay')
        let beforeDate = handleToday()
        let monthDaysArr = getDaysInMonth(new Date().getMonth(),new Date().getFullYear())
        let data = [];
        let toDay = history.filter((e)=>{
            return e.history_date.split(' ')[0] === toDayDate
        })
        let beforeDay = history.filter((e)=>{
            return e.history_date.split(' ')[0] === beforeDate
        })
        let monthDaysData = history.filter((e)=>{
            return  monthDaysArr.indexOf(e.history_date.split(' ')[0]) != -1
        })
        if(dateType === 0){
            data = toDay
        }else if(dateType === 1){
            data = beforeDay
        }else {
            data = monthDaysData
        }
        return data.map((el)=>{
            return <AuctionTableRow data={el}/>
        })
    }
    const renderBets = (type) => {
        if(bets.position){
            return bets.position.map((e,index)=>{
                while (index < 10){
                    return <div className="item">{e[type]}</div>;
                }
            })
        }

    }
    const handleBetsData = () => {
                return [
                    {
                        label:'Позиция в каталоге новостроек ',
                        data: bets.position.map(el => el.position),
                        borderColor: [
                            'orange'
                        ],
                        borderWidth: 3,
                        fill: false,
                        backgroundColor: 'orange'
                    },
                    {
                        label:'Позиция в смешанной выдачи',
                        data: bets.position.map(el=> el.place),
                        borderColor: '#436FFD',
                        borderWidth: 3,
                        fill: true,
                        backgroundColor: 'rgba(67, 111, 253, 0.62)'
                    },
                ]
            }

    const handleCallsData = () => {
        let data = []
             graph.map(el=>{

                data.push( {
                    date:el.label,
                    count:el.stat_0 + el.stat_1 + el.stat_2 + el.stat_3 + el.stat_4 + el.stat_5,
                })
            })
        return data;
    }
    return (
        <div className='yandex-page'>
            <Menu/>
            <AdvHeader type='cian' setSelectedId={setSelectedId}/>
            <div className="common__section">
                <section className="period">
                    <div className="common__container d-flex">
                        <AuctionSection  selectedId={selectedId}/>
                        <div className="d-flex w-50">
                            <CallsChart handleCallsFilter={handleCallsFilter} graph={graph}  statics={statics}/>
                        </div>
                    </div>
                </section>
            </div>
            {
                flats.length !== 0 ? <div className="common__section">
                    <section className="period">
                        <div className="common__container">
                             <AdvListSettings flats={flats}/>
                        </div>
                    </section>
                </div> : ''
            }
            <div className="common__section">
                <div className="output">
                    <div className="common__container">
                        <div className="output__wrap">
                            <div className="output__item mw-100">
                                <span className="output__title output__title--margin">
                                    Видимость в поисковой выдаче за {monthsList[new Date().getMonth()]} {new Date().getFullYear()} г.
                                </span>
                                <div className="output__header">
                                    <div className="output__header-item"><span
                                        className="output__header-title">Позиция в поисковой выдаче (среднее)</span><span
                                        className="output__header-calc">15/210</span></div>
                                    <div className="output__header-item"><span className="output__header-title">Позиция в поисковой выдаче (на сегодня)</span><span
                                        className="output__header-calc">2/210</span></div>
                                </div>
                                <div className="output__header radioContainer">
                                    <FormControl component="fieldset">
                                        <RadioGroup row
                                            aria-label="gender"
                                            name="controlled-radio-buttons-group"
                                            value={ceoSearch}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="type1" control={<Radio />} label="Смешанная выдача" />
                                            <FormControlLabel value="type2" control={<Radio />} label="Каталог новостройки" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div className="chartTitle">
                                    Позиция в поисковой выдаче
                                </div>
                                <div className="output__container">
                                    <div className="output__plan-wrap vasChartSection">
                                        {ceoSearch === 'type1' ? <ChartStandard graph={catalogMix}/> : <ChartStandard graph={catalog}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {bets.date_actual ? <div className="common__section">
                <div className="period">
                    <div className="common__container">
                        <div className="period__wrap">
                            <div className="advInfoHeader">
                                <div className="info w-100 flex-row justify-content-between">
                                    <p className="bidStoryTitle">Прогнозное состояние аукциона за {bets.date_actual}</p>
                                    <p>Обратитесь к менеджеру для обновления расчетных показателей</p>
                                </div>
                            </div>
                            <div className="auctionState">
                                <Line data={{
                                    labels: bets.position.map((el)=>{
                                        return el.bet
                                    }),
                                    datasets: handleBetsData(),
                                }}
                                      width={100}
                                      height={300}
                                      options={{
                                          maintainAspectRatio: false,
                                          responsive: true,
                                          label: {
                                              display:true,
                                              align:'left'
                                          },
                                          scales: {
                                              myScale: {
                                                  type: 'logarithmic',
                                                  position: 'left', // `axis` is determined by the position as `'y'`
                                              }
                                          }
                                      }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}

            {history.length !== 0 ? <div className="common__section">
                <section className="period">
                    <div className="common__container">
                        <div className="apartment__table">
                            <h5 className='py-3'>История изменения ставок Циан</h5>
                            <div className="output__tabs-wrap js-tab-wrap mb-3" style={{width:'150px'}}>
                                <ul className="output__tabs">
                                    <li onClick={()=>{setDateType(0)}} className="output__tabs-item">
                                        <span   className={`output__tabs-button js-tab ${dateType === 0 ? 'active': ''} `}>
                                            сегодня
                                        </span>
                                    </li>
                                    <li onClick={()=>{setDateType(1)}} className="output__tabs-item">
                                        <span className={`output__tabs-button js-tab ${dateType === 1 ? 'active': ''} `}>
                                            вчера
                                        </span>
                                    </li>
                                    {/*<li onClick={()=>{setDateType(2)}} className="output__tabs-item">*/}
                                    {/*    <span className={`output__tabs-button js-tab ${dateType === 2 ? 'active': ''} `}>*/}
                                    {/*        за нед*/}
                                    {/*    </span>*/}
                                    {/*</li>*/}
                                    {/*<li onClick={()=>{setDateType(3)}} className="output__tabs-item">*/}
                                    {/*    <span className={`output__tabs-button js-tab ${dateType === 3 ? 'active': ''} `}>*/}
                                    {/*        с начала мес*/}
                                    {/*    </span>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                            <div className="apartment__table-header">
                                <div className="apartment__table-header-item" >
                                    <span>Дата</span>
                                    <div className="common__sort">
                                        <button
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--up js-sort-button">
                                        </button>
                                        <button
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--down js-sort-button">
                                        </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Ликвидность</span>
                                    <div className="common__sort">
                                        <button style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Ставка, ₽</span>
                                    <div className="common__sort">
                                        <button style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Ст.</span>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>1</span>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>2</span>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>3</span>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>4+</span>
                                </div>
                            </div>
                            <div className="apartment__table-body">
                                {renderAuctions()}
                            </div>
                        </div>
                    </div>
                </section>
            </div> : ''}
        </div>
    );
};

export default Cian;