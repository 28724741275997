import React, {useContext, useEffect, useState} from 'react';
import Menu from "../../components/menu";
import FlatsHeader from "../flats/flatsHeader";
import sprite from "../../assets/img/sprites/sprite.svg";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import FlatItem from "../flats/flatItem";
import {UserAuthContext, UserContext} from "../../App";
import SecondItem from "./second-item";
import MenuOpenButton from "../../components/menuOpenButton";
import {Link} from "react-router-dom";

const Second = () => {
    const [flats,setFlats] = useState([]);
    const [flatsTOShow,setFlatsToShow] = useState([]);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [productsCount, setProductsCount] = useState(10);
    const user = useContext(UserContext);
    const userAuth = useContext(UserAuthContext);
    const handleChange = (event) => {
        setProductsCount(event.target.value);
    };
    const handlePage = (event, value) => {
        setPage(value);
    };
    const handleSorting = ()=>{

    }
    useEffect(()=>{
        if(user !== null){
            axios.post(`${process.env.REACT_APP_BASE_API}?page=second`,user)
                .then(res=>{
                    console.log(res.data.second);
                    let data = res.data.second
                    setFlats(data)
                    setFlatsToShow(res.data.second.slice(0,10))
                    setPagesCount(Math.ceil(res.data.second.length / productsCount))
                })
        }

    },[user])
    useEffect(()=>{
        if(user !== null){
            let data = flats;
            setPagesCount(Math.ceil(data.length / productsCount))
            let pageinationData = data.slice((page - 1) * productsCount,page * productsCount)
            setFlatsToShow(pageinationData);
        }
    },[page,productsCount,flats])
    const urls = [
        {
            av:'https://www.avito.ru/tyumen/kvartiry/1-k._kvartira_386m_1217et._2701239554',
            ci:'https://tyumen.cian.ru/sale/flat/281599803/',
            ya:'https://realty.ya.ru/offer/244100646675644774/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/1-k._kvartira_386m_317et._2573708421',
            ci:'https://tyumen.cian.ru/sale/flat/279227108/',
            ya:'https://realty.ya.ru/offer/244100646675644806/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/1-k._kvartira_179m_1317et._2573205361',
            ci:'https://tyumen.cian.ru/sale/flat/279208753/',
            ya:'https://realty.ya.ru/offer/244100646675645483/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/2-k._kvartira_511m_44et._2701645536',
            ci:'https://tyumen.cian.ru/sale/flat/280065881/',
            ya:'https://realty.ya.ru/offer/244100646675644803/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/3-k._kvartira_568m_38et._2701556612',
            ci:'https://tyumen.cian.ru/sale/flat/280759109/',
            ya:'https://realty.ya.ru/offer/244100646675644776/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/1-k._kvartira_352m_1919et._2701767311',
            ci:'https://tyumen.cian.ru/sale/flat/281599801/',
            ya:'https://realty.ya.ru/offer/244100646675644775/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/1-k._kvartira_406m_616et._2733320428',
            ci:'https://tyumen.cian.ru/sale/flat/282598781/',
            ya:'https://realty.ya.ru/offer/244100646675644773/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/2-k._kvartira_679m_610et._2765096595',
            ci:'https://www.cian.ru/cat.php?deal_type=sale&engine_version=2&id_user=96439792&offer_type=flat&sort=area_order',
            ya:'https://realty.ya.ru/offer/244100646675644772/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/1-k._kvartira_302m_918et._2733735590',
            ci:'https://tyumen.cian.ru/sale/flat/283059964/',
            ya:'https://realty.ya.ru/offer/244100646675644772/'
        },
        {
            av:'https://www.avito.ru/tyumen/kvartiry/1-k._kvartira_386m_317et._2573708421',
            ci:'https://tyumen.cian.ru/sale/flat/279227108/',
            ya:'https://realty.ya.ru/offer/244100646675644806/'
        },
    ]
    const handleUrls = (index)=>{
        switch (index) {
            case 0:
                return urls[7]
                break; case 1:
                return urls[6]
                break;
                case 2:
                return urls[0]
                break;
                case 3:
                return urls[8]
                break;
                case 4:
                return urls[9]
                break;
            case 5:
                return urls[2]
                break;
            case 6:
                return urls[4]
                break;
            case 7:
                return urls[5]
                break;
        }
    }
    return (
        <div className='second'>
            <Menu/>
            <FlatsHeader/>
            <div className="common__section">
                <div className="filter">
                    <div className="common__container">
                        <div className="filter__box">
                            <div className="filter__container">
                                <div className="header__container">
                                    <div className="header__inner">
                                        <MenuOpenButton/>
                                        <div className="output__tabs-wrap js-tab-wrap">
                                            <ul className="output__tabs">
                                                <li className="output__tabs-item" style={{marginRight:'10px'}}>
                                                    <Link to={`/flats/${localStorage.getItem('ci')}`}  className={`output__tabs-button js-tab `}>
                                                        Обычные лоты
                                                    </Link>
                                                </li>
                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/second'}  className={`output__tabs-button js-tab active`}>
                                                        Трейд-ин
                                                    </Link>
                                                </li>
                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/typical-layouts'}  className={`output__tabs-button js-tab`}>
                                                        Типовые планировки
                                                    </Link>
                                                </li>

                                                <li className="output__tabs-item me-3">
                                                    <Link to={'/commercials'}  className={`output__tabs-button js-tab`}>
                                                        Коммерческая недвижимость
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apartment">
                    <div className="common__container">
                        <div className="apartment__table">
                            <div className="apartment__table-header">
                                <div className="apartment__table-header-item apartment__table-header-item--big">
                                    <span>
                                        Объявление
                                    </span>
                                </div>
                                <div className="apartment__table-header-item" >
                                    <span>Улица</span>
                                    <div className="common__sort">
                                        <button
                                            onClick={()=>{handleSorting('building','asc')}}
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--up js-sort-button">
                                        </button>
                                        <button
                                            onClick={()=>{handleSorting('building','desc')}}
                                            style={{backgroundImage:`url(${sprite}#triangle)`}}
                                            className="common__sort-button common__sort-button--down js-sort-button">
                                        </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Дом</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('section','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('section','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Этаж</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('level','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('level','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Регион</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('num','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('num','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Комнат</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('rooms','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('rooms','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item apartment__table-header-item--middle">
                                    <span>Площадь, м2</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('area','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('area','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                                <div className="apartment__table-header-item">
                                    <span>Цена, ₽</span>
                                    <div className="common__sort">
                                        <button onClick={()=>{handleSorting('price','asc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--up js-sort-button"> </button>
                                        <button onClick={()=>{handleSorting('price','desc')}} style={{backgroundImage:`url(${sprite}#triangle)`}} className="common__sort-button common__sort-button--down js-sort-button"> </button>
                                    </div>
                                </div>
                            </div>
                            <div className="apartment__table-body">
                                {
                                     flatsTOShow.map((el,i)=> {
                                         return (<SecondItem key={el.second_id} url={handleUrls(i)} data={el}/>)
                                     })
                                }
                            </div>
                            <div className="pagination__wrap">
                                <div className="pagination">
                                    <div className="pagination__select">
                                        <FormControl sx={{ m: 1, minWidth: 165,maxHeight:'35px' }}>
                                            <Select
                                                value={productsCount}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={10}>Выводить по 10</MenuItem>
                                                <MenuItem value={20}>Выводить по 20</MenuItem>
                                                <MenuItem value={100}>Выводить по 100</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="pagination__list-wrap">
                                        <Stack spacing={2}>
                                            <Pagination count={pagesCount} shape="rounded"  page={page} onChange={handlePage}/>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Second;