import React, {useState} from 'react';
import Menu from "../../components/menu";
import iiconblue from '../../assets/img/img/iiconblue.png';
const AddFlat = () => {
    const [title,setTitle] = useState('')
    const [installment,setInstallment] = useState(true)
    const [mortgage,setMortgage] = useState(true)
    const [bargain,setBargain] = useState(true)
    const [freeLayout,setFreeLayout] = useState(true)
    const [combined,setCombined] = useState(true)
    const [divided,setDivided] = useState(false)
    const [studio,setStudio] = useState(true)
    const [balcony,setBalcony] = useState(true)
    const [loggia,setLoggia] = useState(true)
    const [trash,setTrash] = useState(true)
    const [phone,setPhone] = useState(true)
    const [passengerLift,setPassengerLift] = useState(true)
    const [serviceLift,setServiceLift] = useState(true)
    const [shoppingCentres,setSHoppingCentres] = useState(true)
    const [shops,setShops] = useState(true)
    const [parking,setParking] = useState(true)
    const [security,setSecurity] = useState(true)
    const [playground,setPlayground] = useState(true)
    const [parkingArea,setParkingArea] = useState(true)
    return (
        <div>
            <Menu/>
            <div className="common__section">
                <div className='period'>
                    <div className="common__container">
                        <div className='period__wrap'>
                            <div className="add-flat">
                                <div className="firstRow">
                                    <div className="column-1">
                                        <h3 className="titleMain">Общая информация</h3>
                                        <h4 className="titleSmall">Название объявления</h4>
                                        <input type="text"
                                               value={title}
                                               placeholder='Название объявления'
                                               onChange={e=>setTitle(e.target.value)}/>
                                        <h4 className="titleSmall">Описание объявления</h4>
                                        <textarea placeholder='Текст объявления'></textarea>
                                        <button className="generateButton">Сгенерировать объявление</button>
                                        <div className="noteBox">
                                            <img src={iiconblue} alt=""/>
                                            Описание объявления будет сгенерировано на основе ваших данных
                                        </div>
                                    </div>
                                    <div className="column-2">
                                        <h3 className="titleMain">Загрузка изображений</h3>
                                        <label className='fileUploadLabel'>
                                            <span>+</span>
                                            <span>Загрузить</span>
                                            <input type="file" hidden/>
                                        </label>
                                    </div>
                                    <div className="column-3">
                                        <button className='save-button'>
                                            Создать объявление
                                        </button>
                                    </div>
                                </div>
                                <h3 className="title">Условия приобретения</h3>
                                <div className="standardRow">
                                    <div className="columns">
                                        <p className="titlesSmall">Стоимость помещения</p>
                                        <input type="text" placeholder='10 000 000 ₽'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Рассрочка</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setInstallment(true)}} className={`yesNoButton ${installment ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setInstallment(false)}} className={`yesNoButton ${!installment ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Ипотека</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setMortgage(true)}} className={`yesNoButton ${mortgage ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setMortgage(false)}} className={`yesNoButton ${!mortgage ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Торг</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setBargain(true)}} className={`yesNoButton ${bargain ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setBargain(false)}} className={`yesNoButton ${!bargain ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Подробная информация</h3>
                                <div className="standardRow">
                                    <div className="columns">
                                        <p className="titlesSmall">Секция</p>
                                        <input type="text" placeholder='1'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Этаж</p>
                                        <div className="inputsRow">
                                            <input className='small' type="text" placeholder='12'/>
                                            <span>из</span>
                                            <input className='small' type="text" placeholder='16'/>
                                        </div>

                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Количество комнат</p>
                                        <input type="text" placeholder='1'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Тип комнат</p>
                                        <input type="text" placeholder='Изолированный'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Свободная планировка</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setFreeLayout(true)}} className={`yesNoButton ${freeLayout ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setFreeLayout(false)}} className={`yesNoButton ${!freeLayout ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="standardRow">
                                    <div className="columns">
                                        <p className="titlesSmall">Вид из окон</p>
                                        <input type="text" placeholder='Во двор'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Санузел совмещенный</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setCombined(true)}} className={`yesNoButton ${combined ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setCombined(false)}} className={`yesNoButton ${!combined ? 'active' : ''}`}>Нет</button>
                                            <input className='small leftSpace' placeholder='1' type="text"/>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Санузел раздельный</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setDivided(true)}} className={`yesNoButton ${divided ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setDivided(false)}} className={`yesNoButton ${!divided ? 'active' : ''}`}>Нет</button>
                                            <input className='small leftSpace' placeholder='1' type="text"/>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Площадь помещения</h3>
                                <div className="standardRow">
                                    <div className="columns">
                                        <p className="titlesSmall">Общая площадь</p>
                                        <input type="text" placeholder='38,6 м2'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Жилая площадь</p>
                                        <input type="text" placeholder='22,4 м2'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Площадь кухни</p>
                                        <input type="text" placeholder='8,1 м2'/>
                                    </div>
                                </div>
                                <div className="title">Отделка помещения</div>
                                <div className="standardRow">
                                    <div className="columns">
                                        <p className="titlesSmall">Состояние</p>
                                        <input type="text" placeholder='Удовлетворительное'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Ремонт</p>
                                        <input type="text" placeholder='Без ремонта'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Покрытие пола</p>
                                        <input type="text" placeholder='Стяжка'/>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Студия</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setStudio(true)}} className={`yesNoButton ${studio ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setStudio(false)}} className={`yesNoButton ${!studio ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="title">Удобства</div>
                                <div className="standardRow">
                                    <div className="columns">
                                        <p className="titlesSmall">Балкон</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setBalcony(true)}} className={`yesNoButton ${balcony ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setBalcony(false)}} className={`yesNoButton ${!balcony ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Лоджия</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setLoggia(true)}} className={`yesNoButton ${loggia ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setLoggia(false)}} className={`yesNoButton ${!loggia ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Мусоропровод</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setTrash(true)}} className={`yesNoButton ${trash ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setTrash(false)}} className={`yesNoButton ${!trash ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Телефон</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setPhone(true)}} className={`yesNoButton ${phone ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setPhone(false)}} className={`yesNoButton ${!phone ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Пассажирский лифт</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setPassengerLift(true)}} className={`yesNoButton ${passengerLift ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setPassengerLift(false)}} className={`yesNoButton ${!passengerLift ? 'active' : ''}`}>Нет</button>
                                            <input className='small leftSpace' placeholder='1' type="text"/>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Грузовой лифт</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setServiceLift(true)}} className={`yesNoButton ${serviceLift ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setServiceLift(false)}} className={`yesNoButton ${!serviceLift ? 'active' : ''}`}>Нет</button>
                                            <input className='small leftSpace' placeholder='1' type="text"/>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="title">Инфраструктура</h3>
                                <div className="standardRow">
                                    <div className="columns">
                                        <p className="titlesSmall">Торговые центры</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setSHoppingCentres(true)}} className={`yesNoButton ${shoppingCentres ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setSHoppingCentres(false)}} className={`yesNoButton ${!shoppingCentres ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Магазины</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setShops(true)}} className={`yesNoButton ${shops ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setShops(false)}} className={`yesNoButton ${!shops ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Мусоропровод</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setTrash(true)}} className={`yesNoButton ${trash ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setTrash(false)}} className={`yesNoButton ${!trash ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Парковка</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setParking(true)}} className={`yesNoButton ${parking ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setParking(false)}} className={`yesNoButton ${!parking ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Охрана</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setSecurity(true)}} className={`yesNoButton ${security ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setSecurity(false)}} className={`yesNoButton ${!security ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Детская площадка</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setPlayground(true)}} className={`yesNoButton ${playground ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setPlayground(false)}} className={`yesNoButton ${!playground ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <p className="titlesSmall">Парковая зона</p>
                                        <div className="smallButtonsContainer">
                                            <button onClick={()=>{setParkingArea(true)}} className={`yesNoButton ${parkingArea ? 'active' : ''}`}>Да</button>
                                            <button onClick={()=>{setParkingArea(false)}} className={`yesNoButton ${!parkingArea ? 'active' : ''}`}>Нет</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFlat;